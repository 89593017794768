import { useState, useMemo, useCallback } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import { Button, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";

const BuildStatisticProgress = ({ statistics, color }) => {
    return statistics.map((statistic) => (
        (statistic.home_team_value > 0 && statistic.away_team_value > 0) && <div className='fixture_statistics_widget' key={`match_statistics_${statistic.id}`}>
            <MDBRow className='progress_row'>
                <MDBCol style={{ 
                        textAlign: 'left', 
                        fontSize: '14px', 
                        fontWeight: '400'
                    }} 
                    size='3'
                    >
                    <span style={{
                            //borderTop: statistic.home_percentage > statistic.away_percentage ? `3px solid ${color}` : 'unset', 
                            // color: statistic.home_percentage > statistic.away_percentage ? 'white': '',
                            padding: '4px 8px',
                            // borderRadius: '17px'
                        }}
                    >
                        {statistic.home_team_value}
                    </span>
                </MDBCol>
                <MDBCol className='no_side_padding' style={{ textAlign: 'center' }} size='6'>
                    <div style={{ fontSize: '12px', fontWeight: '400'}}>{statistic.name}</div>
                </MDBCol>
                <MDBCol style={{ textAlign: 'right', fontSize: '14px', fontWeight: '400' }} size='3'>
                    <span style={{
                            // backgroundColor: statistic.away_percentage > statistic.home_percentage ? color : 'unset', 
                            // color: statistic.away_percentage > statistic.home_percentage ? 'white': '',
                            padding: '4px 8px',
                            borderRadius: '17px'
                        }}
                    >
                        {statistic.away_team_value}
                    </span>
                </MDBCol>
            </MDBRow>
            <MDBRow className='progress_row'>
                <div className='progress_component'>
                    <div className='progress_bar'>
                        <div
                            className='line'
                            style={{ float: 'right', backgroundColor: "#2c4b7d", width: `${statistic.home_percentage}%` }}
                        />
                    </div>
                    <div className='progress_bar'>
                        <div
                            className='line'
                            style={{ float: 'left', backgroundColor: "#c7361f", width: `${statistic.away_percentage}%` }}
                        />
                    </div>
                </div>
            </MDBRow>
        </div>
    ));
};

const MobileFixtureStatistics = ({ periods, fixture_statistics, statistic_types }) => {
    const initialPeriod = useMemo(() => 
        periods.filter(({ sort_order }) => sort_order !== undefined)
               .sort((a, b) => a.sort_order - b.sort_order)[0]?.id
    , [periods]);

    const [periodID, setPeriod] = useState(initialPeriod);

    const renderStatistics = (filterCondition, color) => (
        <BuildStatisticProgress
            statistics={fixture_statistics.filter(filterCondition)}
            color={color}
        />
    );

    const handlePeriodChange = useCallback((event, newPeriodID) => {
        if(newPeriodID !== null) {
            setPeriod(newPeriodID);
        }
    }, []);

    return (
        <Paper elevation={1} className="br-15 widget_fade_in no_padding_left">
            <MDBRow style={{ borderBottom: '1px solid #dedede' }}>
                <MDBCol size='6' style={{ padding: '10px 10px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <ToggleButtonGroup
                        className="fixture_period_toggle_group"
                        size='small'
                        value={periodID}
                        exclusive
                        onChange={handlePeriodChange}
                        aria-label="fixture events control"
                    >
                        {periods
                            .filter(period => period.description !== 'penalties')
                            .sort((a, b) => a.sort_order - b.sort_order)
                            .map((period) => (
                                <ToggleButton color="primary" key={period.id} value={period.id} aria-label={period.short_name + " events"}>
                                    {period.short_name}
                                </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </MDBCol>
            </MDBRow>
            <MDBRow className='pb-3' style={{ padding: '0 10px 0 10px' }}>
                <MDBCol className='pb-3' sm="6" style={{ borderBottom: '1px solid #dedede' }}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0 30px 0', fontWeight: '500', fontSize: '16px' }}>
                        <span>General</span>
                    </div>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && [51, 43, 53, 45, 47, 79, 59, 34, 55, 60, 87].includes(a.type_id)
                    )}
                </MDBCol>
                <MDBCol className='pb-2' sm="6" style={{ borderBottom: '1px solid #dedede' }}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0 30px 0', fontWeight: '500', fontSize: '16px' }}>
                        <span>Shots</span>
                    </div>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && [64, 41, 42, 49, 50, 86, 58, 97].includes(a.type_id)
                    )}
                </MDBCol>
                <MDBCol className='pb-2' sm="6" style={{ borderBottom: '1px solid #dedede' }}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0 30px 0', fontWeight: '500', fontSize: '16px' }}>
                        <span>Attacking</span>
                    </div>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && [52, 44, 54, 108, 109, 1527].includes(a.type_id)
                    )}
                </MDBCol>
                <MDBCol className='pb-2' sm="6" style={{ borderBottom: '1px solid #dedede' }}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0 30px 0', fontWeight: '500', fontSize: '16px' }}>
                        <span>Defending</span>
                    </div>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && [78, 100, 66, 77, 70, 46, 57, 106, 65].includes(a.type_id)
                    )}
                </MDBCol>
                <MDBCol className='pb-2' sm="6">
                    <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0 30px 0', fontWeight: '500', fontSize: '16px' }}>
                        <span>Passing</span>
                    </div>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && [80, 117, 82, 98, 99, 74].includes(a.type_id)
                    )}
                </MDBCol>
                <MDBCol className='pb-2' sm="6">
                    <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0 30px 0', fontWeight: '500', fontSize: '16px' }}>
                        <span>Discipline</span>
                    </div>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && [83, 84, 56, 85].includes(a.type_id)
                    )}
                </MDBCol>
            </MDBRow>
        </Paper>
    );
};

export default MobileFixtureStatistics;
