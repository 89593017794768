import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, { useRef, useState } from "react";
import { styled } from "@material-ui/core";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { BiChevronDown, BiChevronLeft, BiChevronUp } from "react-icons/bi";
import { DEFAULT_LOCALE } from "@mui/x-date-pickers";
import { useLeagueStandings } from "../../standings/standingsHook";
import { useIsVisible } from "../../shared/hooks/isVisibleHook";

const selectStyling = {

    // flex: '1', // Make the width flexible

    borderRadius: 2,

    backgroundColor: '#f5f5f5', // Light grey background

    border: 'none', // Remove border

    // display: 'flex',

    alignItems: 'left', // Vertically align items

    "& .MuiSelect-select": {

        padding: '10px', // Adjust padding as needed

        paddingRight: '30px', // Add space for the arrow icon

        display: 'flex',

        alignItems: 'center', // Vertically align items

    },

    ".MuiOutlinedInput-notchedOutline": { border: 0 },

    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {

        border: 0,

    },

    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {

        border: 0,

    },

};

const StyledTableCell = styled(TableCell)(({ theme }) => ({

    [`&.${tableCellClasses.head}`]: {

    // backgroundColor: '#344368',

    color: theme.palette.common.white

    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        whiteSpace: 'nowrap', // Prevent content wrapping
        overflow: 'hidden', // Hide overflow content
        textOverflow: 'ellipsis' // Display ellipsis (...) for overflow content
      },

  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(odd)': {

      backgroundColor: '#0d1e3312'

    },

    // hide last border

    '&:last-child td, &:last-child th': {
      border: 0,
      fontSize: 14
    }
}));

export default function LeagueStandingsFull(props) {

    const componentMounted = useRef(true);

    const [isVisible, setIsVisible] = React.useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    const { contentSetter, isMobile, league_id } = props;

    const [leagueStandingsResponse, setLeagueStandings] = useState(
        { 
            isLoading: false, 
            data: null, 
            league_id: league_id,
            selected_competition: -1,
            selected_round: -1,
            home_away_overall: 1
        }
    );

    useLeagueStandings(league_id, setLeagueStandings);

    const handleSelectedCompetitionChange = (event) => {

        setLeagueStandings(prevState => ({

            ...prevState,

            selected_competition: event.target.value

        }));

    };

    const handleSelectedRoundChange = (event) => {

        setLeagueStandings(prevState => ({

            ...prevState,

            selected_round: event.target.value

        }));

    };

    const handleOverallHomeAwayChange = (event) => {

        setLeagueStandings(prevState => ({

            ...prevState,

            home_away_overall: parseInt(event.target.value)

        }));

    };

    if(league_id == -1)
    {
        return (<Paper className={`widget_fade_in ${isVisible ? 'fade-in' : ''}`} elevation={2}>
            <MDBCol style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }}>
                <label>To view current standings, please select a league from the menu on the left...</label>
            </MDBCol>
        </Paper>)
    }

    if(leagueStandingsResponse == null || leagueStandingsResponse.data == null || leagueStandingsResponse.isLoading || leagueStandingsResponse.league_id != league_id) {

        return (
    
            <Box className="loading_spinner" sx={{ height: '800px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <CircularProgress />
                
            </Box>
                
        );

    }

    var stage = leagueStandingsResponse.data[0];

    return (

        <>
        
        {

            <Paper className={`widget_fade_in ${isVisible ? 'fade-in' : ''}`} elevation={2}>

            <MDBRow>

                <MDBCol className="no_side_padding">

                    <MDBCol className="no_side_padding pt-3" size='12'>

                        <label className="section_header no_background">Standings</label>

                    </MDBCol>

                    <MDBCol className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }}>

                        

                    </MDBCol>

                    <MDBCol className="no_side_padding pb-3" size='12' style={{ paddingLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <ToggleButtonGroup

                            size='small'

                            value={leagueStandingsResponse.home_away_overall}

                            exclusive

                            onChange={handleOverallHomeAwayChange}

                            aria-label="text alignment"

                        >

                            <ToggleButton color="primary" key={"overall_1"} value={1} aria-label="left aligned">

                                Overall

                            </ToggleButton>

                            <ToggleButton color="primary" key={"home_1"} value={2} aria-label="left aligned">

                                Home

                            </ToggleButton>

                            <ToggleButton color="primary" key={"away_2"} value={3} aria-label="left aligned">

                                Away

                            </ToggleButton>

                        </ToggleButtonGroup>

                        <FormControl sx={{ mb: 0, ml: 3 }} size="small">

                            <Select

                                id="team_dashboard_competitions"

                                value={leagueStandingsResponse.selected_round === -1 ? (stage == undefined || stage.rounds == undefined ? -1 : stage.rounds.find(a => a.is_current === true).id)  : (leagueStandingsResponse.selected_round || '')}
                                
                                label="Competitions"

                                onChange={handleSelectedRoundChange}

                                sx={selectStyling}

                                InputProps={{ notched: false }}

                            >

                                {stage.rounds.map((round, index) => (

                                    <MenuItem key={"standing_round_" + round.id} value={round.id}>
                                        
                                        <div style={{paddingLeft:'5px', paddingRight: '25px'}}>GW{round.name}</div>
                                    
                                    </MenuItem>

                                ))}

                            </Select>
                            
                        </FormControl>

                    </MDBCol>

                    <TableContainer>

                        <Table size="small">

                            <TableHead>

                                <TableRow>

                                    <TableCell>

                                        #

                                    </TableCell>

                                    <TableCell>

                                        Team

                                    </TableCell>

                                    <TableCell align="center">

                                        P

                                    </TableCell>

                                    <TableCell align="center">

                                        W

                                    </TableCell>

                                    <TableCell align="center">

                                        D

                                    </TableCell>

                                    <TableCell align="center">

                                        L

                                    </TableCell>

                                    <TableCell align="center">

                                        GS

                                    </TableCell>

                                    <TableCell align="center">

                                        GC

                                    </TableCell>

                                    <TableCell align="center">

                                        GD

                                    </TableCell>

                                    <TableCell align="center">

                                        

                                    </TableCell>
                                    

                                    <TableCell align="center">

                                        PTS

                                    </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {

                                    leagueStandingsResponse.data[0].rounds.find(a => 
                                        
                                        leagueStandingsResponse.selected_round === -1 ? a.is_current === true : a.id === leagueStandingsResponse.selected_round).standings.sort((a, b) => 
                                        
                                            {

                                                if (leagueStandingsResponse.home_away_overall === 1) {
                                                    // Sort by position
                                                    return a.position - b.position;
                                                } else if (leagueStandingsResponse.home_away_overall === 2) {
                                                    // Sort by type_id 185 value
                                                    const aValue = b.details.find(t => t.type_id === 185)?.value || 0; // Default to 0 if value is undefined
                                                    const bValue = a.details.find(p => p.type_id === 185)?.value || 0;
                                                    return aValue - bValue;
                                                } else {
                                                    // Sort by type_id 186 value
                                                    const aValue = b.details.find(t => t.type_id === 186)?.value || 0;
                                                    const bValue = a.details.find(p => p.type_id === 186)?.value || 0;
                                                    return aValue - bValue;
                                                }

                                            }

                                        ).map((standing, index) => {

                                        return (

                                            <StyledTableRow key={"league_standings_team_" + standing.participant_id}>

                                            <StyledTableCell style={{ paddingLeft: '5px', paddingRight: '5px' }} align="center">{index + 1}</StyledTableCell>

                                            <StyledTableCell style={{ paddingLeft: '5px', paddingRight: '5px'}}><img style={{width:'20px', height:'20px', marginRight: '8px', position: 'relative', top:'-2px'}} src={standing.image_path} />{standing.team_name}</StyledTableCell>

                                            {

                                                leagueStandingsResponse.home_away_overall == 1 ? (

                                                    <>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 129).value}</StyledTableCell>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 130).value}</StyledTableCell>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 131).value}</StyledTableCell>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 132).value}</StyledTableCell>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 133).value}</StyledTableCell>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 134).value}</StyledTableCell>

                                                    <StyledTableCell align="center">{standing.details.find(a => a.type_id === 179).value}</StyledTableCell>

                                                    </>

                                                ) : <></>

                                            }

                                            {

                                                leagueStandingsResponse.home_away_overall == 2 ? (

                                                    <>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 135).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 136).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 137).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 138).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 139).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 140).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 139).value - standing.details.find(a => a.type_id === 140).value}</StyledTableCell>

                                                    </>

                                                ) : <></>

                                            }

                                             {

                                                leagueStandingsResponse.home_away_overall == 3 ? (

                                                    <>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 141).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 142).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 143).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 144).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 145).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 146).value}</StyledTableCell>

                                                        <StyledTableCell align="center">{standing.details.find(a => a.type_id === 145).value - standing.details.find(a => a.type_id === 146).value}</StyledTableCell>

                                                    </>

                                                ) : <></>

                                            }

                                            <StyledTableCell style={{ paddingLeft: '0px', paddingRight: '0px' }} align="center">
                                                
                                                {
                                                
                                                    GetStandingResultIcon(standing.result)
                                                
                                                }
                                                
                                            </StyledTableCell>

                                            {

                                                leagueStandingsResponse.home_away_overall === 1 ?

                                                    (<StyledTableCell align="center">{standing.points}</StyledTableCell>) :

                                                (leagueStandingsResponse.home_away_overall === 2 ?

                                                    (<StyledTableCell align="center">{standing.details.find(a => a.type_id === 185).value}</StyledTableCell>) :

                                                    (<StyledTableCell align="center">{standing.details.find(a => a.type_id === 186).value}</StyledTableCell>))

                                            }

                                        </StyledTableRow>

                                        )

                                    })

                                }

                            </TableBody>

                        </Table>

                    </TableContainer>

                </MDBCol>

            </MDBRow>

            </Paper>

        }
        
        </>

    )

}

function CalculateSortOrder(valueone, valuetwo) {

    return valueone - valuetwo;

}

function GetStandingResultIcon(result) {

    switch(result)
    {

        case "equal":
            {

                return "-";

            }
        case "up":
            {

                return <BiChevronUp size={25} color="green" />
                
            }
        case "down":
            {

                return <BiChevronDown size={25} color="red" />
                
            }

    }

}