import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetTime, targetDate }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const [targetHours, targetMinutes] = targetTime.split(":").map(Number);

      const targetDate = new Date();
      targetDate.setHours(targetHours, targetMinutes, 0, 0);

      if (targetDate < now) {
        targetDate.setDate(targetDate.getDate() + 1);
      }

      const diff = targetDate - now;

      if (diff <= 0) {
        setTimeLeft("Time's up!");
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      );
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  var dateDisplay = GetDayDisplay(targetDate);

  return (
    <div>
      {dateDisplay.display && <span>{dateDisplay.display}</span>}
      {dateDisplay.showTime && <span style={{fontSize: '12px', color:'#595959'}}>{timeLeft}</span>}
    </div>
  );
};

function GetDayDisplay(targetDate) {
    const [day, month, year] = targetDate.split("/").map(Number);
    const target = new Date(year, month - 1, day); // Convert dd/mm/yyyy to Date
    target.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    if (target.getTime() === today.getTime()) {
        return {display: null, showTime: true };
    } else if (target.getTime() === tomorrow.getTime()) {
        return {display: "Tomorrow", showTime: false };
    } else {
        return {display: target.toLocaleDateString("en-GB"), showTime: false };
    }
}

export default CountdownTimer;
