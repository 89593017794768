import React, { useCallback, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import ErrorBoundary from "../error/error_boundary";
import '../dashboards/css/fixture_comments.css';
import { BsClock } from "react-icons/bs";
import { GiWhistle } from "react-icons/gi";
import { Fade, Typography } from "@mui/material";

function FixtureComment({ comment, placeholderImage }) {
    return (
        <div className="fixture-comment">
            <div className="comment-header">
                <div className="left-column">
                    <div className="minute-container">
                        
                        <Typography variant="body1" className="minute">
                        {
                            comment.minute ? <>{comment.minute}{comment.extra_minute !== null ? <sup className="comment-extra-minute"> +{comment.extra_minute}</sup> : null}</>

                            : <GiWhistle size={25} />
                        }
                        </Typography>
                    </div>
                </div>
                <div>
                    {
                        comment.player_image && 
                        <div className="image">
                            <img src={comment.player_image || placeholderImage} alt={"Image for " + comment.player_name} />
                            <span>{comment.player_name}</span>
                        </div>
                    }
                    <Typography paragraph={true} variant="body1" className="comment-body">
                        {comment.comment}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

function BuildComment({ comment, placeholderImage }) {
    return (
        <MDBRow key={`fixture_event_${comment.id}`} className="no_side_padding fade-in">
            <MDBCol className="no_side_padding mt-1 mb-1" style={{backgroundColor: '#f4f4f4', borderRadius: '7px' }}>
                <FixtureComment comment={comment} placeholderImage={placeholderImage} />
            </MDBCol>
        </MDBRow>
    );
}

export default function FixtureComments({ comments }) {

    const [showAll, setShowAll] = useState(false);

    const handlePeriodChange = useCallback((event, showAll) => {
        setShowAll(showAll);
    }, []);

    const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

    return (
        <ErrorBoundary>
            <div className="fixture-comments-container">
                {comments.sort((a, b) => b.order - a.order).slice(0, showAll ? comments.length - 1 : 10).map((comment) => (
                    <BuildComment key={`comment_${comment.id}`} comment={comment} placeholderImage={placeholderImage} />
                ))}

                {
                    comments.length < 11 ? <></> : 
                    <label className="show-more-link" onClick={() => setShowAll(!showAll)}>{showAll ? "Show less" : "Show more"}</label>
                }
            </div>
        </ErrorBoundary>
    );
}
