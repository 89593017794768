import { Button, ButtonGroup, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import MatchDayInsights from "../../insights/match_day_insights";
import ErrorBoundary from "../../error/error_boundary";
import { FaUser, FaUsers } from 'react-icons/fa';
import { BsCalendar, BsShield } from 'react-icons/bs';
import { MdLiveTv } from 'react-icons/md';
import LiveInsights from "../../insights/live_insights";

function dateIsMoreThanDate(date1, date2) {
    // Create new date objects with only year, month, and day
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    
    // Compare the two dates
    return d1.getTime() > d2.getTime();
}

export default function InsightContentDashboard(props) {

    const { fixture_id, current_league_id, contentSetter, isMobile, site_date } = props;

    const [selectedDashboard, setSelectedDashboard] = React.useState(1);

    const [selectedMobileState, setSelectedMobileState] = React.useState(1);

    var splitDateParts = site_date.split('/');

    var isFutureDate = splitDateParts.length > 0 && dateIsMoreThanDate(new Date(splitDateParts[2] + '-' + splitDateParts[1] + '-' + splitDateParts[0]), new Date());

    return (

        <React.Fragment>

            {

                isMobile ? <></> :

                <React.Fragment>

                    <Grid container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}>

                        <Grid className="pb-2 pt-2" item xs={12} sm={12} md={12} lg={12} xl={12} key={0}>

                            <Stack direction="row" spacing={2}>

                                <ButtonGroup variant="outlined" aria-label="outlined primary button group">

                                    {

                                        isFutureDate ? <></> :

                                        <Button size="small" onClick={() => setSelectedDashboard(3)} startIcon={<MdLiveTv />}  color="error" variant={selectedDashboard === 3 ? "outlined" : "contained"}>Go Live</Button>

                                    } 

                                    <Button size="small" onClick={() => setSelectedDashboard(1)} startIcon={<BsCalendar />} variant={selectedDashboard === 1 ? "outlined" : "contained"}>Fixture Insights</Button>

                                    {/* <Button size="small" onClick={() => setSelectedDashboard(2)} startIcon={<FaUsers />} variant={selectedDashboard === 2 ? "outlined" : "contained"}>Player Insights</Button>

                                    <Button size="small" onClick={() => setSelectedDashboard(4)} startIcon={<BsShield />} variant={selectedDashboard === 4 ? "outlined" : "contained"}>Team Insights</Button> */}

                                </ButtonGroup>
                                
                            </Stack>
                                
                        </Grid>

                    </Grid>

                    <Grid style={{display: isMobile ? '' : 'none'}} container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}>

                        <Grid className="pb-2 pt-2" item xs={12} sm={12} md={12} lg={12} xl={12} key={0}>

                            <Stack direction="row" spacing={2}>

                                <Button onClick={() => contentSetter(11)} startIcon={<FaUser />} variant={selectedMobileState === 1 ? "contained" : "outlined"}>Insights</Button>

                                <Button onClick={() => contentSetter(12)} startIcon={<FaUsers />} variant={selectedMobileState === 2 ? "contained" : "outlined"}>Fixture Difficulty</Button>

                            </Stack>
                                
                        </Grid>

                    </Grid>

                </React.Fragment>

            }

            <Divider className="mt-1 mb-1" />

            <Grid container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={0}>

                    {ReturnSelectedDashboard(selectedDashboard, fixture_id, current_league_id, contentSetter, isMobile, site_date)}
                        
                </Grid>

            </Grid>

        </React.Fragment>

    )

}

function ReturnSelectedDashboard(selectedDashboard, fixture_id, current_league_id, contentSetter, isMobile, site_date) {

    switch(selectedDashboard) {

        case 1:
            {

                return <ErrorBoundary>

                        <MatchDayInsights 
                        
                            contentSetter={contentSetter} 
                            
                            fixture_id={fixture_id} 
                            
                            current_league_id={current_league_id} 

                            isMobile={isMobile}

                            site_date={site_date}
                            
                        />

                    </ErrorBoundary>

            }

        case 2:
            {

                return <ErrorBoundary>

                    <div>Player Insights</div>

                </ErrorBoundary>

            }

        case 3:
            {

                return <ErrorBoundary>

                        <LiveInsights 
                        
                            contentSetter={contentSetter} 
                            
                            fixture_id={fixture_id} 
                            
                            current_league_id={current_league_id} 

                            site_date={site_date}
                            
                        />

                    </ErrorBoundary>

            }
        case 4: 
        {

            return <ErrorBoundary>

                <div>Team Insights</div>

            </ErrorBoundary>

        }

    }

}