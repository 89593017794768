import { MDBRow, MDBCol } from "mdbreact";
import { SetStatusClasses, GetFixtureState } from "../../common/helpers/status_helper";
import '../dashboards/css/fixture_dashboard.css';
import { Box, List, ListItem, Paper, styled, Tab, Tabs, Tooltip, tooltipClasses } from "@mui/material";
import { Link } from "react-router-dom";
import { FaChevronCircleLeft } from "react-icons/fa";
import { useEffect } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { GiWhistle } from "react-icons/gi";
import { CiLocationOn } from "react-icons/ci";
import CountdownTimer from "../shared/utils/CountdownTimer";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

const BuildCompetitionString = (information) => {
    let details = information.league.name;
    if (information.stage != null) {
        details = `${details}, ${information.stage.name}`;
    }
    if (information.round != null) {
        details = `${details}, Round ${information.round.name}`;
    }
    return details;
};   

export default function FixtureDetails({ index, setIndex, goalEvents, tabPanels, fixture_id, last_updated, contentSetter, detailsResponse, schedule }) {
    
    const { fixture, league } = detailsResponse.data;

    var updatedDate = new Date(last_updated);

    const handleTabChange = (event, newValue) => {
        setIndex(newValue);
    };

    {/* Effect for initial tab load - Parses fragment from url - removes hash and sets the active tab */}
    useEffect(() => {
        const updateTabFromFragment = () => {
            const fragment = window.location.hash.replace("#", ""); 
            const newFragment = Object.values(tabPanels).find(value => value === fragment);
            const newIndex = fragment ? parseInt(Object.keys(tabPanels).find(key => tabPanels[key] === newFragment)) : -1;
            if (newIndex >= 0 && newIndex !== index) {
                handleTabChange(null, newIndex);
            }
        };

        updateTabFromFragment();

        window.addEventListener("hashchange", updateTabFromFragment);

        return () => {
            window.removeEventListener("hashchange", updateTabFromFragment);
        };
    }, [tabPanels, index, handleTabChange]);

    if(!schedule || !schedule.data) {
        return <></>
    }

    return (
        <Paper elevation={1} className="mb-2 br-15 insight_fixture_summary">
            <MDBRow>
                <MDBCol className="no_side_padding" style={{ borderBottom: '1px solid #d6d6d6', padding: '12px 0px'}}>
                    <div style={{ display: 'grid', gridTemplateColumns: '200px auto 200px' }}>
                        <Link className="no_side_padding" style={{ width: '80px', marginLeft: '12px' }} to={`/fixture/${fixture_id}/${fixture.fixture_name}/stat-pack`}>
                            <div 
                                onClick={() => contentSetter(14, fixture_id)}
                                style={{
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    padding: '4px',
                                    width: '110px'
                                }}
                            >
                                <FaChevronCircleLeft style={{marginRight: '5px'}} size={20} /> Stat Pack
                            </div>
                        </Link>
                        <div style={styles.topHeaderContent}>
                            <img src={schedule.data.league?.image_path} alt={schedule.data.league?.name + " Logo"} style={{ width: '25px', height: '25px', marginRight: '8px', verticalAlign: 'middle' }} />
                            <span style={{verticalAlign: 'middle'}}>{BuildCompetitionString(schedule.data)}</span>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow style={styles.topHeaderContent}>
                <MDBCol className="no_side_padding" style={{ borderBottom: '1px solid #d6d6d6', padding: '12px 0px', display: 'flex', justifyContent: 'center', gap: '20px', color: '#3b3b3b' }}>
                    {schedule.data.display_date_time && <span style={{fontSize: '12px'}}>
                        <IoCalendarOutline size={16} style={{ position: 'relative', top: '-2px' }} /> {schedule.data.display_date_time}
                    </span>}
                    {schedule.data.venue && <span style={{fontSize: '12px'}}>
                        <CiLocationOn size={20} style={{ position: 'relative', top: '-3px' }} /> {schedule.data.venue.name}
                    </span>}
                    {schedule.data.referee_name && <span style={{fontSize: '12px'}}>
                        <GiWhistle size={20} style={{ position: 'relative', top: '-2px' }} /> {schedule.data.referee_name}
                    </span>}
                </MDBCol>
            </MDBRow>
            <MDBRow className="widget_container" style={{padding: '10px 10px 0 0'}}>
                {/* Header */}
                <MDBRow className="no_side_padding" style={styles.header}>
                    <MDBCol size="12" style={styles.headerContent}>
                        <div style={{justifySelf: 'right'}}>
                            <span style={{ fontSize: '22px', marginRight: '15px', alignSelf: 'center' }}>{fixture.home_team.name}</span>
                            <img
                                src={fixture.home_team.logo}
                                alt={`${fixture.home_team.name} Logo`}
                                style={{ width: 40, height:40 }}
                            />
                        </div>
                        <div style={{justifySelf: 'center', display: 'flex', gap: '4px', flexDirection: 'column' }}>
                            <div className={`${SetStatusClasses(fixture.fixture_state.state)} fixture_score event_status`} style={{fontSize: '22px', height: '24px'}}>
                                {fixture.fixture_state.state === "NS" ? fixture.fixture_details.time : `${fixture.fixture_state.home_team_score} - ${fixture.fixture_state.away_team_score}` }
                            </div>
                            <div style={styles.fixtureState} className={`${SetStatusClasses(fixture.fixture_state.state, false)} fixture_state`}>
                                {GetFixtureState(fixture.fixture_state, fixture.fixture_details.time, true, fixture.fixture_details.date)}
                            </div>
                        </div>
                        <div style={{justifySelf: 'left', display: 'flex'}}>
                            <img
                                src={fixture.away_team.logo}
                                alt={`${fixture.away_team.logo} Logo`}
                                style={{ width: 40, height:40 }}
                            />
                            <span style={{ fontSize: '22px', marginLeft: '15px', alignSelf: 'center' }}>{fixture.away_team.name}</span>
                        </div>
                    </MDBCol>
                </MDBRow>
                {goalEvents && goalEvents.length > 0 &&
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 30px 1fr', paddingTop: '8px'}}>
                        <div style={{display: 'flex', justifyContent: 'end', textAlign: 'right', fontWeight: 500, fontSize: '10px', color: '#353535' }}>
                            <List style={{paddingTop: 0}}>
                            {
                                goalEvents.filter((event) => { return event.team_id === fixture.home_team.id }).sort((a, b) => (b.minute + (b.extra_minute == null ? 0 : b.extra_minute)) - (a.minute + (a.extra_minute == null ? 0 : a.extra_minute))).map((event, index) => {
                                    return (
                                        <ListItem key={"goal_event_" + event.id} style={{justifyContent: 'end', padding: '0'}}>{event.player_name}{" "}{event.minute}'{event.extra_minute !== null ? <sup className="extra-minute">{event.extra_minute}</sup> : null} {event.type_id === 16 ? "(Pen)" : ""}</ListItem>
                                    )
                                })
                            }
                            </List>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <SportsSoccerIcon fontSize="10px" />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'start', textAlign: 'left', fontWeight: 500, fontSize: '10px', color: '#353535'}}>
                            <List style={{paddingTop: 0}}>
                            {
                                goalEvents.filter((event) => { return event.team_id === fixture.away_team.id }).sort((a, b) => (b.minute + (b.extra_minute == null ? 0 : b.extra_minute)) - (a.minute + (a.extra_minute == null ? 0 : a.extra_minute))).map((event, index) => {
                                    return (
                                        <ListItem key={"goal_event_" + event.id} style={{justifyContent: 'start', padding: '0'}}>{event.minute}'{event.extra_minute !== null ? <sup className="extra-minute">{event.extra_minute}</sup> : null}{" "}{event.player_name} {event.type_id === 16 ? "(Pen)" : ""}</ListItem>
                                    )
                                })
                            }
                            </List>
                        </div>
                    </div>
                }
                <MDBRow className="widget_container pb-0 pt-2">
                    {Object.keys(tabPanels).length > 0 &&
                        <Box sx={{ width: '100%' }}>
                            <StyledTabs
                                value={index}
                                onChange={(event, newIndex) => {
                                    handleTabChange(event, newIndex);
                                    const fragment = `#${tabPanels[newIndex]}`;
                                    window.history.pushState(null, '', fragment);
                                }}
                                aria-label="Fixture tabbed navigation"
                            >
                                    {Object.entries(tabPanels).map(([key, value]) => (
                                        <StyledTab 
                                            key={value + "_" + key}
                                            style={{padding: '0px 5px 0px 5px'}} 
                                            to={`#${value}`}
                                            label={value} 
                                        />
                                    ))}
                            </StyledTabs>
                        </Box>
                    }
                </MDBRow>
            </MDBRow>
        </Paper>
    );
}

const StyledTabs = styled((props) => (
    <Tabs variant="scrollable"
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  });
  
  const StyledTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'black', // Set default tab color to black
        '&.Mui-selected': {
            color: 'black', // Set selected tab color to black
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const styles = {
    header: { backgroundColor: "white" },
    headerContent: {
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: 'center',
        flexGrow: 3,
        columnGap: '6px',
        display: 'grid',
        gridTemplateColumns: '1fr 90px 1fr'
    },
    topHeaderContent: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: 'center'
    },
    fixtureState: { fontSize: '14px' },
    teamNames: { display: "flex", flexDirection: "column", textAlign: "center" },
    fixtureDescription: { fontSize: "12px" },
    centerText: { textAlgn: "center" },
    rowContainer: { display: "flex", justifyContent: "space-between", flexWrap: "nowrap" },
    cellItem: { padding: "5px", flex: "1 1 auto", textAlign: "center" },
    logo: { width: 22, height: 22 },
    statTitle: { textAlign: "center", backgroundColor: "#f1f1f1", fontWeight: "500" },
};
