export function IsInPast(dateToCompare: Date)
{
    
    let currentDateTime = new Date().toLocaleString();

    let currentDate = new Date(currentDateTime);

    let matchStartDate = new Date(dateToCompare);

    return currentDate > matchStartDate;

}

export function IsToday(dateString: string) {

    if(dateString.length === 0 || dateString.trim() === "") {

        return true;

    }

    const today = new Date();

    const dateParts = dateString.split('/').map(function(item) {

        return parseInt(item, 10);

    });

    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

    return date.toDateString() === today.toDateString();

  }

  export function IsTodaySetState(dateString: string, setter: any, currentState: boolean) {

    if(dateString.length === 0 || dateString.trim() === "") {

        if(!currentState) {

            setter(true);
    
        }

    }
    else {

        const today = new Date();

        const dateParts = dateString.split('/').map(function(item) {
    
            return parseInt(item, 10);
    
        });
    
        const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    
        var state = date.toDateString() === today.toDateString();
    
        if(state !== currentState) {
    
            setter(state);
    
        }

    }

  }