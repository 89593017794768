import { Paper } from "@mui/material";
import Divider, { dividerClasses } from '@mui/material/Divider';
import { useState } from "react";
import { useManOfTheMatch } from "./fixtureHook";
import StarBorderIcon from '@mui/icons-material/StarBorder';

const styles = {
    playerImage: {
        width: '48px',
        height: 'auto',
        borderRadius: '50%',
        objectFit: 'cover',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        position: 'relative',
        border: '2px solid rgb(255, 255, 255)',
        marginRight: '6px'
    }
}

export default function FixtureManOfTheMatch({ fixture_id }) {

    const [manOfTheMatchResponse, setManOfTheMatchResponse] = useState({ isLoading: true, data: null });

    useManOfTheMatch(fixture_id, setManOfTheMatchResponse);

    if(manOfTheMatchResponse.isLoading || (!manOfTheMatchResponse.isLoading && !manOfTheMatchResponse.data)) {
        return <></>
    }

    const player = manOfTheMatchResponse.data;

    return (
        <Paper elevation={1} className="mb-2 pb-1 br-15 widget_fade_in no_side_padding" sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}}>
            <div className='widget_heading' style={{textAlign: 'center', padding: '10px 10px 10px 10px'}}>
                Man of the match
            </div>
            <Divider />
            <div style={{ display: 'flex', padding: '10px 10px' }}>
                <div>
                    <img src={player.image} style={styles.playerImage} />
                </div>
                <div>
                    <div>
                        <span style={{ color: '#6d6d6d', marginRight: '3px' }}>{player.player_number}</span>
                        {player.player_name}
                    </div>
                    <div>
                        <span style={{ marginRight: '3px' }}>
                            <img src={player.team_logo} style={{ width: '15px', height: '15px' }} />
                        </span>
                        <span style={{ fontSize: '12px', color: '#6d6d6d' }}>
                            {player.team_name}
                        </span>
                    </div>
                </div>
                <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <span style={{ backgroundColor: '#037ab0', padding: '5px', color:'white', borderRadius: '3px' }}>{player.rating}</span>
                </div>
            </div>
        </Paper>
    )
}