import { useEffect } from 'react';
import axios from 'axios';
import configuration from '../../config.json';

export const useCommentary = (setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if(isMounted) {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/commentary`);
  
            var result = null;
            if (response.status === 200) {
              result = response.data;
            }
        } 
        catch (error) {
          setter(null);
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [setter]);
};

export const useFixtureStatPack = (fixture_id, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, fixture_id: fixture_id };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/statpack`);
        
        if (response.status === 200 && isMounted) {
          result.data = response.data;
        }

        if(isMounted) {
          setter(result);
        }
      } 
      catch (error) {
        setter({ isLoading: false, data: null, fixture_id: fixture_id });
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fixture_id]);
};


export const useFixtureDashboard = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          var result = { isLoading: false, data: null, fixture_id: fixture_id };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}`);
          
          if (response.status === 200) {
            result.data = response.data;
          }

          if(isMounted) {
            setter(result);
          }
        } 
        catch (error) {
          setter({ isLoading: false, data: null, fixture_id: fixture_id });
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [fixture_id]);
  };

  export const useLineups = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
        try {
          var result = { isLoading: false, data: null };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/lineups`);
          
          if (response.status === 200) {
            result.data = response.data;
          }
          setter(result);
        } 
        catch (error) {
          setter({ isLoading: false, data: null });
        }
      };
  
      fetchData();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id]);
  };

  export const useMatchOfficials = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {
          var result = { isLoading: false, data: null };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/matchofficials`);
          
          if (response.status === 200) {
            result.data = response.data;
          }
          setter(result);
        } 
        catch (error) {
          setter({ isLoading: false, data: null });
        }
      };
  
      fetchData();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useManOfTheMatch = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/manofthematch`);
          
          if (isMounted) {
            var result = { isLoading: false, data: null };
            if (response.status === 200) {
              result.data = response.data;
            }
            setter(result);
          }
        } 
        catch (error) {
          if (isMounted) {
           setter({ isLoading: false, data: null });
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useFixtureFormGuide = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/form`);
          
          if (isMounted) {
            var result = null;
            if (response.status === 200) {
              result = response.data;
            }
            setter(result);
          }
        } 
        catch (error) {
          if (isMounted) {
           setter(null);
          }
        }
      })();
  
      // Cleanup function to set isMounted to false when the component is unmounted
      return () => {
        isMounted = false;
      };
    }, [fixture_id, setter]);
  };

  export const useFixtureTvStations = (fixtureId, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        if(isMounted) {
          try {
            const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixtureId}/tv-stations`);
    
            var result = { isLoading: false, data: null };
  
            if (response.status === 200) {
              result.data = response.data;
            }
  
            if(isMounted) {
              setter(result);
            }
          } 
          catch (error) {
            setter({ isLoading: false, data: null });
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [fixtureId])
  }

  export const useFixtureHighlights = (fixtureId, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        if(isMounted) {
          try {
            const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixtureId}/highlights`);
    
            var result = { isLoading: false, data: null };
  
            if (response.status === 200) {
              result.data = response.data;
            }
  
            if(isMounted) {
              setter(result);
            }
          } 
          catch (error) {
            setter({ isLoading: false, data: null });
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [fixtureId])
  }

export const useResults = (date, league_id, setter) => {
  useEffect(() => {
    let isMounted = true;

    (async () => {
      if(isMounted) {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/results?fixtureDate=${date !== null && date !== undefined && date !== '' ? date : ""}&leagueId=` + (league_id == null ? "" : league_id));
  
          var result = { isLoading: false, data: null };

          if (response.status === 200) {
            result.data = response.data;
          }

          if(isMounted) {
            setter(result);
          }
        } 
        catch (error) {
          setter({ isLoading: false, data: null });
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [date, league_id, setter])
}

export const useFixtureSchedule = (fixture_id, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        var result = { isLoading: false, data: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/schedule`);
        
        if (response.status === 200) {
          result.data = response.data;
        }
        
        if(isMounted) {
          setter(result);
        }
      } 
      catch (error) {
        setter({ isLoading: false, data: null });
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [fixture_id, setter]);
};

export const useFixturePlayerStatistics = (fixture_id, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        var result = { isLoading: false, data: null };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixture_id === null ? -1 : fixture_id}/playerstatistics`);
        
        if (response.status === 200) {
          result.data = response.data;
        }
        
        if(isMounted) {
          setter(result);
        }
      } 
      catch (error) {
        setter({ isLoading: false, data: null });
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fixture_id, setter]);
};