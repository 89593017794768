import { useState, useEffect } from 'react';
import configuration from '../../config.json';
import { MakeAxiosRequest } from '../../application/infrastructure/http_client.js';
import axios from 'axios';

export const usePlayers = (currentLeagueID, searchTerm, page = 1, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          var result = { isLoading: false, data: [], currentLeagueID, searchTerm: searchTerm, page };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}players?leagueID=${currentLeagueID === null ? -1 : currentLeagueID}&searchTerm=${searchTerm}&take=25&page=${page}`);

          if (response.status === 200) {
            result.data = response.data;
          }

          setter(result);
        } 
        catch (error) {
          setter({ isLoading: false, data: null, currentLeagueID, searchTerm: searchTerm, page });
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [currentLeagueID, searchTerm, page, setter]);
  };

  export const usePlayersForSelection = (currentLeagueID, searchTerm, page = 1, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          var result = { isLoading: false, data: null, currentLeagueID, searchTerm: searchTerm, page, totalPages: 1 };

          const data = { LeagueID: currentLeagueID, SearchTerm: searchTerm, Page: page };

          const response = await axios.post(`${configuration.FRONT_END_API_v2}players/selection`, data);
          
          if (response.status === 200) {
            result.data = response.data.data;
            result.page = response.data.page;
            result.totalPages = response.data.totalPages;
          }

          setter(result);
        } 
        catch (error) {
          setter({ isLoading: false, data: null, currentLeagueID, searchTerm: searchTerm, page, totalPages: 1 });
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [currentLeagueID, searchTerm, page, setter]);
  };

  export const usePlayerComparisonDashboard = (state, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          const response = await axios.get(`${configuration.FRONT_END_API_v2}players/${state.season_id}/comparison`);
          
          if (response.status === 200) {
            setter(prevState => ({
              ...prevState,
              isLoading: false,
              data: response.data
            }));
          }
        } 
        catch (error) {
          setter(prevState => ({
            ...prevState,
            isLoading: false,
            data: null
          }));
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [state.season_id, state.page, setter]);
  };