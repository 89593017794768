import { MDBRow, MDBCol } from "mdbreact";
import { SetStatusClasses, GetFixtureState } from "../../../common/helpers/status_helper";
import '../../../application/dashboards/css/fixture_dashboard.css';
import { Box, Divider, List, ListItem, Paper, Tab, Tabs } from "@mui/material";
import { useFixtureSchedule } from "../../../application/fixtures/fixtureHook";
import { useState } from "react";
import '../dashboards/css/fixture_details.css';
import { styled } from "@mui/material";
import CountdownTimer from "../../../application/shared/utils/CountdownTimer";
import MobileFixtureMenu from "./mobile_fixture_menu";

const StyledTabs = styled((props) => (
    <Tabs variant="scrollable"
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 60,
      width: '100%',
      borderRadius: '15px',
      backgroundColor: '#151e31',
    },
  });
  
  const StyledTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        position: 'sticky',
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(13),
        color: 'black', // Set default tab color to black
        '&.Mui-selected': {
            color: 'black', // Set selected tab color to black
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const styles = {
    header: { 
        width: '100%',
        minHeight: '70px',
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr'
    },
    headerContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        fontWeight: "500",
        textAlign: 'center'
    },
    headerContentCentered: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "500",
        textAlign: 'center'
    },
    headerContentParticipants: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center'
    },
    teamNames: { display: "flex", flexDirection: "column", textAlign: "center", fontSize: '13px', padding: '7px 0 0 0', fontWeight: '500', width: '112px' },
    fixtureDescription: { fontSize: "12px", width: '100%' },
    centerText: { textAlgn: "center" },
    rowContainer: { display: "flex", justifyContent: "space-between", flexWrap: "nowrap" },
    cellItem: { padding: "5px", flex: "1 1 auto", textAlign: "center" },
    logo: { width: 22, height: 22 },
    statTitle: { textAlign: "center", backgroundColor: "#f1f1f1", fontWeight: "500" },
};

export default function MobileFixtureDetails({ index, setIndex, activeStep, setActiveStep, tabPanels, fixture_id, last_updated, goalEvents, contentSetter, fixture, league }) {
    const updatedDate = new Date(last_updated);
    const [schedule, SetSchedule] = useState({ isLoading: true, data: null });
    useFixtureSchedule(fixture_id, SetSchedule);

    const handleTabChange = (event, newValue) => {
        setIndex(newValue);
        setActiveStep(newValue);
    };

    const BuildCompetitionString = (information) => {
        let details = information.league.name;
        if (information.stage != null) {
            details = `${details}, ${information.stage.name}`;
        }
        if (information.round != null) {
            details = `${details}, Round ${information.round.name}`;
        }
        return details;
    };  

    return schedule.isLoading || (schedule.data == null && !schedule.isLoading) ? <></> : (
        <Paper elevation={1} className="mobile-fixture-summary br-15 insight_fixture_summary pt-1" style={{borderRadius: 0}}>
            <MDBRow className="widget_container pb-0">
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <MobileFixtureMenu fixture={fixture} fixture_id={fixture_id} contentSetter={contentSetter} />
                </div>
                {/* Header */}
                <div style={styles.header}>
                    <div style={styles.headerContentParticipants}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <img
                                src={fixture.home_team.logo}
                                alt={`${fixture.home_team.name} Logo`}
                                style={{ width: 45, height: 45, alignSelf: 'center' }}
                            />
                            <div style={styles.teamNames}>{fixture.home_team.name}</div>
                        </div>
                    </div>
                    <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'flex-start', 
                        alignItems: 'center' 
                    }}>
                        <div className={`${SetStatusClasses(fixture.fixture_state.state, false)} fixture_score event_status`} 
                            style={{
                                fontSize: '28px', 
                                letterSpacing: '1px',
                                textAlign: 'center', 
                                fontWeight: '500'
                            }}
                        >
                                <span style={{ 
                                    height: '38px', 
                                    textAlign: 'center',
                                    display: 'flex', 
                                    alignItems: 'center' 
                                }}> {fixture.fixture_state.state === "NS" ? fixture.fixture_details.time : `${fixture.fixture_state.home_team_score} - ${fixture.fixture_state.away_team_score}` }</span>
                        </div>
                        <span
                            className={`${SetStatusClasses(fixture.fixture_state.state, false)} fixture_state`}
                            style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                position: 'relative',
                                width: 'auto',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {GetFixtureState(fixture.fixture_state, fixture.fixture_details.time, true, fixture.fixture_details.date)}
                        </span>
                    </div>
                    <div style={styles.headerContentParticipants}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <img
                                src={fixture.away_team.logo}
                                alt={`${fixture.away_team.name} Logo`}
                                style={{ width: 45, height: 45, alignSelf: 'center' }}
                            />
                            <div style={styles.teamNames}>{fixture.away_team.name}</div>
                        </div>
                    </div>
                </div>
                {/* <MDBRow style={styles.header}>
                    <MDBCol size="12" style={styles.headerContentCentered}>
                        <div style={styles.teamNames}>
                            <span style={styles.fixtureDescription}>{BuildCompetitionString(schedule.data)}</span>
                        </div>
                    </MDBCol>
                </MDBRow> */}
                <MDBRow className="no_side_padding" style={{ borderRadius: '5px', marginTop: '7px' }}>
                    {/* <MDBRow className="pt-2 pb-2">
                        <Link className="no_side_padding" to={`/fixture/${fixture_id}/${schedule.data.fixture_url_name}/stat-pack`}>
                            <div 
                                onClick={() => contentSetter(14, fixture_id)}
                                style={{ 
                                    backgroundColor: 'rgb(47 70 112)',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    padding: '4px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    color: 'white' 
                                }}
                            >
                                View Stat Pack
                            </div>
                        </Link>
                    </MDBRow> */}
                    {/* {
                        goalEvents && goalEvents.length > 0 && 
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 30px 1fr', paddingTop: '8px'}}>
                            <div style={{display: 'flex', justifyContent: 'end', textAlign: 'right', fontWeight: 500, fontSize: '10px', color: '#353535' }}>
                                <List style={{paddingTop: 0}}>
                                {
                                    goalEvents.filter((event) => { return event.team_id === fixture.home_team.id }).sort((a, b) => (b.minute + (b.extra_minute == null ? 0 : b.extra_minute)) - (a.minute + (a.extra_minute == null ? 0 : a.extra_minute))).map((event, index) => {
                                        return (
                                            <ListItem key={"goal_event_" + event.id} style={{justifyContent: 'end', padding: '0'}}>{event.player_name}{" "}{event.minute}'{event.extra_minute !== null ? <sup className="extra-minute">{event.extra_minute}</sup> : null} {event.type_id === 16 ? "(Pen)" : ""}</ListItem>
                                        )
                                    })
                                }
                                </List>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <SportsSoccerIcon fontSize="10px" />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'start', textAlign: 'left', fontWeight: 500, fontSize: '10px', color: '#353535'}}>
                                <List style={{paddingTop: 0}}>
                                {
                                    goalEvents.filter((event) => { return event.team_id === fixture.away_team.id }).sort((a, b) => (b.minute + (b.extra_minute == null ? 0 : b.extra_minute)) - (a.minute + (a.extra_minute == null ? 0 : a.extra_minute))).map((event, index) => {
                                        return (
                                            <ListItem key={"goal_event_" + event.id} style={{justifyContent: 'start', padding: '0'}}>{event.minute}'{event.extra_minute !== null ? <sup className="extra-minute">{event.extra_minute}</sup> : null}{" "}{event.player_name} {event.type_id === 16 ? "(Pen)" : ""}</ListItem>
                                        )
                                    })
                                }
                                </List>
                            </div>
                        </div>
                    } */}
                    <div className="widget_container pb-0" style={{ overflow: 'visible' }}>
                        {Object.keys(tabPanels).length > 0 && (
                            <Box>
                                <StyledTabs
                                    value={index}
                                    onChange={handleTabChange}
                                    aria-label="Fixture tabbed navigation"
                                >
                                    {Object.entries(tabPanels).map(([key, value]) => (
                                        <StyledTab
                                            style={{ padding: '0px 5px 0px 5px' }}
                                            label={value}
                                            key={`tab_${key}`}
                                        />
                                    ))}
                                </StyledTabs>
                            </Box>
                        )}
                    </div>
                </MDBRow>
            </MDBRow>
        </Paper>
    );
}
