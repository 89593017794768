import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import StatManLogo from '../../images/stat_man_logo_aqua.jpg';
import { Skeleton } from "@mui/material";
import { FaGlobeEurope } from "react-icons/fa";

import premierLeague from '../../images/8.png';
import clLogo from '../../images/2.png';
import europaLeagueLogo from '../../images/5.png';
import championshipLogo from '../../images/9.png';
import conferenceLeagueLogo from '../../images/2286.png';
import erdeviseLogo from '../../images/72.png';
import splLogo from '../../images/501.png';
import superligLogo from '../../images/271.png';
import { Link } from "react-router-dom";

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const drawerWidth = 250;

// Opened Drawer Styles
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Closed Drawer Styles
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `80px`,
  [theme.breakpoints.up("sm")]: {
    width: `80px`,
  },
});

// DrawerHeader Styles
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

// AppBar Component
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// Drawer Component
const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export default function DashboardMenu({HandleDefaultMenuSwitching, LeagueSelectionOnClick, MenuItems, dashboardProps}) {
    const [open, setOpen] = React.useState(true);
    const [showImages, setShowImages] = React.useState(false);

    const { leagueSelectionState, primary_dashboard_state } = dashboardProps;
    const { league_selection_response, isLoading } = leagueSelectionState;

    const handleDrawerClose = () => {
      setOpen(!open);
  
      if (open) {
        setTimeout(() => setShowImages(true), 300);
      } else {
        setShowImages(false);
      }
    };
  
    return (
    <>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <DrawerHeader style={{backgroundColor: '#151e31'}}>
            {!open ? <></> : <img src={StatManLogo} className="the_stat_man_dashboard_logo" alt="The stat man logo" />}
            <IconButton color="white" onClick={handleDrawerClose}>
                {!open ? 
                    <ChevronRightIcon sx={{color: 'white', fontSize: '30px' }} /> : 
                    <ChevronLeftIcon sx={{color: 'white', fontSize: '30px' }} />
                }
            </IconButton>
          </DrawerHeader>
          <div className="dashboard_menu">
            <List style={{width: open ? 'auto' : '63px'}} className="dashboard_menu_list">
                <RenderMenuItems 
                    MenuItems={MenuItems} 
                    HandleDefaultMenuSwitching={HandleDefaultMenuSwitching}
                    dashboardProps={dashboardProps}
                    open={open}
                />
            </List>
          </div>
          <div className="dashboard_menu_seperator"></div>
          <div className="dashboard_menu">
              <List style={{width: open ? 'auto' : '63px'}} className="dashboard_menu_list_leagues">
                  <li className={
                          (primary_dashboard_state && primary_dashboard_state.selectedLeagueID === null ? "dashboard_league_list_item_selected " : "") +
                          "dashboard_league_list_item"
                      }
                      onClick={() => LeagueSelectionOnClick(null)}
                  >
                        {open ? <span style={{marginLeft: '10px' }}>All Leagues</span> : 
                            <BootstrapTooltip title="All Leagues" placement="right">
                                <div>
                                    <FaGlobeEurope className={`league-logo ${showImages ? "fade-in" : "fade-out"}`} size={30} />
                                </div>
                            </BootstrapTooltip>
                        }
                  </li>
                  <RenderLeagueSelections 
                      isLoading={isLoading} 
                      league_selection_response={league_selection_response} 
                      open={open} 
                      showImages={showImages}
                      LeagueSelectionOnClick={LeagueSelectionOnClick}
                      primary_dashboard_state={primary_dashboard_state}
                  />
              </List>
          </div>
        </Drawer>
        </>
    );
  }
  
const RenderMenuItems = ({MenuItems, HandleDefaultMenuSwitching, dashboardProps, open }) => {
    return MenuItems.map((menu_item, index) => (
        <React.Fragment key={menu_item.menu_item_text}>
            {menu_item.menu_item_apply_seperator && <div className="dashboard_menu_seperator"></div>}
            <Link to={menu_item.menu_item_route}>
                <li
                    onClick={() => HandleDefaultMenuSwitching(menu_item.menu_item_function_type, dashboardProps)}
                    className="dashboard_list_item"
                >
                    {open ?
                        <span style={{marginLeft: '10px' }}>{menu_item.menu_item_text}</span> : 
                        <BootstrapTooltip title={menu_item.menu_item_text} placement="right">
                            <div>
                            {
                                React.createElement(menu_item.menu_item_icon, { style: { fontSize: 26, marginLeft: 'auto', marginRight: 'auto', display: 'block'} })
                            }
                            </div>
                        </BootstrapTooltip>
                    }
                </li>
            </Link>
            {menu_item.menu_item_apply_seperator && <div className="dashboard_menu_seperator"></div>}
        </React.Fragment>
    ));
};

const RenderLeagueSelections = ({ isLoading, league_selection_response, primary_dashboard_state, open, showImages, LeagueSelectionOnClick }) => {
    const logo = { 
      8: premierLeague,
      2: clLogo,
      5: europaLeagueLogo,
      9: championshipLogo,
      2286: conferenceLeagueLogo,
      72: erdeviseLogo,
      501: splLogo,
      271: superligLogo
    };
  
    if (!league_selection_response || isLoading) {
      return Array.from(Array(15).keys()).map((index) => (
        <li key={"league_selection_" + index} style={{ marginTop: "5px" }}>
          <Skeleton
            sx={{ bgcolor: "#1a171e" }}
            animation="wave"
            height={44}
            style={{
              marginBottom: 6,
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              transform: "unset",
            }}
          />
        </li>
      ));
    }
  
    return (
      <>
        {league_selection_response.map((league) => (
          <li
            key={"league_profile_" + league.id}
            onClick={() => LeagueSelectionOnClick(league.id)}
            className={
              (league.id === primary_dashboard_state?.selectedLeagueID
                ? "dashboard_league_list_item_selected "
                : "") + "dashboard_league_list_item"
            }
          >
            {open ? (
              <span style={{marginLeft: '10px' }}>{league.name}</span>
            ) : (
                <BootstrapTooltip title={league.name} placement="right">
                    <img
                        className={`league-logo ${showImages ? "fade-in" : "fade-out"}`}
                        style={{ width: "30px", height: "30px" }}
                        src={logo[league.id] || league.image_path}
                        alt={league.name + " logo"}
                    />
                </BootstrapTooltip>
            )}
          </li>
        ))}
      </>
    );
  };
  
  