import { Component, Fragment } from "react";
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';

class Autocomplete extends Component {

  constructor(props) {

    super(props);

    this.state = {

      // activeSuggestion: 0,

      // filteredSuggestions: [],

      // showSuggestions: false,

      setSearchTerm: props.setSearchTerm,

      userInput: props.searchTerm,

      placeholder: props.placeholder

    };

    this.timeout =  0;

  }

  render = () => {

      const {

        onClick,

        onKeyDown,

        state: {

          // activeSuggestion,

          // filteredSuggestions,

          // showSuggestions,

          userInput = "",

          placeholder

        }

      } = this;
  
      // let suggestionsListComponent;

      // if (showSuggestions && userInput) {

      //   if (filteredSuggestions.length) {

      //     suggestionsListComponent = (

      //       <ul class="suggestions">

      //         {filteredSuggestions.map((suggestion, index) => {

      //           let className;
  
      //           // Flag the active suggestion with a class
      //           if (index === activeSuggestion) {

      //             className = "suggestion-active";

      //           }

      //           return (

      //             <li className={className} key={suggestion} onClick={onClick}>

      //               {suggestion}

      //             </li>

      //           );

      //         })}

      //       </ul>

      //     );

      //   } 
      //   else {

      //     suggestionsListComponent = (

      //       <div class="no-suggestions">

      //         <em>No suggestions available.</em>

      //       </div>

      //     );

      //   }

      // }

      return (

        <Fragment>

          <Form.Control 
          
            style={{width: '100%'}} 
            
            id="standard-search" 
            
            icon="search"

            placeholder={placeholder}
            
            type="search" 
            
            variant="standard" 
            
            value={userInput} 
            
            onKeyDown={this.onKeyDown} 
            
            onChange={this.onChange} 
            
          />

          {/* {suggestionsListComponent} */}

        </Fragment>

      );

    }

    onChange = e => {

        var elementValue = e.currentTarget.value;

        this.setState({
        
          ...this.state, 

          userInput: elementValue.toLowerCase()

        });

        if(this.timeout) {

          clearTimeout(this.timeout);

        } 

        this.timeout = setTimeout(() => {
        
          this.props.setSearchTerm(e, elementValue.toLowerCase());
        
      }, 500);

    };

    onClick = e => {

      this.setState({

        activeSuggestion: 0,

        filteredSuggestions: [],

        showSuggestions: false,

        userInput: e.currentTarget.innerText

      });

    };

    onKeyDown = e => {

    //   const { activeSuggestion, filteredSuggestions } = this.state;
  
    //   if (e.keyCode === 13) {

    //     this.setState({

    //       activeSuggestion: 0,

    //       showSuggestions: false,

    //       userInput: filteredSuggestions[activeSuggestion]

    //     });

    //   } else if (e.keyCode === 38) {

    //     if (activeSuggestion === 0) {

    //       return;

    //     }

    //     this.setState({ activeSuggestion: activeSuggestion - 1 });

    //   }

    //   // User pressed the down arrow, increment the index

    //   else if (e.keyCode === 40) {

    //     if (activeSuggestion - 1 === filteredSuggestions.length) {

    //       return;

    //     }

    //     this.setState({ activeSuggestion: activeSuggestion + 1 });

    //   }
      
    // };
    
  }

}

export default Autocomplete;