import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CircularProgress from '@mui/material/CircularProgress';
// import { usePlayer } from '../../player/playerHook';
import BreadcrumbNavigation from '../../../Components/navigation/breadcrumb_navigation.js';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import PlayerProfile from "../../player/player_profile.js";
import flattenChildren from 'react-keyed-flatten-children'
import Divider from '@mui/material/Divider';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import '../css/player_dashboard.css';


function defaultStatValue(value)
{

    return value == null || value == undefined ? 0 : value;

}

function seasonFilterMethod(key, current_player, stateTransition)
{

    var id = key.split('_')[0];

    var year = key.split('_')[1];
    
    var current_season = current_player.statistics_breakdown_dto.season_breakdown.filter((item, index) => {

        return (item.season_name === year)

    })[0];

    var current_competition = current_season.competition_breakdown.filter((item, index) => {

        return (item.competition_details.competition_id == id)

    })[0];

    stateTransition(current_competition);

}

export default function PlayerDashboard(props) {

    const theme = useTheme();

    const { player_id, contentSetter, isMobile } = props;

    const [selectedSeason, setSelectedSeason] = useState('');

    const [selectedStatistics, setSelectedStatistics] = useState([]);

    const playerResponse = null;
    
    //usePlayer(player_id);

    const current_player = playerResponse === null ? null : playerResponse.data;

    var initialStatistics = [];

    const handleDropdownChange = (event) => {

        setSelectedSeason(event.target.value || '');

        seasonFilterMethod(event.target.value, current_player, setSelectedStatistics);

    };

    var filterItems = [];

    var initialID = '';

    if(current_player !== null) {

        for(var i = 0; i < current_player.statistics_breakdown_dto.statistic_filters.length; i++)
        {

            var item = current_player.statistics_breakdown_dto.statistic_filters[i];

            if(isMobile) {

                filterItems.push(<option disabled={true} style={{backgroundColor: '#e5e5e5'}}>{item.competition_details.competition_name}</option>);

            }
            else {

                filterItems.push(<ListSubheader disabled={true} style={{backgroundColor: '#e5e5e5'}}><img style={{width: '30px', marginRight: '10px'}} src={item.competition_details.competition_Logo} />  {item.competition_details.competition_name}</ListSubheader>);

            }

            for(var k = 0; k < item.years.length; k++){

                var year = item.years[k];

                if(isMobile) {

                    filterItems.push(<option key={"menu_item_" + item.competition_details.competition_id + "_" + year} value={item.competition_details.competition_id + "_" + year}>{year}</option>);

                }
                else {

                    filterItems.push(<MenuItem key={"menu_item_" + item.competition_details.competition_id + "_" + year} value={item.competition_details.competition_id + "_" + year}>{year}</MenuItem>);

                }

            }

        }

        if(selectedStatistics.length < 1) {

            var seasonName = current_player.statistics_breakdown_dto.season_breakdown[0].season_name;

            var competition_id = current_player.statistics_breakdown_dto.season_breakdown[0].competition_breakdown[0].competition_id;
            
            initialID = competition_id + "_" + seasonName;

            initialStatistics = current_player.statistics_breakdown_dto.season_breakdown[0].competition_breakdown[0];

        }
        else {

            initialStatistics = selectedStatistics;

        }

    }

    return current_player === null ? 
    
        (playerResponse !== null && playerResponse.data === null ? 
            
                <label>Please select player</label> : (
            
                <Box className="loading_spinner" sx={{ display: 'flex' }}>

                    <CircularProgress />
                    
                </Box>
            
            )
                        
        ) : (

        <MDBRow className="player_dashboard">

            {

                current_player.team_Profile == null ? <></> :

                <BreadcrumbNavigation content_setter={contentSetter} isMobile={isMobile} navigation_elements={[
                        
                    { name: current_player.team_Profile.team_name, id: current_player.team_Profile == null ? -1 : current_player.team_Profile.team_id, content_dashboard_type: isMobile ? 5 : 1 },
                    
                    { name: current_player.display_name, id: current_player.player_id, content_dashboard_type: isMobile ? 7 : 2 }
                
                ]} />

            }

            <MDBCol size="12" className="column_player_content pt-4 pb-4">

                <MDBRow>
                    
                    <MDBCol size="4">

                        <MDBRow>

                            <MDBCol className="column_player_profile_details" size="12">

                                <img className="player_profile_image" src={current_player.player_image} alt={current_player.player_name + " profile image"} />

                            </MDBCol>

                        </MDBRow>
                        
                    </MDBCol>

                    <MDBCol size="8" className="column_no_side_padding">

                        <MDBRow>

                            <MDBCol size="12">

                                <label className="label_player_name">{current_player.display_name}</label>

                                {/* <label className="label_player_position">{GetPositionName(current_player)}</label> */}

                            </MDBCol>

                        </MDBRow>

                    </MDBCol>
    
                </MDBRow>

            </MDBCol>

            <MDBCol className="column_no_side_padding mt-2">

                <MDBRow>

                    <MDBCol className="column_no_side_padding">

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 0, width: '100%', height: 'auto', padding: '20px' } }}>

                            <MDBRow style={{padding:0}}>

                                <MDBCol style={{paddingRight: '3px'}} sm="12" md="12" lg="6" xl="6" className="remove_inline_padding_mobile column_no_side_padding pb-2">

                                    <PlayerProfile current_player={current_player} />

                                    {/* <Paper elevation={1} className="p-1 mt-2">

                                        <p className="paragraph_player_bio" style={{paddingRight: "20px", paddingLeft: '20px', textAlign: 'justify'}}>

                                            {current_player.display_name} was born on {current_player.date_of_birth} in {current_player.birth_place === null ? '' : current_player.birth_place + ','} {current_player.birth_country} and plays for {current_player.team_Profile.team_name}.
                                        
                                            He played for KS Partyzant Leszno from 1996-1997, for MKS Varsovia Warschau from 1997-2004, 
                                            
                                            for Delta Warschau from 2005-2005, for Legia Warschau from 2005-2006, for MKS Znicz Pruszkow from 2006-2008, 
                                            
                                            for KKS Lech Posen from 2008-2010, for Borussia Dortmund from 2010-2014 and has played for FC Bayern München since 2014.

                                        </p>
                    
                                    </Paper> */}

                                </MDBCol>

                                <MDBCol style={{paddingLeft: '3px'}}  sm="12" md="12" lg="6" xl="6" className="remove_inline_padding_mobile column_no_side_padding">

                                    <React.Fragment>

                                    <Paper elevation={1}>

                                        <MDBRow>

                                            <MDBCol className="column_no_side_padding p-3 pb-2" style={{borderBottom: '1px solid #e5e5e5'}}>

                                                <span style={{marginRight: '10px'}}>
                            
                                                    <img className="league_logo" style={{ width: '30px' }} src={initialStatistics.competition_details.competition_Logo} />

                                                </span>
                                                
                                                <span style={{fontSize: '14px', fontWeight: '500'}}>
                                                    
                                                    <label>{selectedSeason === '' ? initialID.split('_')[1] : selectedSeason.split('_')[1]} - {initialStatistics.competition_details.competition_name}</label>

                                                </span>

                                            </MDBCol>
                                            
                                        </MDBRow>

                                        <MDBRow>

                                            <MDBCol md="12" className="pt-3">

                                                <FormControl sx={{ m: 0, minWidth: '100%', width: '100%' }}>

                                                    <InputLabel htmlFor="grouped-select">Season</InputLabel>

                                                    <Select 
                                                    
                                                        native={isMobile}

                                                        id="grouped-select" 
                                                        
                                                        label="Grouping"

                                                        onChange={handleDropdownChange}

                                                        value={selectedSeason === '' ? initialID : selectedSeason}
                                                        
                                                    >

                                                        {/* { isMobile ? <option value=''>All Fixtures</option> : <MenuItem value=''><em>Select Season</em></MenuItem>} */}

                                                        {

                                                            flattenChildren(filterItems)

                                                        }

                                                    </Select>

                                                </FormControl>

                                                <hr />

                                            </MDBCol>

                                        </MDBRow>

                                        <MDBRow className="pb-2">

                                            {

                                                 initialStatistics.breakdown_by_category.map((stat, index) => {

                                                    return (<React.Fragment key={"categories_" + stat.category_name}>

                                                        <MDBRow className="column_no_side_padding statistic_row">
        
                                                            <span className='statistic_row_header pb-2'>{stat.category_name}</span>
        
                                                            {

                                                                stat.statistic_values.map((category, innerIndex) => {

                                                                    return (

                                                                        <React.Fragment key={"category_fragment_" + category.statistic_label}>

                                                                            <div className="pb-1 pt-1">

                                                                                <span>{category.statistic_label}</span>
            
                                                                                <span size="4" style={{textAlign: 'center', fontWeight: '500' }}>{category.statistic_value}</span>
            
                                                                            </div>

                                                                            {

                                                                            innerIndex === stat.statistic_values.length - 1 && index !== initialStatistics.length - 1 ?

                                                                                <Divider style={{height: '0.5px', borderStyle: 'unset'}} className="mt-2 mb-3" /> : ""

                                                                            }

                                                                        </React.Fragment>

                                                                    )

                                                                })

                                                            }

                                                        </MDBRow>

                                                    </React.Fragment>)

                                                })

                                            }

                                            </MDBRow>

                                        </Paper>

                                    </React.Fragment>

                                </MDBCol>

                            </MDBRow>

                        </Box>

                    </MDBCol>

                </MDBRow>
                
            </MDBCol>

        </MDBRow>

    );

}