import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { useStandings } from "./standingsHook";
import { MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, { useRef, useState } from "react";
import { styled } from "@material-ui/core";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { BiChevronDown, BiChevronLeft, BiChevronUp } from "react-icons/bi";

const selectStyling = {

    // flex: '1', // Make the width flexible

    borderRadius: 2,

    backgroundColor: '#f5f5f5', // Light grey background

    border: 'none', // Remove border

    // display: 'flex',

    alignItems: 'left', // Vertically align items

    "& .MuiSelect-select": {

        padding: '10px', // Adjust padding as needed

        paddingRight: '30px', // Add space for the arrow icon

        display: 'flex',

        alignItems: 'center', // Vertically align items

    },

    ".MuiOutlinedInput-notchedOutline": { border: 0 },

    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {

        border: 0,

    },

    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {

        border: 0,

    },

};

const StyledTableCell = styled(TableCell)(({ theme }) => ({

    [`&.${tableCellClasses.head}`]: {

    // backgroundColor: '#344368',

    color: theme.palette.common.white

    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        whiteSpace: 'nowrap', // Prevent content wrapping
        overflow: 'hidden', // Hide overflow content
        textOverflow: 'ellipsis' // Display ellipsis (...) for overflow content
      },

  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(odd)': {

      backgroundColor: '#0d1e3312'

    },

    // hide last border

    '&:last-child td, &:last-child th': {
      border: 0,
      fontSize: 14
    }
}));

export function LeagueStandingsCondensed(props) {

    const componentMounted = useRef(true);

    const [isVisible, setIsVisible] = React.useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    const { contentSetter, competitions, isMobile, team_id } = props;

    const [leagueStandingsResponse, setLeagueStandings] = useState({ isLoading: false, data: null, season_id: competitions[0].season_id, round_id: -1 });

    useStandings(leagueStandingsResponse.season_id, leagueStandingsResponse.round_id, setLeagueStandings);

    const handleSelectedCompetitionChange = (event) => {
        const newSeasonId = event.target.value;
        setLeagueStandings(prevState => ({
            ...prevState,
            season_id: newSeasonId
        }));
    };

    const handleSelectedRoundChange = (event) => {
        const newRoundId = event.target.value;
        setLeagueStandings(prevState => ({
            ...prevState,
            round_id: newRoundId
        }));
    };

    if(leagueStandingsResponse == null || leagueStandingsResponse.data == null || leagueStandingsResponse.isLoading) {

        return (
    
            <Box className="loading_spinner" sx={{ height: '800px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <CircularProgress />
                
            </Box>
                
        );

    }

    var stage = leagueStandingsResponse.season_id === -1 ? leagueStandingsResponse.data[0] : leagueStandingsResponse.data.find(a => a.season_id === leagueStandingsResponse.season_id);

    if(!stage) {

        return (
        
            <Box className="loading_spinner" sx={{ height: '800px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    
                <CircularProgress />
                
            </Box>
            
        );

    }

    return (

        <>
        
        {

            <MDBRow className={`widget_fade_in ${isVisible ? 'fade-in' : ''}`}>

                <MDBCol className="no_side_padding">

                    <MDBCol className="no_side_padding pt-3" size='12'>

                        <label className="section_header no_background">Standings</label>

                    </MDBCol>

                    <MDBCol className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }}>

                        <FormControl sx={{ mb: 3 }} size="small" style={{ marginRight: '10px' }}>
                            
                            <Select

                                id="team_dashboard_competitions"

                                value={leagueStandingsResponse.season_id}
                                
                                label="Competitions"

                                onChange={handleSelectedCompetitionChange}

                                sx={selectStyling}

                            >

                                {competitions.filter(comp => comp.is_cup === false).map((competition, index) => (

                                    <MenuItem key={"standing_season_" + competition.season_id} value={competition.season_id}>
                                        
                                        <img style={{ width: '20px', height: '20px', marginRight: '10px' }} src={competition.league_logo} alt={competition.league_name} />
                                        
                                        <div style={{paddingLeft:'5px', paddingRight: '25px'}}>{competition.league_name}</div>
                                    
                                    </MenuItem>

                                ))}

                            </Select>

                        </FormControl>

                        <FormControl sx={{ mb: 3 }} size="small">

                            <Select

                                id="team_dashboard_competitions"

                                value={leagueStandingsResponse.round_id === -1 ? (stage == undefined || stage.rounds == undefined ? -1 : stage.rounds.find(a => a.is_current === true).id) : (leagueStandingsResponse.round_id || '')}
                                
                                label="Competitions"

                                onChange={handleSelectedRoundChange}

                                sx={selectStyling}

                            >

                                {stage.rounds.map((round, index) => (

                                    <MenuItem key={"standing_round_" + round.id} value={round.id}>
                                        
                                        <div style={{paddingLeft:'5px', paddingRight: '25px'}}>GW{round.name}</div>
                                    
                                    </MenuItem>

                                ))}

                            </Select>
                            
                        </FormControl>

                    </MDBCol>

                    <TableContainer>

                        <Table size="small">

                            <TableHead>

                                <TableRow>

                                    <TableCell>

                                        #

                                    </TableCell>

                                    <TableCell>

                                        Team

                                    </TableCell>

                                    <TableCell align="center">

                                        P

                                    </TableCell>

                                    <TableCell align="center">

                                        W

                                    </TableCell>

                                    <TableCell align="center">

                                        D

                                    </TableCell>

                                    <TableCell align="center">

                                        L

                                    </TableCell>

                                    <TableCell align="center">

                                        

                                    </TableCell>

                                    <TableCell align="center">

                                        PTS

                                    </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {

                                    stage.rounds.find(a => leagueStandingsResponse.round_id === -1 ? a.is_current === true : a.id ===  leagueStandingsResponse.round_id).standings.sort((a, b) => a.position - b.position).map((standing, index) => {

                                        return (<StyledTableRow key={"league_standings_team_" + standing.participant_id} style={{ background: standing.participant_id === team_id ? "#2F4369" : "" }}>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '', paddingLeft: '5px', paddingRight: '5px' }} align="center">{standing.position}</StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '', paddingLeft: '5px', paddingRight: '5px'}}><img style={{width:'20px', height:'20px', marginRight: '8px', position: 'relative', top:'-2px'}} src={standing.image_path} />{standing.team_short_name === '' || standing.team_short_name == null ? standing.team_name.toUpperCase().substring(0, 3) : standing.team_short_name}</StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '' }} align="center">{standing.details.find(a => a.type_id === 129).value}</StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '' }} align="center">{standing.details.find(a => a.type_id === 130).value}</StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '' }} align="center">{standing.details.find(a => a.type_id === 131).value}</StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '' }} align="center">{standing.details.find(a => a.type_id === 132).value}</StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '', paddingLeft: '0px', paddingRight: '0px' }} align="center">
                                                
                                                {
                                                
                                                    GetStandingResultIcon(standing.result)
                                                
                                                }
                                                
                                            </StyledTableCell>

                                            <StyledTableCell style={{ color: standing.participant_id === team_id ? 'white': '' }} align="center">{standing.points}</StyledTableCell>

                                        </StyledTableRow>
                                        )

                                    })

                                }

                            </TableBody>

                        </Table>

                    </TableContainer>

                </MDBCol>

            </MDBRow>

        }
        
        </>

    )

}

function GetStandingResultIcon(result) {

    switch(result)
    {

        case "equal":
            {

                return "-";

            }
        case "up":
            {

                return <BiChevronUp size={25} color="green" />
                
            }
        case "down":
            {

                return <BiChevronDown size={25} color="red" />
                
            }

    }

}