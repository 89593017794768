import { useRef, useState } from "react";
import ErrorBoundary from "../error/error_boundary";
import { Paper } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { BsClockFill } from "react-icons/bs";
import { BiCalendarEvent, BiFootball } from "react-icons/bi";
import { MdStadium } from "react-icons/md";
import { Link } from "react-router-dom";

export default function FixtureSchedule({ fixture_id, teams, schedule }) {
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted); 

    const BuildCompetitionString = (information) => {
        var details = information.league.name;
        if(information.stage != null) {
            details = `${details}, ${information.stage.name}`; 
        }
        if(information.round != null) {
            details = `${details}, Round ${information.round.name}`;
        }
        return details;
    }

    const BuildVenueDetails = (information) => {
        var details = information.venue.name;
        if(information.venue.city != null) {
            details = `${details}, ${information.venue.city}`; 
        }
        return details;
    }

    const scheduledetails = schedule.data;

    if(!scheduledetails) {
        return <></>
    }

    const venueLine = scheduledetails.venue && ` at the ${scheduledetails.venue.name} `; 

    const roundLine = scheduledetails.round && ` round ${scheduledetails.round.name} `;

    return schedule.isLoading || (schedule.data == null && !schedule.isLoading) ? <></> : (
        <ErrorBoundary>
          <Paper elevation={1} className={`mb-2 br-15 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
            <MDBRow>
                <MDBCol style={{ padding: '16px 24px' }}>
                    <div>
                        <h1 style={{textAlign: 'center', padding: '5px 0px 15px 0px', fontWeight: '500', fontSize: '18px'}}>Match Day: {scheduledetails.fixture_name}</h1>
                        <div style={{fontSize: '14px'}}>
                            <p>
                                Get ready for an exciting {roundLine} clash in the <b>{scheduledetails.league.name}</b> as <b>{teams.home_team.name}</b> take on <b>{teams.away_team.name}</b> {venueLine} on <b>{scheduledetails.display_date_time}</b>.
                            </p>

                            <p>
                                <Link className="anchor-tag" style={{ fontWeight: '500', textDecoration: 'none' }} to={`/fixture/${fixture_id}/${scheduledetails.fixture_url_name}/stat-pack`}>The stat pack</Link> from The Stat Man is your ultimate companion for pre-match insights and detailed analysis. Whether you’re preparing for a bet builder, planning your fantasy football strategy, or simply diving into the stats, we've got everything you need.
                            </p>

                            <p>Explore key metrics such as:</p>

                            <ul style={{marginBottom: '10px'}}>
                                <li>Shots on Target</li>
                                <li>Goals & Over/Under Metrics</li>
                                <li>Fouls & Cards</li>
                                <li>Big Chances Created</li>
                                <li>Key Passes & Duels</li>
                            </ul>
                            
                            <p>Our pre-match stat pack also includes team form, team performance insights, and predictions to help you break down the game.</p>

                            <p>Don't forget to check out The Stat Man's live features, including real-time match stats, live scores, and fixture schedules, ensuring you're always in the know before and during the action.</p>

                            <p>Prepare smarter, bet wiser, and enjoy the game like never before with The Stat Man.</p>
                        </div>
                    </div>
                </MDBCol>
            </MDBRow>
          </Paper>
      </ErrorBoundary>
    )
}