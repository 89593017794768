import { useCallback, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { AiOutlineSwap } from "react-icons/ai";
import { Divider, Paper } from "@mui/material";
import { GetGradientClass } from "../../../common/helpers/rating_helper";
import ErrorBoundary from "../../../application/error/error_boundary";

const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

const PlayerList = ({ players, placeholderImage }) => (
    <ul className="underlying_list_team_lineup">
        {players
            .sort((a, b) => a.formation_position - b.formation_position)
            .map((player) => (
                <li className="list_item_lineup_player" key={player.id}>
                    <img
                        src={player.player_image || placeholderImage}
                        alt={`${player.player_name} profile`}
                        onError={(e) => (e.target.src = placeholderImage)}
                        className="player-image"
                    />
                    <label className="label_player_number">{player.jersey_number}</label>
                    <div className="player-info">
                        <label className="label_player_name">{player.player_name}</label>
                        {player.rating && player.rating > 0 && player.rating != 0 && (
                            <span
                                className={`${GetGradientClass(player.rating)} span_player_fixture_rating`}
                            >
                                {player.rating?.toFixed(1) || "-"}
                            </span>
                        )}
                    </div>
                </li>
            ))}
    </ul>
);

const LineupSection = ({ lineupData, placeholderImage }) => {
    const substitutes = lineupData.filter(player => player.type_name === "Bench");
    return (
        <MDBRow>
            <MDBCol className="column_team_lineup no_side_padding" size="12">
                <PlayerList 
                    players={lineupData.filter(player => player.type_name === "Lineup")} 
                    placeholderImage={placeholderImage} 
                />
            </MDBCol>
            {substitutes.length > 0 && (
                <>
                    <div className="substitute_divider" style={{ display: "flex", gap: "12px" }}>
                        <AiOutlineSwap size={25} />
                        <span>Substitutes</span>
                    </div>
                    <MDBCol className="column_team_lineup no_side_padding" size="12">
                        <PlayerList 
                            players={substitutes.sort((a, b) => a.jersey_number - b.jersey_number)} 
                            placeholderImage={placeholderImage} 
                        />
                    </MDBCol>
                </>
            )}
        </MDBRow>
    );
};

const MobileFixtureLineups = ({ fixtureLineups, teams, fixture_id, last_updated }) => {

    const displayPlayerStats = useCallback((playerId) => {
        // Handle player stats display logic
    }, []);

    const [IsHome, setIsHome] = useState(true);

    if (fixtureLineups.isLoading || !fixtureLineups.data) {
        return null;
    }

    return (
        <ErrorBoundary>
            {fixtureLineups.data.home_team_lineup.length > 0 &&
            fixtureLineups.data.away_team_lineup.length > 0 ? (
                <>
                    <Paper elevation={1} className="mb-2 br-15 widget_fade_in">
                        <MDBRow>
                            <MDBCol className="pt-3 pb-3" size="12">
                                <div style={{display: 'flex'}}>
                                    <div style={{
                                            width: '50%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            backgroundColor: IsHome ? '#1976d21f' : '#0000000a',
                                            borderRadius: '9px 0px 0px 9px',
                                            height: '40px',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => setIsHome(true)}
                                    >
                                        <img style={{width: '28px', height: '28px'}} src={teams.home_team.logo}alt={teams.home_team.name + " team logo"} />
                                    </div>
                                    <div style={{
                                            width: '50%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            backgroundColor: IsHome ? '#0000000a' : '#1976d21f',
                                            borderRadius: '0px 9px 9px 0px',
                                            height: '40px',
                                            alignItems: 'center'
                                        }}
                                        onClick={() => setIsHome(false)}
                                    >
                                        <img style={{width: '28px', height: '28px'}} src={teams.away_team.logo} alt={teams.away_team.name + " team logo"} />
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <Divider style={{opacity: '0.1'}} />
                        <MDBCol className='column_fixture_lineup no_side_padding' style={{paddingTop: '5px'}} size='12'>
                            <MDBRow>
                                {<MDBCol className="no_side_padding" size="12">
                                    <LineupSection 
                                        lineupData={IsHome ? fixtureLineups.data.home_team_lineup : fixtureLineups.data.away_team_lineup}
                                        placeholderImage={placeholderImage}
                                        onPlayerClick={displayPlayerStats} 
                                    />
                                </MDBCol>}
                            </MDBRow>
                        </MDBCol>
                    </Paper>
                </>
            ) : null}
        </ErrorBoundary>
    );
};

export default MobileFixtureLineups;
