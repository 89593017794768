import { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from "@microsoft/signalr";
import configuration from '../../../config.json';
import { IsToday, IsTodaySetState } from "../../../common/helpers/date_helper";

export function useLiveScoresSubscription(site_date, setter) {

    const [ connection, setConnection ] = useState(null);

    const [ isToday, setIsToday ] = useState(IsToday(site_date));

    IsTodaySetState(site_date, setIsToday, isToday);

    useEffect(() => {

        if(!isToday && connection) {

            connection.stop();

        }
        else if(isToday) {

            const newConnection = new HubConnectionBuilder()

                .withUrl(configuration.SignalR_Livescore_Service_URL, {

                    skipNegotiation: true,

                    transport: signalR.HttpTransportType.WebSockets
                    
                })

                .withAutomaticReconnect()

                .build();

            setConnection(newConnection);

        }

    }, [isToday]);

    useEffect(() => {

        if (connection) {

            connection.start()

                .then(result => {

                    console.log('SignalR Socket Connection (Livescores) Status: Connected!');
    
                    connection.on('ReceiveLiveScores', livescores => {

                        setter({ isLoading: false, data: livescores });

                    });

                })

            .catch(e => 
                
                console.log('Connection failed: ', e)
                
            );

        }

        return () => {

            if (connection) {

                console.log("Disconnecting from SignalR socket.");

                connection.off("ReceiveLiveScores");

            }

        };

    }, [connection]);

}

export function useLiveFixtureSubscription(fixture_id, setter) {
    const [hubConnection, setHubConnection] = useState(null);
    const isUnmountedRef = useRef(false);

    useEffect(() => {
        let isCreatingConnection = false;

        const createConnection = async () => {
            isCreatingConnection = true;
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(configuration.SignalR_Fixture_Service_URL, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .build();

            try {
                await connection.start();
                console.log("SignalR Socket Connection (Fixture) Status: Connected!");

                if (!isUnmountedRef.current) {
                    await subscribeToFixture(connection, fixture_id);

                    connection.on("ReceiveFixtureUpdate", (fixturedetails) => {
                        if (!isUnmountedRef.current) {
                            // console.log(`Received update for fixture ${fixture_id}:`, fixturedetails);
                            setter({
                                isLoading: false,
                                data: fixturedetails,
                                fixture_id,
                                last_updated: fixturedetails.lastupdated,
                            });
                        }
                    });

                    if (!isUnmountedRef.current) {
                        setHubConnection(connection); // Only set state if component is mounted
                    }
                } else {
                    await connection.stop(); // If unmounted, stop the connection
                }
            } catch (e) {
                if (!isUnmountedRef.current) {
                    console.error("Connection failed: ", e);
                }
            } finally {
                isCreatingConnection = false;
            }
        };

        const subscribeToFixture = async (connection, newFixtureID) => {
            try {
                await connection.invoke("SubscribeToGroup", `fixture_${newFixtureID}`, '');
                console.log(`Subscribed to fixture ${newFixtureID}`);
            } catch (e) {
                if (!isUnmountedRef.current) {
                    console.error(`Subscription failed for fixture ${newFixtureID}:`, e);
                }
            }
        };

        // If a new connection is required, create it
        if (!hubConnection && !isCreatingConnection) {
            createConnection();
        } else if (hubConnection?.state === signalR.HubConnectionState.Connected) {
            // Subscribe to the new fixture
            subscribeToFixture(hubConnection, fixture_id);
        }

        // Cleanup function for unsubscribing from the current fixture
        return () => {
            isUnmountedRef.current = true; // Mark the component as unmounted

            if (hubConnection?.state === signalR.HubConnectionState.Connected) {
                console.log("Disconnecting from SignalR socket for fixture", fixture_id);
                
                // Unsubscribe from the current fixture group before stopping the connection
                hubConnection.invoke("UnsubscribeFromGroup", `fixture_${fixture_id}`)
                    .then(() => {
                        hubConnection.stop()
                            .then(() => {
                                if (!isUnmountedRef.current) {
                                    setHubConnection(null); // Clean up connection state
                                }
                            })
                            .catch((e) => console.error("Error stopping connection:", e));
                    })
                    .catch((e) => console.error("Error unsubscribing:", e));
            }
        };
    }, [fixture_id, setter]); // Run effect when fixture_id or setter changes

    return hubConnection;
}
