import configuration from '../config.json';
import axios from 'axios';

export const getFixtureName = async (fixtureId) => {
    try {
        const response = await axios.get(`${configuration.FRONT_END_API_v2}fixtures/${fixtureId}/name`);
        return response.data;
    } catch (error) {
        console.error("Error fetching fixture name:", error);
        return '';
    }
};