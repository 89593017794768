import { Alert, Paper } from "@mui/material";
import ErrorBoundary from "../../../application/error/error_boundary";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { GetGradientClass } from "../../../common/helpers/rating_helper";
import {footballPitchSVG, pitchLines } from "../fixture/mobile_fixture_constants";
import '../../application/dashboards/css/mobile_fixture_pitch.css'
import image from '../../../images/fixture-pitch.png';
import { BiFootball } from "react-icons/bi";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import * as React from 'react';
import PlayerStatisticModal from "./lineup/player_statistics_modal";

export default function MobileFixturePitch({ fixtureLineups, teams }) {
    const [modalState, setModalState] = React.useState({ player: null, open: false });

    const extractPlayerStatistics = (lineup) => {
        return lineup.map(player => ({
            player_id: player.player_id,
            player_image: player.player_image,
            statistics: player.lineup_statistics,
            statistics_wrapper: player.statistics_wrapper
        }));
    };
  
    const awayTeamStatistics = extractPlayerStatistics(fixtureLineups.data.away_team_lineup);
    const homeTeamStatistics = extractPlayerStatistics(fixtureLineups.data.home_team_lineup);
    
    const allPlayerStatistics = [
        ...awayTeamStatistics,
        ...homeTeamStatistics,
    ];

    const setUpdatedModalState = (open, player) => {
        if(player) {
            var statPlayer = allPlayerStatistics.find(stat => stat.player_id === player.playerId);
            if(statPlayer && statPlayer.statistics.length > 0) {
                setModalState((prev) => ({
                    player: { 
                        ...player,
                        statistics_wrapper: statPlayer ? statPlayer.statistics_wrapper : null,
                        statistics: statPlayer ? statPlayer.statistics : null, 
                        team: player.homeTeam ? teams.home_team : teams.away_team 
                    },
                    open: open,
                }));
            }
        }
        else {
            setModalState((prev) => ({
                player: null,
                open: open,
            }));
        }
    }

    var home_lineup = fixtureLineups.data.home_team_lineup
        .filter(player => player.type_name === 'Lineup')
        .sort((a, b) => a.formation_position - b.formation_position);

    var away_lineup = fixtureLineups.data.away_team_lineup
            .filter(player => player.type_name === 'Lineup')
            .sort((a, b) => a.formation_position - b.formation_position);

    var home_formation = fixtureLineups.data.home_team_formation;
    var away_formation = fixtureLineups.data.away_team_formation;

    const homeFormation = BuildFormation(home_formation, home_lineup, true);
    const awayFormation = BuildFormation(away_formation, away_lineup, false);

    const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

    if(homeFormation.length < 1 || awayFormation.length < 1) {
        return (
            <Paper elevation={1} className="mb-2 br-15 widget_fade_in">
                <Box className="p-3">
                    <Alert severity="info" fontSize={16}>Confirming lineups...</Alert>
                </Box>
            </Paper>
        )
    }

    return (
        <ErrorBoundary>
            <Paper elevation={1} className="mb-2 br-15 widget_fade_in">
                <AverageRatingsDisplay team={teams.home_team} rating={fixtureLineups.data.average_home_team_rating} formation={home_formation} />
                <MobilePitch setUpdatedModalState={setUpdatedModalState} home_lineup={homeFormation} away_lineup={awayFormation} placeholderImage={placeholderImage} />
                <AverageRatingsDisplay team={teams.away_team} rating={fixtureLineups.data.average_away_team_rating} formation={away_formation} />
                <PlayerStatisticModal setModalState={setModalState} modalState={modalState} />
            </Paper>
        </ErrorBoundary>
    )
}

const AverageRatingsDisplay = ({ team, rating, formation }) => (
    <MDBRow>
        <MDBCol>
            <RatingCard 
                gradientClass={GetGradientClass(rating)}
                rating={rating}
                team={team}
                formation={formation}
            />
        </MDBCol>
    </MDBRow>
);

const RatingCard = ({ team, gradientClass, rating, formation }) => (
    <div style={{ padding: '15px 0px 15px 0px', background: 'white' }}>
        <MDBRow>
            <MDBCol className='no_side_padding' style={{ display: 'flex', justifyContent: 'start', gap: '14px', alignItems: 'center', flexDirection: 'row' }}>
                <span className={"span_player_fixture_rating " + gradientClass}>
                    {rating == 0 ? "-" : rating?.toFixed(2) || "-"}
                </span>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center', flexDirection: 'row' }}>
                    <img 
                        src={team.logo} 
                        alt={team.name + " logo"} 
                        style={{ width: '28px', height: '28px' }}
                    />
                    <span>
                        {team.name}
                    </span>
                </div>
                <span className="formation-pill">{formation}</span>
            </MDBCol>
        </MDBRow>
    </div>
);

const MobilePitch = ({ setUpdatedModalState, home_lineup, away_lineup, placeholderImage }) => (
    <MDBCol className="pitch-container" style={{backgroundColor: '#b8cca6'}}>
        {/* Home Team */}
        <div className="team-wrapper">
            <div className="formation-team home-team">
                {home_lineup.map((lineup, index) => (
                    <div key={`home-lineup-${index}`} className="formation-row">
                        {lineup.map((player, idx) => (
                            <div onClick={() => setUpdatedModalState(true, player)} key={`home-player-${index}-${idx}`} className={index === 0 ? "home-goalkeeper player" : "player"}>
                                <img 
                                    src={player.playerImage || placeholderImage} 
                                    onError={(e) => { e.target.src = placeholderImage; }}
                                    alt={player.name} 
                                    style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'white' }} 
                                />
                                <span className="player-name">{player.name}</span>
                                {player.rating === null || player.rating === "" || player.rating === 0 ? <></> : <span className={player.ratingClass + " player-rating"}>{player.rating}</span>}
                                {player.totalGoals > 0 ? <span className="player-goals"><BiFootball color="green" /></span> : <></>}
                                <CardType status={player.cardStatus} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
        {/* Home Team */}

        {/* Away Team */}
        <div className="team-wrapper">
            <div className="formation-team away-team">
                {[...away_lineup].reverse().map((lineup, index) => (
                    <div key={`away-lineup-${index}`} className="formation-row-away">
                        {lineup.map((player, idx) => (
                            <div  onClick={() => setUpdatedModalState(true, player)} key={`away-player-${index}-${idx}`} className={index === away_lineup.length - 1 ? "away-goalkeeper player" : "player"}>
                                <img 
                                    src={player.playerImage || placeholderImage} 
                                    onError={(e) => { e.target.src = placeholderImage; }}
                                    alt={player.name} 
                                    style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'white' }} 
                                />
                                <span className="player-name">{player.name}</span>
                                {player.rating === null || player.rating === "" || player.rating === 0 ? <></> : <span className={player.ratingClass + " player-rating"}>{player.rating}</span>}
                                {player.totalGoals > 0 ? <span className="player-goals"><BiFootball color="green" /></span> : <></>}
                                <CardType status={player.cardStatus} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
        {/* Away Team */}
    </MDBCol>
);

function CardType({ status }) {
    switch (status) {
        case 1:
            return <span className="yellow-card"></span>;
        case 2:
            return <span className="yellow-red-card" />;
        case 3:
            return <span className="red-card" />;
        default:
            return null;
    }
}

function BuildFormation(formation, players, isHome, callback) {
    const formationNumbers = formation.split('-').map(Number);
    const formationRows = [];
    const goalkeeper = players[0]; 

    if(goalkeeper) {
        formationRows[0] = [mapPlayer(goalkeeper, callback, isHome ? '#000000' : '#FFFFFF', isHome ? '#FFFFFF' : '#000000', isHome)];
    }
    
    let positionIndex = 1;
    for (let col = 0; col < formationNumbers.length; col++) {
        const positionalPlayers = [];
        for (let row = 0; row < formationNumbers[col]; row++) {
            const player = players[positionIndex];
            if (player) {
                positionalPlayers.push(
                    mapPlayer(player, callback, isHome ? '#000000' : '#FFFFFF', isHome ? '#FFFFFF' : '#000000', isHome)
                );
                positionIndex++;
            }
        }
        if(positionalPlayers.length > 0) {
            formationRows.push(positionalPlayers);
        }
    }

    return formationRows;
}

function mapPlayer(player, callback, color, textColor, homeTeam) {
    return {
        homeTeam,
        numberColor: textColor,
        nameColor: textColor,
        color,
        playerId: player.player_id,
        name: player.player_name,
        number: player.jersey_number,
        onClick: callback,
        style: { numberColor: textColor, nameColor: textColor, color },
        cardStatus: player.card_status,
        totalGoals: player.total_goals || 0,
        totalAssists: null,
        rating: player.rating ? parseFloat(player.rating).toFixed(1) : 0,
        ratingClass: GetGradientClass(player.rating),
        playerImage: player.player_image
    };
}