import { Box, CircularProgress, Typography } from "@mui/material";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";

export default function LiveScoreWidgetSkeleton() {

    return (
        <div className="dashboard_widget">
            <div>
                <MDBRow>
                    <MDBCol size="12">
                        <Box sx={{ 
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            textAlign: 'center',
                            alignItems: 'center',
                            minHeight: '300px' 
                        }}>
                            <CircularProgress size="4rem" />
                            <Typography variant="h5" sx={{color: '#0D1E33'}}>Fetching scheduled fixtures...</Typography>
                        </Box>

                    </MDBCol>
                </MDBRow> 
            </div>
        </div>
    )

}