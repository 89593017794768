import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useSquad } from './teamHook';
import { CircularProgress } from '@material-ui/core';
import { Box } from '@mui/material';
import { useIsVisible } from '../shared/hooks/isVisibleHook';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#344368',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#0d1e3312',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function SquadList(props) {

    const { team_id } = props;

    const componentMounted = React.useRef(true);

    const [squadResponse, setSquadResponse] = React.useState(false);

    const [isVisible, setIsVisible] = React.useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    useSquad(team_id, setSquadResponse);

    if(squadResponse == null || squadResponse.data == null || squadResponse.isLoading || team_id !== squadResponse.team_id) {

            return (
        
                <Box className="loading_spinner" sx={{ display: 'flex' }}>
    
                    <CircularProgress />
                    
                </Box>
                
            );

    }

    const positionDictionary = {
        G: 'GOALKEEPER',
        D: 'DEFENDER',
        M: 'MIDFIELDER',
        A: 'ATTACKER',
        F: 'STRIKER',
    };

    const positionColors = {
        G: '#e59c03',
        D: '#374df5',
        M: 'rgba(19, 175, 102, 0.9)',
        A: 'rgba(199, 54, 31, 0.9)',
        F: 'rgba(199, 54, 31, 0.9)',
    };

    return (

    <TableContainer className={`widget_fade_in ${isVisible ? 'fade-in' : ''}`} component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">#</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Player</StyledTableCell>
            <StyledTableCell align="center">Nationality</StyledTableCell>
            <StyledTableCell align="center">Position</StyledTableCell>
            <StyledTableCell align="center">Detailed Position</StyledTableCell>
            <StyledTableCell align="center">Age</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {squadResponse.data.map((player, index) => (
            <StyledTableRow 

              key={"squad_player_" + index + "_" + player.id}

              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              
            >
              <StyledTableCell align="center" component="th" scope="row">
                <div style={{
                    backgroundColor: '#51565f',
                    position: 'relative',
                    width: '26px',
                    height: '26px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold'
                }}>
                    <span style={{ color: 'white', textAlign: 'center' }}>{player.jersey_number == null ? "-" : player.jersey_number}</span>
                </div>
            </StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                <img src={player.image_path} style={{width:'30px', height: '30px', borderRadius: '50%'}} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {player.name}
              </StyledTableCell>
              <StyledTableCell align="center" style={{fontWeight: '500'}}>{player.country_name}</StyledTableCell>
              <StyledTableCell align="center" style={{color: positionColors[player.position], fontWeight: '500'}}>{positionDictionary[player.position]}</StyledTableCell>
              <StyledTableCell align="center">{player.detailed_position == "" || player.detailed_position == null ? "-" : player.detailed_position}</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
            </StyledTableRow >
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}