import React, { useState, useRef } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import Autocomplete from '../shared/widget/autocomplete_textbox.js';
import { GetGradientClass } from '../../common/helpers/rating_helper.js';
import { usePlayersForSelection } from '../player/playerHook.js';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useIsVisible } from '../shared/hooks/isVisibleHook.js';
import hour_glass_gif from '../../images/hour_glass.gif';
import { MdManageSearch } from "react-icons/md";

import '../dashboards/css/players_dashboard.css';
import NoWidgetContent from '../shared/widget/no_widget_content.js';

export default function PlayersDashboard(props) {

    const componentMounted = useRef(true);

    const { contentSetter, currentContentID, currentLeagueID, isMobile } = props;

    const [playerSearchState, setPlayerSearchState] = useState({ isLoading: true, data: [], current_league_id: currentLeagueID, searchTerm: null, page: 1, totalPages: 1 });
    
    const [isVisible, setIsVisible] = React.useState(false);

    usePlayersForSelection(currentLeagueID, playerSearchState.searchTerm, playerSearchState.page, setPlayerSearchState);

    useIsVisible(isVisible, setIsVisible, componentMounted); 

    const handleChange = (event, value) => {

        setPlayerSearchState(prevFilterState => {

            return { 
                
                ...prevFilterState,

                page: value

            }

        })

    };

    const inputChange = (event, value) => {

        setPlayerSearchState(prevFilterState => {

            return { 
                
                ...prevFilterState,

                page: 1,

                searchTerm: value

            }

        })

    };

    if(playerSearchState == null || playerSearchState.isLoading)
    {
        return  (<NoWidgetContent no_content_heading={"Loading Players"} no_content_sub_heading={"Currently fethcing players, please wait..."} image_src={hour_glass_gif} />)
    }

    return (<React.Fragment>

            <MDBRow className={`dashboard_widget players_dashboard pb-2 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>

                <MDBCol className='column_player_selection_search' xs="12" sm="12" md="12" lg="12" xl="12">

                    <Autocomplete searchTerm={playerSearchState.searchTerm} currentLeagueID={currentLeagueID} setSearchTerm={inputChange} onClick={contentSetter} placeholder="Search Players..." />

                </MDBCol>

                <MDBCol className='players_dashboard_pagnation column_no_side_padding' xs="12" sm="12" md="12" lg="12" xl="12">

                    <Stack spacing={2}>

                        <Pagination 
                        
                            count={playerSearchState.totalPages} 
                        
                            color="primary" 
                        
                            onChange={handleChange} 
                        
                            page={playerSearchState.page} />

                    </Stack>

                </MDBCol>

                {

                    (

                        playerSearchState.data === null || playerSearchState.data.length === 0 ?
                
                            <MDBRow>
        
                                <MDBCol size="12" className="p-3">
        
                                    <MdManageSearch color='#0D1E33' style={{marginLeft: 'auto', marginRight: 'auto', display: 'block' }} size={150} />
        
                                </MDBCol>
        
                                <MDBCol size="12">
        
                                    <p className="label_no_results_heading">No Players</p>
        
                                    <p className="label_no_results_sub_heading">Try altering your filters and trying again.</p>
        
                                </MDBCol>
        
                            </MDBRow> 

                         :

                        <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="column_player_selection pt-2">

                            <ul className="list_player_Selection">

                                {

                                    playerSearchState.data.map((player, index) =>

                                        <li key={"player_search_widget_" + player.id} className="clickable list_item_player_selection" onClick={() => contentSetter(isMobile ? 7 : 2, player.id)}>

                                            <div>
     
                                                <div>

                                                    <img className="image_player_profile" src={player.image_path} alt={player.name + " profile image"} />

                                                </div>

                                                <div className="player_details">

                                                    <span className='player_name elipsis_text_wrap'>{player.name}</span>

                                                    <div>

                                                        {
                                                            
                                                            player.team_image_path == null ? "" : <img className="image_team_logo" src={player.team_image_path} alt={player.team_name + " team logo"} />
                                                            
                                                        }

                                                        <span className="team_name elipsis_text_wrap">{player.team_name}</span>

                                                    </div>

                                                </div>

                                                <div className={"player_rating " + GetGradientClass(player.average_rating)}>

                                                    <span className="player_rating">{player.average_rating == null ? "-" : player.average_rating}</span>

                                                </div>
                                                    
                                            </div>

                                        </li>

                                    )

                                }

                            </ul>

                        </MDBCol>

                    )

                }

            </MDBRow>

        </React.Fragment>

    );

}