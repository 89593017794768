
import { Routes, Route, Navigate } from "react-router-dom";
import  { BreakpointProvider } from 'react-socks';
import { MDBContainer } from 'mdb-react-ui-kit';
//../../../Components/navigation/top_menu.js';
import PrimaryDashboard from './application/dashboards/views/primary_dashboard';
import { HelmetProvider } from 'react-helmet-async';
import '../src/css/colour_gradient.css';
import './application/dashboards/css/layout_styling.css';
import '../src/css/site_typography.css';
import StatisticsDashboard from "./application/dashboards/views/statististics_dashboard";
import PlayerComparison from './application/player_statistics/player_comparison';
import TeamComparison from "./application/team_statistics/team_comparison";
import PlayerComparisonOne from "./application/player_statistics/player_comparison_one";

export default function App() {
  return (
    <HelmetProvider>
      <BreakpointProvider>
        <MDBContainer className="myCustomTheme website-wrapper">
          <Routes>
            <Route exact path="/" element={<PrimaryDashboard />} />
            <Route exact path="/fixture/:fixture_id/:fixture_name" element={<PrimaryDashboard />} />
            <Route exact path="/fixture/:fixture_id/:fixture_name/stat-pack" element={<PrimaryDashboard />} />
            <Route exact path="/dashboards/statistics" element={<StatisticsDashboard />}>
              <Route path="/dashboards/statistics/players" element={<PlayerComparisonOne />} />
              <Route path="/dashboards/statistics/insights" element={<PlayerComparison />} />
              <Route path="/dashboards/statistics/teams" element={<TeamComparison />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />}/>
          </Routes>
        </MDBContainer>
      </BreakpointProvider>
    </HelmetProvider>
  )
}