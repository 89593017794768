import { useEffect } from "react";
import configuration from '../../config.json';
import axios from 'axios';

export const useStandings = (seasonId, round_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      (async () => {
        if(isMounted) {
          try {
            const response = await axios.get(`${configuration.FRONT_END_API_v2}standings/${seasonId}`);
    
            var result = { isLoading: false, data: null, season_id: seasonId, round_id: round_id };
            if (response.status === 200) {
              result.data = response.data;
            }
  
            setter(result);
          } 
          catch (error) {
            setter({ isLoading: false, data: null, season_id: seasonId, round_id: round_id });
          }
        }
      })();
  
      return () => {
        isMounted = false;
      };
    }, [seasonId, setter])
}

export const useLeagueStandings = (league_id, setter) => {
  
  var result = { 
    isLoading: true,
    data: null, 
    league_id: league_id, 
    selected_competition: -1,
    selected_round: -1,
    home_away_overall: 1 
  };

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if(isMounted) {
        try {
          const response = await axios.get(`${configuration.FRONT_END_API_v2}standings/league/${league_id}`);

          if (response.status === 200) {
            result.isLoading = false;
            result.data = response.data;
          }

          setter(result);
        } 
        catch (error) {
          setter({ 
            isLoading: false, 
            data: null, 
            league_id: league_id, 
            selected_competition: -1,
            selected_round: -1,
            home_away_overall: 1 
          });
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [league_id, setter])
}