import { MDBContainer } from 'mdb-react-ui-kit';
import { MDBCol, MDBRow } from 'mdbreact';
import { GrFacebook, GrInstagram, GrTwitter, GrLinkedin } from 'react-icons/gr';
import buy_me_a_coffee_src from '../../../images/bmc_qr.png';
import Chip from '@mui/material/Chip';

export default function DashboardFooter() {

    return (

        <>
        
            <MDBContainer className="container_footer_information">

                <MDBRow>

                    <MDBCol size="12">

                        <img src={buy_me_a_coffee_src} style={{width: '100%', height: '150px'}} alt="Buy me a coffee barcode" />

                    </MDBCol>

                    <MDBCol className="pt-3" size="12">

                        <Chip

                            sx={{
                                width: '100%',
                                    '&:hover': {
                                    backgroundColor: 'white',
                                    color: '#0D1E33 !important',
                                    cursor: 'pointer'
                                },
                            }}

                            component="a"

                            href="https://www.buymeacoffee.com/thestatman"

                            target="_blank"

                            label="Support the stat man"

                            variant="outlined"

                        />
                        
                    </MDBCol>

                </MDBRow>

            </MDBContainer>

            <MDBContainer className="container_footer_information">

                <MDBRow>

                    <MDBCol>

                        <MDBRow className="">

                            <MDBCol size="3">

                                <a href="https://www.facebook.com/thestatmanuk" target="_blank" rel="nofollow" title="Facebook">
                                    
                                    <GrFacebook size={20} fill="white" />

                                </a>

                            </MDBCol>

                            <MDBCol size="3">

                                <a href="https://www.instagram.com/thestatmanuk/" target="_blank" rel="nofollow" title="Instagram">
                                    
                                    <GrInstagram size={20} />

                                </a>

                            </MDBCol>

                            <MDBCol size="3">

                                <a href="https://twitter.com/TheStatManUK/" target="_blank" rel="nofollow" title="Twitter">
                                    
                                    <GrTwitter size={20} />

                                </a>

                            </MDBCol>

                            <MDBCol size="3">

                                <a href="https://www.linkedin.com/company/the-stat-man/" target="_blank" rel="nofollow" title="LinkedIn">
                                    
                                    <GrLinkedin size={20} />

                                </a>

                            </MDBCol>

                        </MDBRow>

                    </MDBCol>

                </MDBRow>

            </MDBContainer>

            <div className="footer_copywrite mb-4" style={{color:"white", textAlign: "center"}}>

                <span>
                    
                    &copy; {new Date().getFullYear()} Copyright:{' '}
                
                    <a className='text-white' href='https://thestatman.co.uk/'>

                        www.thestatman.co.uk

                    </a>

                </span>

            </div>

        </>

    )

}