import { Box, CircularProgress, Paper, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, 
    Tooltip,
    IconButton} from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useFixtureStatPack } from "../../fixtures/fixtureHook";
import { useEffect, useRef, useState } from "react";
import errorImg from '../../../images/opps_something_went_wrong.jpg';
import { useIsVisible } from "../../shared/hooks/isVisibleHook";
import { GetOutcomeClass } from "../../../common/helpers/rating_helper";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
import statmanWhiteLogo from '../../../images/stat-man-banner-logo-white.png';
import { CiImageOn } from "react-icons/ci";
import { ButtonGroup } from "reactstrap";
import { TbEyeSearch } from "react-icons/tb";
import FixtureStatPackHelmet from "../../helmets/fixture_stat_pack_helmet";
import { GoDotFill } from "react-icons/go";

export default function TeamStatPack({ contentSetter, isMobile, fixture_id }) {
    const componentMounted = useRef(true);

    const [fixtureStatPackDashboardResponse, setFixtureStatPackDashboard] = useState({
        isLoading: true,
        data: null,
        fixture_id
    });

    const [isVisible, setIsVisible] = useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    useFixtureStatPack(fixture_id, setFixtureStatPackDashboard);

    const [showLoading, setShowLoading] = useState(true);
    useEffect(() => {
        if (!fixtureStatPackDashboardResponse.isLoading) {
            const timeout = setTimeout(() => {
                setShowLoading(false);
            }, 300);

            return () => clearTimeout(timeout);
        }
    }, [fixtureStatPackDashboardResponse.isLoading]);

    if(!fixture_id && fixture_id < 1) {
        return (
            <Paper className="br-15" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <MDBRow>
                    <MDBCol size='12'>
                        <Typography style={{textAlign: 'center'}} variant="h5">Select a fixture</Typography>
                        <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">Select a fixture from the live scores widget to view their stat pack</Typography>
                    </MDBCol>
                </MDBRow>
            </Paper>
        )
    }

    if(fixtureStatPackDashboardResponse.data === null && !fixtureStatPackDashboardResponse.isLoading) {
        return (
        <Paper className="br-15" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <img src={errorImg} alt='no available stat pack' style={{height: '300px', width: '275px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
                </MDBCol>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">An error occured</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">The fixture stat pack you where looking for could not be found</Typography>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if (showLoading || !fixtureStatPackDashboardResponse.data ||
        fixtureStatPackDashboardResponse.isLoading ||
        fixture_id !== fixtureStatPackDashboardResponse.fixture_id) 
    {
        return (
            <Paper className="br-15" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </Paper>
        );
    }

    let statpack = fixtureStatPackDashboardResponse.data;

    const handleCapture = async () => {
        const element = document.getElementById("stat-pack-capture-area");
        try {
            const canvas = await html2canvas(element, {
                useCORS: true,
                scale: 2,
            });
            const dataUrl = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `${statpack.fixture_url_name}.png`;
            link.click();
        } catch (error) {
            console.error("Failed to capture image:", error);
        }
    };

    return (
        <>
            <FixtureStatPackHelmet 
                fixture_description={statpack.fixture_description} 
                fixture_url={statpack.fixture_url_name}
                home_team_name={statpack.home_team_pack.team_name}
                away_team_name={statpack.away_team_pack.team_name} 
                fixture_id={fixture_id}
                league_logo={statpack.league_logo}
            />
            <div id="stat-pack-capture-area" className={`dashboard_widget pt-3 pb-3 ${isVisible ? 'fade-in' : ''}`}>
                <MDBRow>
                    <MDBCol className="pb-4" style={{ paddingLeft: '20px' }} size="12">
                        <div style={{display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}
                        >
                            <ButtonGroup
                                variant="contained"
                                aria-label="Disabled button group"
                            >
                                <Tooltip describeChild title="View fixture">
                                    <Link to={`/fixture/${fixture_id}/${statpack.fixture_url_name}`} style={{verticallyAlign: 'middle'}}>
                                        <IconButton variant="outlined" color="primary">
                                            <TbEyeSearch color="#121e32" />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                                
                                <Tooltip describeChild title="Download stat pack">
                                    <IconButton variant="outlined" onClick={() => handleCapture()} color="primary">
                                        <CiImageOn color="#121e32" />
                                    </IconButton>
                                </Tooltip>
                            </ButtonGroup>

                            <img src={statmanWhiteLogo} style={{width: '160px', float: 'center', marginRight: '15px' }} />
                        </div>
                    </MDBCol>
                    <MDBCol className="no_side_padding" size="12">
                        <MatchStatsTable statpack={statpack} />
                    </MDBCol>
                </MDBRow>
            </div>
        </>
    )
}

const StatRow = ({ title, homeData, homeAverage, awayData, awayAverage, hasAverage = true }) => (
    <TableRow>
        <StatCell data={homeData} />
        <TableCell style={styles.centerText}>{hasAverage ? homeAverage : "-"}</TableCell>
        <TableCell style={styles.statTitle}>{title}</TableCell>
        <TableCell style={styles.centerText}>{hasAverage ? awayAverage : "-"}</TableCell>
        <StatCell data={awayData} />
    </TableRow>
);
  
const StatCell = ({ data }) => (
    <TableCell style={styles.centerText}>
        <MDBRow style={styles.rowContainer}>
        {data.map((item, index) => (
            <div key={index} style={styles.cellItem}>
            {typeof item === "string" || typeof item === "number" ? (
                item
            ) : (
                <img src={item.opponent_logo} alt={`${item.opponent_name} Logo`} style={styles.logo} />
            )}
            </div>
        ))}
        </MDBRow>
    </TableCell>
);

const FormRow = ({ title, homeData, awayData }) => (
    <TableRow>
        <FormCell data={homeData} />
        <TableCell style={styles.centerText}>-</TableCell>
        <TableCell style={styles.statTitle}>{title}</TableCell>
        <TableCell style={styles.centerText}>-</TableCell>
        <FormCell data={awayData} />
    </TableRow>
);

const FormCell = ({ data }) => {
    return (
      <TableCell style={styles.centerText}>
        <MDBRow style={styles.rowContainer}>
          {data.map((item, index) => (
            <div key={"form-cell-" + index} style={styles.cellItem}>
              <img src={item.opponent_logo} alt={`${item.opponent_name} Logo`} style={styles.logo} />
              <GoDotFill size={18} className={GetOutcomeClass(item.outcome)} />
            </div>
          ))}
        </MDBRow>
      </TableCell>
    );
  };

  const MatchStatsTable = ({ statpack }) => {
    const { home_team_pack, away_team_pack } = statpack;
  
    return (
      <>
        {/* Header */}
        <MDBRow style={styles.header}>
          <MDBCol size="12" style={styles.headerContent}>
            <img
              src={home_team_pack.team_logo}
              alt={`${home_team_pack.team_name} Logo`}
              style={{ width: 55, marginRight: 15 }}
            />
            <div style={styles.teamNames}>
              <span>{`${home_team_pack.team_name} - ${away_team_pack.team_name}`}</span>
              <span style={styles.fixtureDescription}>{statpack.fixture_description}</span>
            </div>
            <img
              src={away_team_pack.team_logo}
              alt={`${away_team_pack.team_name} Logo`}
              style={{ width: 55, marginLeft: 15 }}
            />
          </MDBCol>
        </MDBRow>
  
        {/* Table */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={styles.centerText}>
                  Last 5 Games <FaArrowRight />
                </TableCell>
                <TableCell style={styles.centerText}>
                  <Tooltip
                    describeChild
                    title="Season Average - Average value for this team over the course of the current competition season."
                  >
                    <span>Season</span>
                  </Tooltip>
                </TableCell>
                <TableCell style={styles.centerText}>Coverage</TableCell>
                <TableCell style={styles.centerText}>
                  <Tooltip
                    describeChild
                    title="Season Average - Average value for this team over the course of the current competition season."
                  >
                    <span>Season</span>
                  </Tooltip>
                </TableCell>
                <TableCell style={styles.centerText}>
                  <FaArrowLeft /> Last 5 Games
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <FormRow
                    title="Recent Form"
                    homeData={home_team_pack.form}
                    awayData={away_team_pack.form}
                />
                <StatRow
                    title="Goals For"
                    homeData={home_team_pack.goals_for}
                    homeAverage={home_team_pack.averages?.goals ?? 0}
                    awayData={away_team_pack.goals_for}
                    awayAverage={away_team_pack.averages?.goals ?? 0}
                />
                <StatRow
                    title="Goals Against"
                    homeData={home_team_pack.goals_against}
                    homeAverage={home_team_pack.averages?.goals_conceded ?? 0}
                    awayData={away_team_pack.goals_against}
                    awayAverage={away_team_pack.averages?.goals_conceded ?? 0}
                />
                <StatRow
                    title="Shots on Target"
                    homeData={home_team_pack.shots_on_target}
                    homeAverage={(home_team_pack.averages?.shots_on_target_pct ?? 0).toFixed(2)}
                    awayData={away_team_pack.shots_on_target}
                    awayAverage={(away_team_pack.averages?.shots_on_target_pct ?? 0).toFixed(2)}
                />
                <StatRow
                    title="SOT Against"
                    homeData={home_team_pack.shots_on_target_against}
                    homeAverage={'-'}
                    awayData={away_team_pack.shots_on_target_against}
                    awayAverage={'-'}
                />
                <StatRow
                    title="Cards For"
                    homeData={home_team_pack.cards_for}
                    homeAverage={'-'}
                    awayData={away_team_pack.cards_for}
                    awayAverage={'-'}
                />
                <StatRow
                    title="Cards Against"
                    homeData={home_team_pack.cards_against}
                    homeAverage={'-'}
                    awayData={away_team_pack.cards_against}
                    awayAverage={'-'}
                />
                <StatRow
                    title="Fouls"
                    homeData={home_team_pack.fouls}
                    homeAverage={home_team_pack.averages?.fouls ?? 0}
                    awayData={away_team_pack.fouls}
                    awayAverage={away_team_pack.averages?.fouls ?? 0}
                />
                <StatRow
                    title="Fouls Won"
                    homeData={home_team_pack.fouls_won}
                    homeAverage={'-'}
                    awayData={away_team_pack.fouls_won}
                    awayAverage={'-'}
                />
                <StatRow
                    title="Corners"
                    homeData={home_team_pack.corners}
                    homeAverage={home_team_pack.averages?.corners ?? 0}
                    awayData={away_team_pack.corners}
                    awayAverage={away_team_pack.averages?.corners ?? 0}
                />
                <StatRow
                    title="Corners Against"
                    homeData={home_team_pack.corners_against}
                    homeAverage={'-'}
                    awayData={away_team_pack.corners_against}
                    awayAverage={'-'}
                />
            </TableBody>
            
          </Table>
        </TableContainer>
  
        {/* Footer */}
        {home_team_pack.averages && away_team_pack.averages && (
          <>
            <Box display="flex" justifyContent="space-around" mt={2}>
                <Box textAlign="center">
                    <Typography variant="body1">BTTS</Typography>
                    <Typography variant="body2">
                        {statpack.home_team_pack.team_name} <strong>{statpack.home_team_pack.averages.both_teams_to_score.toFixed(2)}%</strong>
                    </Typography>
                    <Typography variant="body2">
                        {statpack.away_team_pack.team_name} <strong>{statpack.away_team_pack.averages.both_teams_to_score.toFixed(2)}%</strong>
                    </Typography>
                </Box>
                {["over_1_5", "over_2_5", "over_3_5"].map((key) => (
                <Box key={key} textAlign="center">
                    <Typography variant="body1">{home_team_pack.averages.goal_result_averages[`${key}_label`]}</Typography>
                    <Typography variant="body2">
                    {home_team_pack.team_name}{": "}
                    <strong>{home_team_pack.averages.goal_result_averages[`${key}_average`].toFixed(2)}%</strong>
                    </Typography>
                    <Typography variant="body2">
                    {away_team_pack.team_name}{": "}
                    <strong>{away_team_pack.averages.goal_result_averages[`${key}_average`].toFixed(2)}%</strong>
                    </Typography>
                </Box>
                ))}
            </Box>
          </>
        )}
      </>
    );
  };
  
  const styles = {
    header: { backgroundColor: "white", paddingBottom: "15px" },
    headerContent: {
      padding: "0 40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "26px",
      fontWeight: "500",
    },
    teamNames: { display: "flex", flexDirection: "column", textAlign: "center" },
    fixtureDescription: { fontSize: "12px" },
    centerText: { textAlign: "center" },
    rowContainer: { display: "flex", justifyContent: "space-between", flexWrap: "nowrap" },
    cellItem: { padding: "5px", flex: "1 1 auto", textAlign: "center" },
    logo: { width: 22 },
    statTitle: { textAlign: "center", backgroundColor: "#f1f1f1", fontWeight: "500" },
  };
  
  