import { Button, Paper } from '@mui/material';
import { MDBRow } from 'mdb-react-ui-kit';
import React from 'react';

import redCardImage from '../../images/opps_something_went_wrong.jpg';

export default class ErrorBoundary extends React.Component {

  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {

    this.setState({

      error: error,

      errorInfo: errorInfo

    });

  }

  refresh = () => {

    this.setState({ error: null, errorInfo: null });

  }

  render() {

    if (this.state.error) {

      return (

        <Paper elevation={2}>

          <MDBRow className='widget_container'>

            <div className='widget_heading'>Oops... Something went wrong</div>

            <div className="widget_sub_heading">To clear the error and refresh the widget click the button below</div>

            <div className='widget_controller p-2'>

              <Button variant="outlined" onClick={this.refresh}>Refresh</Button>
            
            </div>

            <div className='widget_content'>

              <img className='center_content' style={{width: '300px', height: '300px'}} src={redCardImage} />

            </div>

          </MDBRow>

          {/* <details style={{ whiteSpace: 'pre-wrap' }}>

            {this.state.error && this.state.error.toString()}

            <br />

            {this.state.errorInfo.componentStack}

          </details> */}

        </Paper>

      );

    }

    return this.props.children;

  }

}