import { Grid, Paper } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useRef, useState } from "react";
import { SetStatusClasses } from "../../../common/helpers/status_helper";
import { useResults } from "../../fixtures/fixtureHook";
import '../css/insights_dashboard.css';
import NoWidgetContent from "../../shared/widget/no_widget_content";
import hour_glass_gif from '../../../images/hour_glass.gif';
import { useIsVisible } from "../../shared/hooks/isVisibleHook";
import { RiAlertFill } from 'react-icons/ri';
import { IsToday, IsTodaySetState } from "../../../common/helpers/date_helper";
import configuration from '../../../config.json';
import { useLiveScoresSubscription } from "../../infrastructure/subscriptions/fixtureSubscriptionHook";

export default function InsightSecondaryDashboard(props) {

    const componentMounted = useRef(true);

    const { currentLeagueID, isMobile, contentSetter, fixture_id, site_date } = props;

    const [resultsResponse, setFixtureResultsState] = useState(null);

    const [isVisible, setIsVisible] = React.useState(false);

    useResults(site_date, currentLeagueID, setFixtureResultsState);

    useIsVisible(isVisible, setIsVisible, componentMounted); 

    useLiveScoresSubscription(site_date, setFixtureResultsState);

    if (resultsResponse === null || 
        
        (resultsResponse !== null && !resultsResponse.successful_request)) {

        return <NoWidgetContent no_content_heading={"Loading Insights"} no_content_sub_heading={"Currently generating insights, please wait..."} image_src={hour_glass_gif} />

    }

    return (

        <React.Fragment>

            <Grid container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={12}>

                    {

                        resultsResponse.data.league_profiles.length < 1 ? 

                        <div className={`dashboard_widget widget_fade_in ${isVisible ? 'fade-in' : ''}`}>

                            <MDBRow>

                                <MDBCol size="12" className="p-5">

                                    <RiAlertFill color='#0D1E33' style={{marginLeft: 'auto', marginRight: 'auto', display: 'block' }} size={100} />

                                </MDBCol>

                                <MDBCol size="12">

                                    <p className="label_no_scheduled_fixtures_heading">No Scheduled Fixtures</p>

                                    <p className="label_no_scheduled_fixtures">Try selecting a future date from the date picker above to view upcoming fixtures.</p>

                                    <p className="label_no_scheduled_fixtures">Alternatively select a date from the past to view past results and statistics.</p>

                                </MDBCol>

                            </MDBRow> 

                        </div>

                        :

                        resultsResponse.data.results.filter(a => { 

                            return currentLeagueID === -1 || currentLeagueID === null ? true : a.league_id === currentLeagueID
    
                        }).map((result, index) => {

                                return (
                                
                                    <Paper onClick={() => contentSetter(isMobile ? 11 : 7, result.fixture_id)} key={"insights_dashboard_" + result.fixture_id} elevation={1} className={`mb-2 insight_fixture_summary widget_fade_in ${isVisible ? 'fade-in' : ''}`}>

                                        <MDBRow className='widget_container'>

                                            <div className='widget_heading'>
                                                
                                                <img className="league_logo" src={result.league_logo} />
                                                
                                                {result.league_name}
                                                
                                            </div>

                                            <div className="widget_sub_heading"></div>

                                            <MDBRow>

                                                <MDBCol>
                                                    
                                                    <img className="team_logo" src={result.home_team_logo} />

                                                </MDBCol>

                                                <MDBCol className="column_insight_fixture_summary_score">
                                                    
                                                    <span className={SetStatusClasses(result.fixture_status) + " fixture_score event_status"}>{result.home_team_score} : {result.away_team_score}</span>

                                                </MDBCol>

                                                <MDBCol>
                                                    
                                                    <img className="team_logo" src={result.away_team_logo} />
                                                
                                                </MDBCol>

                                            </MDBRow>

                                            <MDBRow>

                                                <MDBCol>

                                                    <label className="team_name">{result.home_team_name}</label>

                                                </MDBCol>

                                                <MDBCol>

                                                    <span className={SetStatusClasses(result.fixture_status) + " fixture_state"}>{result.fixture_state}</span>

                                                </MDBCol>

                                                <MDBCol>

                                                    <label className="team_name">{result.away_team_name}</label>

                                                </MDBCol>

                                            </MDBRow>

                                            {/* <MDBRow>

                                                <MDBCol size="4">

                                                    <span className="difficulty_rating">{result.home_team_difficulty_factor == null ? "-" : result.home_team_difficulty_factor.toFixed(0)}</span>

                                                </MDBCol>

                                                <MDBCol className="difficulty_rating_column no_side_padding" style={{height: '80px'}} size="4">

                                                    <RechartHalfPie data={[{ name: 'Group A', value: result.home_team_difficulty_factor },{ name: 'Group B', value: result.away_team_difficulty_factor }]} />

                                                </MDBCol>

                                                <MDBCol size="4">

                                                    <span className="difficulty_rating">{result.away_team_difficulty_factor == null ? "-" : result.away_team_difficulty_factor.toFixed(0)}</span>

                                                </MDBCol>

                                            </MDBRow> */}

                                        </MDBRow>

                                    </Paper>
            
                                ) 
        
                            }
    
                        )

                    }

                </Grid>
                    
            </Grid>

        </React.Fragment>

    )

}