import * as React from 'react';
import Button from '@mui/material/Button';
import { TableBody, Table, TableContainer, TableRow, TableCell } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MDBRow, MDBCol } from "mdbreact";
import StatManImage from '../../../images/stat-man-banner-logo-white.png';
import { GetGradientClass } from '../../../common/helpers/rating_helper.js';

import '../../../css/fixture_player_statistics_modal.css';

function getStatisticValue(value) {

    return value == null || value == undefined ? 0 : value;

}

function CalculateStatisticPercentage(valueOne, valueTwo)
{

    var statisticOne = getStatisticValue(valueOne);

    var statisticTwo = getStatisticValue(valueTwo);

    if(statisticTwo === 0) 
    {

        return 0;

    }

    return (statisticOne / statisticTwo) * 100;

}

export default function StatisticsModal(props) {

  const { modalOpen, handleClose, playerStatistics } = props;

  return (

    <React.Fragment>

      <Dialog

        open={modalOpen}

        onClose={handleClose}
        
        aria-labelledby="alert-dialog-title"

        aria-describedby="alert-dialog-description"

        fullWidth={true}

      >

        <DialogTitle id="alert-dialog-title">

            <img className="image_statman_watermark" src={StatManImage} />

        </DialogTitle>

        <DialogContent className='P-2'>

          <MDBRow className="modal_row_focused_statistics_profile">

            <MDBCol>

                <img className="image_player_profile" src={playerStatistics.player_image} />

                <img className="image_team_logo mobile-hidden" src={playerStatistics.team_logo} />

            </MDBCol>

            <MDBCol>

                <MDBRow style={{height: "50%"}}>
                    
                    <MDBCol><label className="label_player_name">{playerStatistics.player_name}</label></MDBCol>
                    
                </MDBRow>

                <MDBRow>

                    <MDBCol></MDBCol>

                    <MDBCol>

                        <label className={GetGradientClass(playerStatistics.match_rating) + " label_player_rating mobile-hidden"}>{playerStatistics.match_rating == null ? "-" : playerStatistics.match_rating}</label>

                    </MDBCol>

                </MDBRow>

            </MDBCol>

          </MDBRow>

          <MDBRow>

              <MDBCol className="column_modal_statistics" sm="6" md="6" lg="6" xl="6">

                <TableContainer className="statistics_table_container">

                    <Table className="table_lineup_statistics_focus" size="small" aria-label="In Game Statistics">

                        <TableBody>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Minutes Played</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_minutes_played)}'</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Goals</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_goals_scored)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Assists</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_goals_assisted)}</TableCell>

                            </TableRow>

                        </TableBody>
                        
                    </Table>

                </TableContainer>

                <TableContainer className="statistics_table_container">

                    <Table className="table_lineup_statistics_focus" size="small" aria-label="Attacking Statistics">

                        <TableBody>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Shots on Target</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_shots_on_target)} ({playerStatistics.total_shots === 0 ? 0 : (
                                    
                                    CalculateStatisticPercentage(playerStatistics.total_shots_on_target, playerStatistics.total_shots)
                                    
                                ).toFixed(0)}%)</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Shots Off Target</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_shots) - getStatisticValue(playerStatistics.total_shots_on_target)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Successful Dribbles</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_successful_dribbles)}/{getStatisticValue(playerStatistics.total_dribble_attempts)}</TableCell>

                            </TableRow>

                        </TableBody>
                        
                    </Table>

                </TableContainer>

                <TableContainer className="statistics_table_container">

                    <Table className="table_lineup_statistics_focus" size="small" aria-label="Pass Statistics">

                        <TableBody>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Total Passes</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_passes)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Successful Passes</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_accurate_passes)}  ({playerStatistics.pass_accuracy}%)</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Key Passes</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_key_passes)}  ({playerStatistics.total_passes === 0 ? 0 : (
                                    
                                    CalculateStatisticPercentage(playerStatistics.total_key_passes, playerStatistics.total_passes)
                                    
                                ).toFixed(0)}%)</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Total Crosses</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_crosses)}</TableCell>

                            </TableRow>

                        </TableBody>
                        
                    </Table>

                </TableContainer>

              </MDBCol>

              <MDBCol className="column_modal_statistics" sm="6" md="6" lg="6" xl="6">

                <TableContainer className="statistics_table_container">

                    <Table className="table_lineup_statistics_focus" size="small" aria-label="Dicipline Statistics">

                        <TableBody>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Duels Won</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_duels_won)}/{getStatisticValue(playerStatistics.total_duels)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Aerial Duels Won</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_aerial_duels_won)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Times Disposessed</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_times_dispossesed)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Fouls Committed</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_fouls_committed)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Times Fouled</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_fouls_drawn)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Times Offside</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_offsides)}</TableCell>

                            </TableRow>

                        </TableBody>
                        
                    </Table>

                </TableContainer>

                <TableContainer className="statistics_table_container">

                    <Table className="table_lineup_statistics_focus" size="small" aria-label="Defensive Statistics">

                        <TableBody>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Clearances</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_clearances)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Blocked Shots</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_blocks)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Interceptions</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_interceptions)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Tackles</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_tackles)}</TableCell>

                            </TableRow>

                            <TableRow>

                                <TableCell className='cell_player_stat_label'>Dribbled Past</TableCell>

                                <TableCell className='cell_player_stat_value'>{getStatisticValue(playerStatistics.total_dribbled_past)}</TableCell>

                            </TableRow>

                        </TableBody>
                        
                    </Table>

                </TableContainer>

              </MDBCol>

          </MDBRow>

        </DialogContent>

        <DialogActions>

          <Button onClick={handleClose} autoFocus>

            Close

          </Button>

        </DialogActions>

      </Dialog>

    </React.Fragment>

  );
}