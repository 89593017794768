import React, { useState, useEffect } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import configuration from '../../../config.json';

import '../css/league_dashboard.css';

export default function LeagueDashboard(props) {

    const { league_id, contentSetter, isMobile } = props;

    const [league_dashboard_state, setLeagueDashboardState] = useState(
        
        {

            league: {},

            isLoaded: false,

            error: {},

            bounceRequest: false

        }
    
    );

    function fetchData() {

        if(!league_dashboard_state.bounceRequest && ((league_id !== null && league_id !== undefined) && (league_dashboard_state.league === undefined || league_dashboard_state.league === null || league_id !== league_dashboard_state.league.league_id))) {

            setLeagueDashboardState(prevState => (
                
                    {

                        ...prevState,

                        bounceRequest: true

                    }
                
                )
            
            );

            fetch(`${configuration.MASTER_API}League/GetLeagueByLeagueID?league_id=${league_id}`)

            .then(res => res.json())

            .then(

                (league) => {

                    setLeagueDashboardState(prevState => (
                
                            {
        
                                ...prevState,
        
                                league: league,

                                isLoaded: true,

                                bounceRequest: false
        
                            }
                        
                        )
                    
                    );

                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                    setLeagueDashboardState(prevState => (
                
                            {
        
                                ...prevState,
        
                                error: error,

                                bounceRequest: false
        
                            }
                        
                        )
                    
                    );

                }

            )

        }

    }

    useEffect(() => {
        
        fetchData();

    });

    if(!league_dashboard_state.isLoaded) {

        return (
        
            <Box className="loading_spinner" sx={{ display: 'flex' }}>

                <CircularProgress />
                
            </Box>
            
        );

    }

    return (
    
        <React.Fragment>

            <MDBRow className="heading_column">
                        
                <MDBCol size="1" className="heading_column_image">

                    <img alt="League logo" src={league_dashboard_state.league.league_logo} />

                </MDBCol>

                <MDBCol className="heading_column_title">

                    <h2>{league_dashboard_state.league.league_name}</h2>

                </MDBCol>

            </MDBRow>

            <MDBRow className="row_league_teams row-cols-2 row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-2 g-2">

                {

                    league_dashboard_state.league.current_Teams.map((team, index) => {

                        return (

                            <MDBCol>

                                <MDBCol onClick={() => contentSetter(isMobile ? 6 : 1, team.team_ID)} className='column_league_team'>

                                    <MDBRow>

                                        <MDBCol className="column_team_logo" sm="12" md="4" lg="4" xl="4">

                                            <img src={team.logo_Path} alt={team.name + " logo"} />
                                            
                                        </MDBCol>

                                        <MDBCol className="column_team_name" sm="12" md="8" lg="8" xl="8">
                                        
                                            <span>{team.name}</span>

                                        </MDBCol>

                                    </MDBRow>

                                </MDBCol>

                            </MDBCol>

                        )

                    })

                }

            </MDBRow>

        </React.Fragment>

    );

}