import React, { useRef, useState } from 'react';
import { useTeams } from "./teamHook";
import { MDBRow, MDBCol } from 'mdbreact';
import Autocomplete from '../shared/widget/autocomplete_textbox.js';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MdManageSearch } from "react-icons/md";
import Paper from '@mui/material/Paper';
import hour_glass_gif from '../../images/hour_glass.gif';
import '../dashboards/css/teams_dashboard.css';
import { useIsVisible } from '../shared/hooks/isVisibleHook';
import NoWidgetContent from '../shared/widget/no_widget_content';

export default function TeamsSearchWidget(props) {

    const componentMounted = useRef(true);

    const { contentSetter, currentLeagueID, isMobile } = props;

    const [teamSearchWidgetState, setTeamSearchWidgetState] = useState({ isLoading: true, data: null, searchTerm: "", page: 1, currentLeagueID: currentLeagueID, totalPages: 1 });

    useTeams(currentLeagueID, teamSearchWidgetState.searchTerm, setTeamSearchWidgetState, teamSearchWidgetState.page);

    const [isVisible, setIsVisible] = React.useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted); 

    const handleChange = (event, value) => {

        setTeamSearchWidgetState(prevFilterState => {

            return { 
                
                ...prevFilterState,

                page: value

            }

        })

    };

    const inputChange = (event, value) => {

        setTeamSearchWidgetState(prevFilterState => {

            return { 
                
                ...prevFilterState,

                page: 1,

                searchTerm: value

            }

        })

    };

    if(teamSearchWidgetState == null || teamSearchWidgetState.isLoading)
    {
        return  (<NoWidgetContent no_content_heading={"Loading Teams"} no_content_sub_heading={"Currently fethcing teams, please wait..."} image_src={hour_glass_gif} />)
    }

    return (<Paper className={`dashboard_widget widget_fade_in ${isVisible ? 'fade-in' : ''}`} elevation={2}>

            <MDBRow className='widget_container'>

                <MDBRow className="teams_dashboard">

                    <MDBCol className='column_team_selection_search' xs="12" sm="12" md="12" lg="12" xl="12">

                        <Autocomplete currentLeagueID={teamSearchWidgetState.currentLeagueID} setSearchTerm={inputChange} onClick={contentSetter} placeholder="Search Teams..." />

                    </MDBCol>

                    <MDBCol className='teams_dashboard_pagnation column_no_side_padding' xs="12" sm="12" md="12" lg="12" xl="12">

                        <Stack spacing={2}>

                            <Pagination 
                            
                                count={teamSearchWidgetState.totalPages}
                            
                                color="primary" 
                            
                                onChange={handleChange} 
                            
                                page={teamSearchWidgetState.page} />

                        </Stack>

                    </MDBCol>

                    {

                        (

                            teamSearchWidgetState.data === null || teamSearchWidgetState.data.length === 0 ?
        
                            <MDBRow>

                                <MDBCol size="12" className="p-3">

                                    <MdManageSearch color='#0D1E33' style={{marginLeft: 'auto', marginRight: 'auto', display: 'block' }} size={150} />

                                </MDBCol>

                                <MDBCol size="12">

                                    <p className="label_no_results_heading">No Teams</p>

                                    <p className="label_no_results_sub_heading">Try altering your filters and trying again.</p>

                                </MDBCol>

                            </MDBRow> 

                            :

                            <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="column_team_selection pt-2">

                                <ul className="list_team_selection">

                                    {

                                        teamSearchWidgetState.data.map((team, index) =>

                                            <li key={"team_search_widget_" + team.id} className="clickable list_item_team_selection padded_8_14" onClick={() => contentSetter(isMobile ? 6 : 1, team.id)}>

                                                <div>
        
                                                    <div>

                                                        <img className="image_team_logo" src={team.image_path} alt={team.name + " badge"} />

                                                    </div>

                                                    <div className="team_details">

                                                        <span className='team_name'>{team.name}</span>

                                                        <div>

                                                            {
                                                                
                                                                team.country_image_path === "" ? "" : <img className="image_country_flag" src={team.country_image_path} alt={team.country_name + " flag"} />
                                                                
                                                            }

                                                            {
                                                                
                                                                team.league_name == null ? "" :

                                                                <span className="league_name">{team.league_name}</span>

                                                            }

                                                        </div>

                                                    </div>

                                                    <div>

                                                        {
                                                                
                                                            team.league_image_path == null ? "" :

                                                            <img className="image_league_logo" src={team.league_image_path}  alt={team.league_name + " league logo"} />

                                                        }

                                                    </div>

                                                </div>

                                            </li>

                                        )

                                    }

                                </ul>

                            </MDBCol>

                        )

                    }

                </MDBRow>

            </MDBRow>

        </Paper>

    )

}