import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Link } from 'react-router-dom';

const options = [
  { label: 'View Stat Pack', icon: AssessmentOutlinedIcon, dashboardId: 8 }
];

const ITEM_HEIGHT = 48;

export default function MobileFixtureMenu({ fixture_id, fixture, contentSetter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (dashboardId) => {
    contentSetter(dashboardId, fixture_id);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <MoreHorizIcon fontSize="medium" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: '20ch',
            borderRadius: '10px'
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleMenuItemClick(option.dashboardId)}
          >
            <Link className="no_side_padding" to={`/fixture/${fixture_id}/${fixture.name}/stat-pack`}>
                <option.icon style={{ marginRight: '10px' }} />
                {option.label}
            </Link> 
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
