import { useState, useEffect } from 'react';
import configuration from '../../config.json';
import { MakeAxiosRequest } from '../infrastructure/http_client.js';
import axios from 'axios';

export const useTeam = (team_id, setter) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, team_id: team_id };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}teams/${team_id}`);
        
        if (response.status === 200) {
          result.data = response.data;
        }

        setter(result);
      } 
      catch (error) {
        setter({ isLoading: false, data: null, team_id: team_id });
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [team_id, setter]);
};

export const useTeams = (currentLeagueID, searchTerm, setter, page = 1) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, currentLeagueID, searchTerm: searchTerm, page, totalPages: 1 };

        const data = { LeagueID: currentLeagueID, SearchTerm: searchTerm, Page: page };

        const response = await axios.post(`${configuration.FRONT_END_API_v2}teams`, data);

        if (response.status === 200) {
          result.data = response.data.data;
          result.page = response.data.page;
          result.totalPages = response.data.totalPages;
        }

        setter(result);
      } 
      catch (error) {
        setter({ isLoading: false, data: [], searchTerm, page, currentLeagueID });
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [page, searchTerm, currentLeagueID, setter]);
};


export const useTeamStatistics = (setter, team_id, season_id) => {
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        var result = { isLoading: false, data: null, team_id: team_id, season_id: season_id };

        const response = await axios.get(`${configuration.FRONT_END_API_v2}teams/${team_id}/statistics/${season_id}`);
        
        if (response.status === 200) {
          result.data = response.data;
        }

        setter(result);
      } 
      catch (error) {
        setter({ isLoading: false, data: null, team_id: team_id, season_id: season_id });
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [setter, team_id, season_id]);
};

export const useVenue = (venue_id) => {

    const [venueState, setVenueState] = useState(null);

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            try 
            {

                const data = await MakeAxiosRequest(`${configuration.FRONT_END_API_v2}venues/${venue_id}`);
                
                if (isMounted) {

                    setVenueState(data);

                }

            } 
            catch (error) {

                // Handle error if needed
                console.error('Error fetching venue data:', error);

            }

        };

        fetchData();

        return () => {

            isMounted = false; // Set isMounted to false when component unmounts

        };

    }, [venue_id]);

    return venueState;

};

export const useSquad = (team_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          var result = { isLoading: false, data: null, team_id: team_id };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}squads/${team_id}`);
          
          if (response.status === 200) {
            result.data = response.data;
          }

          setter(result);
        } 
        catch (error) {
          setter({ isLoading: false, data: null, team_id: team_id });
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [team_id, setter]);
  };