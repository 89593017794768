import { BsDot } from "react-icons/bs";
import MatchTimer from "../../application/shared/utils/MatchTimer";
import CountdownTimer from "../../application/shared/utils/CountdownTimer";

export function GetFixtureState(fixture_state, time, fullName, date) {
    switch(fixture_state.state) {
        case "NS":
            {
                return <CountdownTimer targetTime={time} targetDate={date} />;
            }
        case "INPLAY_PENALTIES":
            {
                return fullName ? "Penalties" : "PEN"
            }
        case "FT_PEN":
            {
                return fullName ? "After Penalties" : "FT PEN"
            }
        case "ET":
        case "INPLAY_1ST_HALF":
        case "INPLAY_2ND_HALF":
        case "INPLAY_ET":
        case "INPLAY_ET_2ND_HALF":
            {
                return <MatchTimer initialMinute={fixture_state?.minutes ?? 0} initialSeconds={fixture_state?.seconds ?? 0} />
            }
        case "BREAK":
            {
                return "BREAK";
            }
        case "POSTPONED":
            {
                return fullName ? "Postponed" : "POST";
            }
        case "AET":
        case "FT":
        case "HT":
            {
                if(fullName) 
                {
                    switch(fixture_state.state) 
                    {
                        default:
                            {
                                return "";
                            }
                        case "FT":
                            {
                                return "Full Time";
                            }
                        case "HT":
                            {
                                return "Half Time";
                            }
                        case "ET":
                            {
                                return "Extra Time";
                            }
                        case "AET":
                            {
                                return "After Extra Time";
                            }
                    }
                }   
                else 
                {
                    return fixture_state.state;
                }
            }
            default:
            {
                return "";
            }
    }
}

export function SetStatusClasses(status, applyPulse) {
        
    let status_classes = {};

    switch(status)
    {
        case "POSTPONED":
            {
                return 'status_postponed';
            }
        case "INPLAY_1ST_HALF":
        case "INPLAY_2ND_HALF":
        case "INPLAY_ET":
        case "INPLAY_PENALTIES":
        case "INPLAY_ET_2ND_HALF":
            {
                return applyPulse ? 'status_live live-pulse' : 'status_live';
            }
        case "HT":
            {
                return 'status_live';
            }
        case "ET":
            {
                return 'status_live';
            }
        case "NS":
            {
                return '';
            }
        case "FT":
            {
                return '';
            }
        case "AET":
            {
                return '';
            }
        default:
            {
                return "";
            }
    }
}

export function GetStatusDisplay(status) {
    switch(status.state)
    {
        case "POSTPONED":
            {
                return <></>;
            }
        case "INPLAY_1ST_HALF":
        case "INPLAY_2ND_HALF":
            {
                return <span><BsDot /> LIVE</span>;
            }
        case "INPLAY_ET":
        case "INPLAY_PENALTIES":
        case "INPLAY_ET_2ND_HALF":
            
        case "HT":
        case "ET":
            {
                return <span><BsDot /> {status.state}</span>;
            }
        case "NS":
            {
                return <></>;
            }
        case "FT":
            {
                return <span style={{padding: '5px 10px 5px 10px',
                    backgroundColor: '#f3f3f3',
                    borderRadius: '2px' }}>FT</span>
            }
        case "AET":
            {
                return <span>AET</span>;
            }
        default:
            {
                return <></>;
            }
    }
}

export function GetStatusMinutes(status) {
    var fixture_state = status.state;
    switch(status.state)
    {
        case "INPLAY_1ST_HALF":
        case "INPLAY_2ND_HALF":
        case "INPLAY_ET":
        case "INPLAY_ET_2ND_HALF":
            {
                return <span>{fixture_state.minutes}</span>
            }
        default:
            {
                return <></>
            }
    }
}

