import { combineReducers } from'redux';
import 
{ 
    leagueSelectionReducer  
} from '../store/application-state';

import { SetSiteDateReducer, SetFixtureNameReducer, MobileDashboardSwitchReducer, PrimaryDashboardSwitchReducer, SecondaryDashboardSwitchReducer, ContentDashboardSwitchReducer } from '../application/dashboards/dashboard_system/dashboard_navigation_system';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.

const allReducers = combineReducers (
    
    {

        site_date: SetSiteDateReducer,

        primary_dashboard_state: PrimaryDashboardSwitchReducer,

        content_dashboard_state: ContentDashboardSwitchReducer,
        
        secondary_dashboard_state: SecondaryDashboardSwitchReducer,

        mobile_dashboard_state: MobileDashboardSwitchReducer,

        leagueSelectionState: leagueSelectionReducer,

    }

);

export default allReducers;