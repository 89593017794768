import { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { AiOutlineSwap } from "react-icons/ai";
import { 
    Paper, 
    Table, 
    Button,
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    ToggleButton, 
    ToggleButtonGroup, 
    Alert
} from "@mui/material";
import { GetGradientClass } from "../../../common/helpers/rating_helper";
import FixturePitch from "./fixture_pitch";
import ErrorBoundary from "../../error/error_boundary";
import { BiSolidChevronDownCircle } from "react-icons/bi";

const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

const PlayerList = ({ players, placeholderImage }) => (
    
    <ul className="underlying_list_team_lineup">
        {players
            .sort((a, b) => a.formation_position - b.formation_position)
            .map((player) => (
                <li className="list_item_lineup_player" key={player.id}>
                    <img
                        src={player.player_image || placeholderImage}
                        alt={`${player.player_name} profile`}
                        onError={(e) => (e.target.src = placeholderImage)}
                        className="player-image"
                    />
                    <label className="label_player_number">{player.jersey_number}</label>
                    <div className="player-info">
                        <label className="label_player_name">{player.player_name}</label>
                        {player.rating && player.rating > 0 && player.rating != 0 && (
                            <span
                                className={`${GetGradientClass(player.rating)} span_player_fixture_rating`}
                            >
                                {player.rating?.toFixed(1) || "-"}
                            </span>
                        )}
                    </div>
                </li>
            ))}
    </ul>
);

const LineupSection = ({ lineupData, placeholderImage }) => {
    const substitutes = lineupData.filter(player => player.type_name === "Bench");
    return (
        <MDBRow>
            <MDBCol className="column_team_lineup no_side_padding" size="12">
                <PlayerList 
                    players={lineupData.filter(player => player.type_name === "Lineup")} 
                    placeholderImage={placeholderImage} 
                />
            </MDBCol>
            {substitutes.length > 0 && (
                <>
                    <div className="substitute_divider" style={{ display: "flex", gap: "12px" }}>
                        <AiOutlineSwap size={25} />
                        <span>Substitutes</span>
                    </div>
                    <MDBCol className="column_team_lineup no_side_padding" size="12">
                        <PlayerList 
                            players={substitutes.sort((a, b) => a.jersey_number - b.jersey_number)} 
                            placeholderImage={placeholderImage} 
                        />
                    </MDBCol>
                </>
            )}
        </MDBRow>
    );
};

const PlayerStatisticsTable = ({ players, statisticTableState, teams, setStatisticTableState }) => {
    const handleOrderByChange = (column) => {
        setStatisticTableState((prevState) => ({
            ...prevState,
            orderBy: column,
        }));
    };

    const handleTeamChange = (event, id) => {
        if(id !== null) {
            setStatisticTableState((prevState) => ({
                ...prevState,
                selectedTeam: id,
            }));
        }      
    };

    const renderHeader = () => {
        const headers = getHeaders(statisticTableState.selectedStatistic);
        return headers.map(({ label, column }) => (
            <TableCell
                key={column}
                onClick={() => handleOrderByChange(column)}
                style={{ textAlign: "center", fontSize: "12px", padding: "8px" }}
            >
                {label} {statisticTableState.orderBy === column && <BiSolidChevronDownCircle size={14} />}
            </TableCell>
        ));
    };

    var filteredPlayers = 
    statisticTableState.selectedTeam === -2 ? 
        players : 
        statisticTableState.selectedTeam === teams.home_team.id ? 
            players.filter((player, index) => { return player.team_id === teams.home_team.id }) : 
            players.filter((player, index) => { return player.team_id === teams.away_team.id });

    filteredPlayers = filteredPlayers.filter((player, index) => { return statisticTableState.selectedStatistic === 6 ? player.position_name === "Goalkeeper" : true});

    const sortedPlayers = OrderPlayers(filteredPlayers, statisticTableState);

    return (
        <TableContainer className="no_side_padding" style={{ fontSize: "12px", overflowX: 'auto', width: '100%', boxSizing: 'border-box' }}>
            <Table className="table-lineup-statistics" sx={{borderCollapse: 'collapse'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                value={statisticTableState.selectedTeam}
                                onChange={(event, id) => {handleTeamChange(event, id)}}
                                aria-label="Team Filter"
                                style={{gap: '3px'}}
                            >
                                {renderTeamButtons(teams, statisticTableState, setStatisticTableState)}
                            </ToggleButtonGroup>
                        </TableCell>
                        {renderHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedPlayers.map((player, index) => (
                        <PlayerStatisticsRow
                            key={`statistics_player_row_${player.id}_${index}`}
                            player={player}
                            statisticTableState={statisticTableState}
                            teams={teams}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

var playerStatisticStyles = {
    player_name: {
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100px',
        maxWidth: '90px',
        overflow: 'hidden'
    },
    information_label: {
        padding: '6px 14px',
        fontSize: '13px',
        borderRadius: '10px'
    },
    attacker_style: {
        backgroundColor: '#e12764',
        color: 'white',
        borderRadius: '6px',
        textAlign: 'center',
        fontSize: '10px',
        width: '75px',
        marginTop: '3px'
    },
    midfielder_style: {
        backgroundColor: '#3971eb',
        color: 'white',
        borderRadius: '6px',
        textAlign: 'center',
        fontSize: '10px',
        width: '75px',
        marginTop: '3px'
    },
    defender_style: {
        backgroundColor: '#56c766',
        color: 'white',
        borderRadius: '6px',
        textAlign: 'center',
        fontSize: '10px',
        width: '75px',
        marginTop: '3px'
    },
    goalkeeper_style: {
        backgroundColor: '#e9a538',
        color: 'white',
        borderRadius: '6px',
        textAlign: 'center',
        fontSize: '10px',
        width: '75px',
        marginTop: '3px'
    },
}

const PlayerStatisticsRow = ({ player, statisticTableState, teams }) => {
    const getTeamLogo = (teamId) => {
        return teamId === teams.home_team.id ? teams.home_team.logo : teams.away_team.logo;
    };

    const renderCell = (statValue) => {
        return <TableCell style={{ textAlign: "center", fontSize: "14px", padding: "8px" }}>{statValue ?? 0}</TableCell>;
    };

    const GetPositionalStyle = (position) => {
        switch(position){
            case "Goalkeeper":
                return playerStatisticStyles.goalkeeper_style;
            case "Defender":
                return playerStatisticStyles.defender_style;
            case "Midfielder":
                return playerStatisticStyles.midfielder_style;
            case "Attacker":
                return playerStatisticStyles.attacker_style;
        }
    };

    return (
        <TableRow>
            {/* Player Info */}
            <TableCell style={{ fontWeight: "400", fontSize: "14px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "15px", position: "relative" }}>
                    <img
                        src={player.player_image || placeholderImage}
                        alt={`${player.player_name} image`}
                        style={{
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                            backgroundColor: "#b6b6b6",
                        }}
                    />
                    <img
                        src={getTeamLogo(player.team_id)}
                        alt={`${player.team_id === teams.home_team.id ? teams.home_team.name : teams.away_team.name} logo`}
                        style={{ width: 13, height: 13, position: "absolute", bottom: "0px", left: "20px" }}
                    />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={playerStatisticStyles.player_name}>{player.player_name}</span>
                        <span style={GetPositionalStyle(player.position_name)}>{player.position_name}</span>
                    </div>
                </div>
            </TableCell>

            {/* Render Statistic Columns Based on Selected Statistic */}
            {statisticTableState.selectedStatistic === 1 && (
                <>
                    <TableCell style={{ textAlign: "center", fontSize: "13px" }}>
                        {player.rating === null || player.rating === 0 ? (
                            "-"
                        ) : (
                            <span className={`span_player_fixture_rating ${GetGradientClass(player.rating)}`}>
                                {player.rating}
                            </span>
                        )}
                    </TableCell>
                    {renderCell(player?.statistics_wrapper?.overall_statistics?.minutes_played)}
                    {renderCell(player?.total_goals)}
                    {renderCell(player?.total_assists)}
                    {renderCell(
                        `${player?.statistics_wrapper?.shot_statistics?.shots_on_target ?? 0}/${
                            player?.statistics_wrapper?.shot_statistics?.total_shots ?? 0
                        }`
                    )}
                    {renderCell(player?.statistics_wrapper?.pass_statistics?.accurate_passes_percentage)}
                </>
            )}

            {statisticTableState.selectedStatistic === 2 && (
                <>
                    {renderCell(player?.total_goals)}
                    {renderCell(
                        `${player?.statistics_wrapper?.shot_statistics?.shots_on_target ?? 0}/${
                            player?.statistics_wrapper?.shot_statistics?.total_shots ?? 0
                        }`
                    )}
                    {renderCell(player?.statistics_wrapper?.shot_statistics?.shots_inside_box)}
                    {renderCell(player?.statistics_wrapper?.shot_statistics?.shots_outside_box)}
                    {renderCell(player?.statistics_wrapper?.shot_statistics?.shots_blocked)}
                </>
            )}

            {statisticTableState.selectedStatistic === 3 && (
                <>
                    {renderCell(player?.statistics_wrapper?.pass_statistics?.touches)}
                    {renderCell(player?.total_assists)}
                    {renderCell(player?.statistics_wrapper?.pass_statistics?.long_balls)}
                    {renderCell(
                        `${player?.statistics_wrapper?.pass_statistics?.accurate_passes ?? 0}/${
                            player?.statistics_wrapper?.pass_statistics?.total_passes ?? 0
                        } (${
                            player?.statistics_wrapper?.pass_statistics?.accurate_passes_percentage ?? 0
                        }%)`
                    )}
                    {renderCell(player?.statistics_wrapper?.pass_statistics?.key_passes)}
                    {renderCell(
                        `${player?.statistics_wrapper?.pass_statistics?.accurate_crosses ?? 0}/${
                            player?.statistics_wrapper?.pass_statistics?.total_crosses ?? 0
                        }`
                    )}
                </>
            )}

            {statisticTableState.selectedStatistic === 4 && (
                <>
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.total_tackles)}
                    {renderCell(
                        `${player?.statistics_wrapper?.duel_statistics?.duels_won ?? 0}/${
                            player?.statistics_wrapper?.duel_statistics?.total_duels ?? 0
                        }`
                    )}
                    {renderCell(player?.statistics_wrapper?.duel_statistics?.aerial_duels_won)}
                    {renderCell(player?.statistics_wrapper?.duel_statistics?.fouls)}
                    {renderCell(player?.statistics_wrapper?.duel_statistics?.fouls_drawn)}
                    {renderCell(player?.statistics_wrapper?.duel_statistics?.dispossessed)}
                    {renderCell(
                        `${player?.statistics_wrapper?.duel_statistics?.successful_dribbles ?? 0}/${
                            player?.statistics_wrapper?.duel_statistics?.dribble_attempts ?? 0
                        }`
                    )}
                </>
            )}

            {statisticTableState.selectedStatistic === 5 && (
                <>
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.errors_leading_to_goals)}
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.shots_blocked)}
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.interceptions)}
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.clearances)}
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.clearances_off_line)}
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.dribbled_past)}
                    {renderCell(player?.statistics_wrapper?.defensive_statistics?.long_balls_won)}
                </>
            )}

            {statisticTableState.selectedStatistic === 6 && (
                <>
                    {renderCell(player?.statistics_wrapper?.goalkeeping_statistics?.saves)}
                    {renderCell(player?.statistics_wrapper?.goalkeeping_statistics?.come_outs)}
                    {renderCell(player?.statistics_wrapper?.goalkeeping_statistics?.punches)}
                    {renderCell(player?.statistics_wrapper?.goalkeeping_statistics?.saves_inside_box)}
                    {renderCell(player?.statistics_wrapper?.goalkeeping_statistics?.goalkeeper_goals_conceded)}
                    {renderCell(player?.statistics_wrapper?.goalkeeping_statistics?.penalties_saved)}
                </>
            )}
        </TableRow>
    );
};

const FixtureLineups = ({
    fixture_id,
    fixtureLineups,
    teams
}) => {
    const [tabToShow, setTabToShow] = useState(1);
    const [statisticTableState, setStatisticTableState] = useState({ selectedStatistic: 1, orderBy: "rating", selectedTeam: -2 });

    if (fixtureLineups.isLoading || !fixtureLineups.data) {
        return null;
    }

    return (
        <ErrorBoundary>
            {fixtureLineups.data.home_team_lineup.length > 0 &&
            fixtureLineups.data.away_team_lineup.length > 0 ? (
                <Paper elevation={1} className="mb-2 br-15 widget_fade_in" sx={{display: 'grid' }}>
                    <HeaderTabs tabToShow={tabToShow} setTabToShow={setTabToShow} statisticTableState={statisticTableState} setStatisticTableState={setStatisticTableState} />
                    {tabToShow === 1 && (
                        <>
                            <FixturePitch
                                home_team_lineup={fixtureLineups.data.home_team_lineup.filter(a => a.type_name === 'Lineup')}
                                away_team_lineup={fixtureLineups.data.away_team_lineup.filter(a => a.type_name === 'Lineup')}
                                home_team={{
                                    rating: fixtureLineups.data.average_home_team_rating,
                                    logo: teams.home_team.logo,
                                    name: teams.home_team.name,
                                    formation: fixtureLineups.data.home_team_formation
                                }}
                                away_team={{
                                    rating: fixtureLineups.data.average_away_team_rating,
                                    logo: teams.away_team.logo,
                                    name: teams.away_team.name,
                                    formation: fixtureLineups.data.away_team_formation
                                }}
                            />
                            <MDBCol className='column_fixture_lineup no_side_padding' size='12'>
                                <MDBRow>
                                    <MDBCol className="no_side_padding" size="6">
                                        <LineupSection 
                                            lineupData={fixtureLineups.data.home_team_lineup}
                                            placeholderImage={placeholderImage} 
                                        />
                                    </MDBCol>
                                    <MDBCol className="no_side_padding" size="6">
                                        <LineupSection 
                                            lineupData={fixtureLineups.data.away_team_lineup}
                                            placeholderImage={placeholderImage} 
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </>
                    )}
                    {tabToShow === 2 && (
                        <PlayerStatisticsTable
                            players={[
                                ...fixtureLineups.data.home_team_lineup,
                                ...fixtureLineups.data.away_team_lineup,
                            ]}
                            statisticTableState={statisticTableState}
                            setStatisticTableState={setStatisticTableState}
                            teams={teams}
                        />
                    )}
                </Paper>
            ) : null}
        </ErrorBoundary>
    );
};

const HeaderTabs = ({ tabToShow, setTabToShow, statisticTableState, setStatisticTableState }) => {
    const tabStyles = (isActive) => ({
        fontSize: "12px",
        borderRadius: "15px",
        marginRight: "5px",
        border: "1px solid #2f4670",
        color: isActive ? "white" : "#2f4670",
        backgroundColor: isActive ? "#2f4670" : "white",
        cursor: "pointer",
    });

    return (
        <>
        <MDBRow className="row_lineup_tab no_side_padding">
            <MDBCol
                size={tabToShow === 2 ? "4" : "12"}
                style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "10px 10px",
                    borderBottom: "1px solid #dedede",
                }}
            >
                <Button
                    style={tabStyles(tabToShow === 1)}
                    onClick={() => setTabToShow(1)}
                    size="small"
                >
                    Lineups
                </Button>
                <Button
                    style={tabStyles(tabToShow === 2)}
                    onClick={() => setTabToShow(2)}
                    size="small"
                >
                    Player Statistics
                </Button>
            </MDBCol>
            {tabToShow === 2 && <MDBCol size='8' style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', padding: '10px 10px', borderBottom: '1px solid #dedede' }}>
                <Button style={{fontSize: '12px', borderRadius: '15px', marginRight: '5px', border: '1px solid #2f4670', color: statisticTableState.selectedStatistic === 1 ? 'white' : '#2f4670', backgroundColor: statisticTableState.selectedStatistic === 1 ? '#2f4670' : 'white' }} onClick={() => { setStatisticTableState((prevState) => ({...prevState, selectedStatistic: 1, orderBy: "rating" })) }} size="small" variant='outlined'>Top Stats</Button>
                <Button style={{fontSize: '12px', borderRadius: '15px', marginRight: '5px', border: '1px solid #2f4670', color: statisticTableState.selectedStatistic === 2 ? 'white' : '#2f4670', backgroundColor: statisticTableState.selectedStatistic === 2 ? '#2f4670' : 'white' }} onClick={() => { setStatisticTableState((prevState) => ({...prevState, selectedStatistic: 2, orderBy: "goals" })) }} size="small" variant='outlined'>Attack</Button>
                <Button style={{fontSize: '12px', borderRadius: '15px', marginRight: '5px', border: '1px solid #2f4670', color: statisticTableState.selectedStatistic === 3 ? 'white' : '#2f4670', backgroundColor: statisticTableState.selectedStatistic === 3 ? '#2f4670' : 'white' }} onClick={() => { setStatisticTableState((prevState) => ({...prevState, selectedStatistic: 3, orderBy: "assists" })) }} size="small" variant='outlined'>Passing</Button>
                <Button style={{fontSize: '12px', borderRadius: '15px', marginRight: '5px', border: '1px solid #2f4670', color: statisticTableState.selectedStatistic === 4 ? 'white' : '#2f4670', backgroundColor: statisticTableState.selectedStatistic === 4 ? '#2f4670' : 'white' }} onClick={() => { setStatisticTableState((prevState) => ({...prevState, selectedStatistic: 4, orderBy: "tackles" })) }} size="small" variant='outlined'>Duels</Button>
                <Button style={{fontSize: '12px', borderRadius: '15px', marginRight: '5px', border: '1px solid #2f4670', color: statisticTableState.selectedStatistic === 5 ? 'white' : '#2f4670', backgroundColor: statisticTableState.selectedStatistic === 5 ? '#2f4670' : 'white' }} onClick={() => { setStatisticTableState((prevState) => ({...prevState, selectedStatistic: 5, orderBy: "shots_blocked" })) }} size="small" variant='outlined'>Defensive</Button>
                <Button style={{fontSize: '12px', borderRadius: '15px', marginRight: '5px', border: '1px solid #2f4670', color: statisticTableState.selectedStatistic === 6 ? 'white' : '#2f4670', backgroundColor: statisticTableState.selectedStatistic === 6 ? '#2f4670' : 'white' }} onClick={() => { setStatisticTableState((prevState) => ({...prevState, selectedStatistic: 6, orderBy: "saves" })) }} size="small" variant='outlined'>Goalkeeping</Button>
            </MDBCol>}
        </MDBRow>
        </>
    );
};

const renderTeamButtons = (teams) => (
    <>
        {/* Home Team Button */}
        <ToggleButton value={teams.home_team.id} sx={{padding: '5px' }} title={`${teams.home_team.name} statistics toggle`}>
            <img
                src={teams.home_team.logo}
                alt={`${teams.home_team.name} logo`}
                style={{ width: 20, height: 20, verticalAlign: "middle" }}
            />
        </ToggleButton>

        {/* Away Team Button */}
        <ToggleButton value={teams.away_team.id} sx={{padding:'5px'}} title={`${teams.away_team.name} statistics toggle`}>
            <img
                src={teams.away_team.logo}
                alt={`${teams.away_team.name} logo`}
                style={{ width: 20, height: 20, verticalAlign: "middle" }}
            />
        </ToggleButton>

        {/* Both Teams Button */}
        <ToggleButton value={-2} sx={{padding:'5px'}} title="Both teams statistics toggle">
            <div style={{ display: "flex", gap: 8 }}>
                <img
                    src={teams.home_team.logo}
                    alt={`${teams.home_team.name} logo`}
                    style={{ width: 20, height: 20, verticalAlign: "middle" }}
                />
                <img
                    src={teams.away_team.logo}
                    alt={`${teams.away_team.name} logo`}
                    style={{ width: 20, height: 20, verticalAlign: "middle" }}
                />
            </div>
        </ToggleButton>
    </>
);

const getHeaders = (selectedStatistic) => {
    const headersByStatistic = {
        1: [
            { label: "Rating", column: "rating" },
            { label: "Minutes Played", column: "minutes" },
            { label: "Goals", column: "goals" },
            { label: "Assists", column: "assists" },
            { label: "SOT", column: "sot" },
            { label: "Pass Acc. (%)", column: "pass_acc" },
        ],
        2: [
            { label: "Goals", column: "goals" },
            { label: "Shots On Target", column: "sot" },
            { label: "Shots Inside Box", column: "sot_inside_box" },
            { label: "Shots Outside Box", column: "sot_outside_box" },
            { label: "Blocked Shots", column: "blocked_shots" },
        ],
        3: [
            { label: "Touches", column: "touches" },
            { label: "Assists", column: "assists" },
            { label: "Long Balls", column: "long_balls" },
            { label: "Accurate Passes", column: "pass_acc" },
            { label: "Key Passes", column: "key_passes" },
            { label: "Accurate Crosses", column: "acc_crosses" },
        ],
        4: [
            { label: "Tackles", column: "tackles" },
            { label: "Duels Won", column: "duels_won" },
            { label: "Aerial Duels Won", column: "aerial_won" },
            { label: "Fouls Committed", column: "fouls" },
            { label: "Was Fouled", column: "fouls_drawn" },
            { label: "Dispossessed", column: "dispossessed" },
            { label: "Successful Dribbles", column: "successful_dribbles" },
        ],
        5: [
            { label: "Errors Leading To Goal", column: "errors" },
            { label: "Shots Blocked", column: "shots_blocked" },
            { label: "Interceptions", column: "interceptions" },
            { label: "Clearances", column: "clearances" },
            { label: "Off Line", column: "off_line" },
            { label: "Dribbled Past", column: "dribbled_past" },
            { label: "Long Balls Won", column: "long_balls_won" },
        ],
        6: [
            { label: "Saves", column: "saves" },
            { label: "Claims", column: "claims" },
            { label: "Punches", column: "punches" },
            { label: "Saves Inside Box", column: "saves_inside_box" },
            { label: "Goals Conceded", column: "goalkeeper_goals_conceded" },
            { label: "Penalties Saved", column: "penalties_saved" },
        ],
    };

    return headersByStatistic[selectedStatistic] || [];
};

function OrderPlayers(players, statisticTableState) {
    switch(statisticTableState.orderBy)
    {
        case "rating":
            {
                return players.sort((a, b) => { return a.rating < b.rating ? 1 : -1 });
            }
        case "minutes":
            {
                return players.sort((a, b) => { 
                    return (a.statistics_wrapper?.overall_statistics?.minutes_played ?? 0) < 
                    (b.statistics_wrapper?.overall_statistics?.minutes_played ?? 0) ? 1 : -1 
                });
            }
        case "goals":
            {
                return players.sort((a, b) => { return a.total_goals < b.total_goals ? 1 : -1 });
            }
        case "assists":
            {
                return players.sort((a, b) => { return a.total_assists < b.total_assists ? 1 : -1 });
            }
        case "sot":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.shot_statistics?.shots_on_target ?? 0) < 
                    (b.statistics_wrapper?.shot_statistics?.shots_on_target ?? 0) ? 1 : -1 
                });
            }
        case "pass_acc":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.pass_statistics?.accurate_passes_percentage ?? 0) < 
                    (b.statistics_wrapper?.pass_statistics?.accurate_passes_percentage ?? 0) ? 1 : -1 
                });
            }
        case "sot_inside_box":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.shot_statistics?.shots_inside_box ?? 0) < 
                    (b.statistics_wrapper?.shot_statistics?.shots_inside_box ?? 0) ? 1 : -1 
                });
            }
        case "sot_outside_box":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.shot_statistics?.shots_outside_box ?? 0) < 
                    (b.statistics_wrapper?.shot_statistics?.shots_outside_box ?? 0) ? 1 : -1 
                });
            }
        case "blocked_shots":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.shot_statistics?.shots_blocked ?? 0) < 
                    (b.statistics_wrapper?.shot_statistics?.shots_blocked ?? 0) ? 1 : -1 
                });
            }
        case "touches":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.pass_statistics?.touches ?? 0) < 
                    (b.statistics_wrapper?.pass_statistics?.touches ?? 0) ? 1 : -1 
                });
            }
        case "long_balls":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.pass_statistics?.long_balls ?? 0) < 
                    (b.statistics_wrapper?.pass_statistics?.long_balls ?? 0) ? 1 : -1 
                });
            }
        case "key_passes":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.pass_statistics?.key_passes ?? 0) < 
                    (b.statistics_wrapper?.pass_statistics?.key_passes ?? 0) ? 1 : -1 
                });
            }
        case "acc_crosses":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.pass_statistics?.accurate_crosses ?? 0) < 
                    (b.statistics_wrapper?.pass_statistics?.accurate_crosses ?? 0) ? 1 : -1 
                });
            }
        case "tackles":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.total_tackles ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.total_tackles ?? 0) ? 1 : -1 
                });
            }
        case "duels_won":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.duel_statistics?.duels_won ?? 0) < 
                    (b.statistics_wrapper?.duel_statistics?.duels_won ?? 0) ? 1 : -1 
                });
            }
        case "aerial_won":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.duel_statistics?.aerial_duels_won ?? 0) < 
                    (b.statistics_wrapper?.duel_statistics?.aerial_duels_won ?? 0) ? 1 : -1 
                });
            }
        case "fouls":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.duel_statistics?.fouls ?? 0) < 
                    (b.statistics_wrapper?.duel_statistics?.fouls ?? 0) ? 1 : -1 
                });
            }
        case "fouls_drawn":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.duel_statistics?.fouls_drawn ?? 0) < 
                    (b.statistics_wrapper?.duel_statistics?.fouls_drawn ?? 0) ? 1 : -1 
                });
            }
        case "dispossessed":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.duel_statistics?.dispossessed ?? 0) < 
                    (b.statistics_wrapper?.duel_statistics?.dispossessed ?? 0) ? 1 : -1 
                });
            }
        case "successful_dribbles":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.duel_statistics?.successful_dribbles ?? 0) < 
                    (b.statistics_wrapper?.duel_statistics?.successful_dribbles ?? 0) ? 1 : -1 
                });
            }
        case "errors":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.errors_leading_to_goals ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.errors_leading_to_goals ?? 0) ? 1 : -1 
                });
            }
        case "shots_blocked":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.shots_blocked ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.shots_blocked ?? 0) ? 1 : -1 
                });
            }
        case "interceptions":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.interceptions ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.interceptions ?? 0) ? 1 : -1 
                });
            }
        case "clearances":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.clearances ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.clearances ?? 0) ? 1 : -1 
                });
            }
        case "off_line":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.clearances_off_line ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.clearances_off_line ?? 0) ? 1 : -1 
                });
            }
        case "dribbled_past":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.dribbled_past ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.dribbled_past ?? 0) ? 1 : -1 
                });
            }
        case "long_balls_won":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.defensive_statistics?.long_balls_won ?? 0) < 
                    (b.statistics_wrapper?.defensive_statistics?.long_balls_won ?? 0) ? 1 : -1 
                });
            }
        case "saves":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.goalkeeping_statistics?.saves ?? 0) < 
                    (b.statistics_wrapper?.goalkeeping_statistics?.saves ?? 0) ? 1 : -1 
                });
            }
        case "claims":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.goalkeeping_statistics?.come_outs ?? 0) < 
                    (b.statistics_wrapper?.goalkeeping_statistics?.come_outs ?? 0) ? 1 : -1 
                });
            }
        case "punches":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.goalkeeping_statistics?.punches ?? 0) < 
                    (b.statistics_wrapper?.goalkeeping_statistics?.punches ?? 0) ? 1 : -1 
                });
            }
        case "saves_inside_box":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.goalkeeping_statistics?.saves_inside_box ?? 0) < 
                    (b.statistics_wrapper?.goalkeeping_statistics?.saves_inside_box ?? 0) ? 1 : -1 
                });
            }
        case "goalkeeper_goals_conceded":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.goalkeeping_statistics?.goalkeeper_goals_conceded ?? 0) < 
                    (b.statistics_wrapper?.goalkeeping_statistics?.goalkeeper_goals_conceded ?? 0) ? 1 : -1 
                });
            }
        case "penalties_saved":
            {
                return players.sort((a, b) => { return (a.statistics_wrapper?.goalkeeping_statistics?.penalties_saved ?? 0) < 
                    (b.statistics_wrapper?.goalkeeping_statistics?.penalties_saved ?? 0) ? 1 : -1 
                });
            }
    }
}

export default FixtureLineups;
