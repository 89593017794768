import { useRef, useState } from "react"
import { Paper } from "@mui/material";
import { MDBRow } from "mdbreact";
import MobileFixtureComments from "./mobile_fixture_comments";
import ErrorBoundary from "../../../application/error/error_boundary";
import { useIsVisible } from "../../../application/shared/hooks/isVisibleHook";

export default function MobileFixtureCommentary({ comments })
{
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);
    useIsVisible(isVisible, setIsVisible, componentMounted);

    return comments.length < 1 ? <></> : (
        <ErrorBoundary>
          <Paper elevation={1} className={`br-15 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
              <MDBRow style={{padding: '12px 0 12px 0' }}>
                <MobileFixtureComments
                    className="fixture-commentary"
                    comments={comments}
                />
              </MDBRow>
          </Paper>
      </ErrorBoundary>
    )
}