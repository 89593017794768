import { Pagination, Paper, Tooltip, tooltipClasses } from "@mui/material";
import { MDBRow } from "mdb-react-ui-kit";
import { styled } from "@mui/system";
import React, { forwardRef, useRef, useState } from "react";
import '../dashboards/css/player_insights.css';
import NoWidgetContent from "../shared/widget/no_widget_content";
import { GetGradientClass } from "../../common/helpers/rating_helper";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { useTransition, animated } from 'react-spring';
import { Stack } from "react-bootstrap";
import Autocomplete from "../shared/widget/autocomplete_textbox";
import hour_glass_gif from '../../images/hour_glass.gif';
import { MDBCol } from "mdbreact";

function filterByPlayerNameOrClub(array, value) {

    return array.filter((data) => 
    
        data.player_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        
    );

}

function paginate(array, pageIndex, pageSize, searchTerm) {

    var endIndex = Math.min((pageIndex) * pageSize, array.length);

    array = filterByPlayerNameOrClub(array, searchTerm);

    return array.slice(Math.max(endIndex - pageSize, 0), endIndex);

}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: '12px',
      border: '1px solid #dadde9',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
  }));

const StatisticsDiv = forwardRef((props, ref) => {

    const { player_id, player_image, team_logo, player_name, statistics } = props;

    return (

        <React.Fragment>

            <div>

                { 
                
                    team_logo === "" ? <React.Fragment /> : <img style={{width: '30px', height: '30px'}} src={team_logo} /> 
                    
                }

            </div>

            <div className="elipsis_text_wrap">{player_name}</div>

            { 
            
                isNaN(Number(statistics.total_fouls_committed)) ? 
                
                <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Fouls Committed</span></MDBCol></MDBRow></React.Fragment>}><span>Fouls</span></HtmlTooltip></div> :

                    <div className={statistics.total_fouls_committed > 5 ? "high-insight" : `insight-${statistics.total_fouls_committed}`}><span>{statistics.total_fouls_committed}</span></div>

            }

            {
                
                isNaN(Number(statistics.total_passes)) ? <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Passes Completed</span></MDBCol></MDBRow></React.Fragment>}><span>Passes</span></HtmlTooltip></div> :

                    <div className="insight-0" ><span>{statistics.total_accurate_passes} / {statistics.total_passes}</span></div>

            }

            {
                
                isNaN(Number(statistics.total_yellow_cards)) ? <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Yellow Cards</span></MDBCol></MDBRow></React.Fragment>}><span>YC</span></HtmlTooltip></div> : 
                
                    <div className={`insight-${statistics.total_yellow_cards}`}><span>{statistics.total_yellow_cards}</span></div>

            }

            {
                
                isNaN(Number(statistics.total_red_cards + statistics.total_red_yellow_cards)) ? <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Red Card</span></MDBCol></MDBRow></React.Fragment>}><span>RC</span></HtmlTooltip></div> : 
                
                    <div className={`insight-${statistics.total_red_cards + statistics.total_red_yellow_cards}`}><span>{statistics.total_red_cards + statistics.total_red_yellow_cards}</span></div>

            }

            {
                
                isNaN(Number(statistics.total_shots_on_target)) ? <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Shots on Target</span></MDBCol></MDBRow></React.Fragment>}><span>SOT</span></HtmlTooltip></div> : 
                
                    <div className={statistics.total_shots_on_target > 5 ? "high-insight" : `insight-${statistics.total_shots_on_target}`}><span>{statistics.total_shots_on_target}</span></div>

            }

            {
                
                isNaN(Number(statistics.total_goals_scored)) ? <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Goals Scored</span></MDBCol></MDBRow></React.Fragment>}><span>Goals</span></HtmlTooltip></div> : 
                
                    <div className={statistics.total_goals_scored > 5 ? "high-insight" : `insight-${statistics.total_goals_scored}`}><span>{statistics.total_goals_scored}</span></div>

            }

            {
            
                isNaN(Number(statistics.match_rating)) ? <div><HtmlTooltip style={{padding: 0}} title={<React.Fragment><MDBRow><MDBCol className="no_side_padding p-2"><span>Player Rating</span></MDBCol></MDBRow></React.Fragment>}><span>Rating</span></HtmlTooltip></div> : 
                
                    <div className={GetGradientClass(statistics.match_rating)}><span>{statistics.match_rating}</span></div>

            }

        </React.Fragment>)

})


export default function LiveInsightsPlayerTable(props) {

    const { fixture_id, contentSetter, current_league_id, apiResponse, site_date } = props;

    const [isVisible, setIsVisible] = React.useState(false);

    const [filterState, setFilterState] = useState({ page: 1, searchTerm: "" });

    const componentMounted = useRef(true);

    var statisticsResponse = apiResponse;

    const inputChange = (event, value) => {

        setFilterState(prevFilterState => {

            return { 
                
                page: 1,

                searchTerm: value

            }

        })

    };

    const pagingChange = (event, value) => {

        setFilterState(prevFilterState => {

            return { 
                
                ...prevFilterState,

                page: value

            }

        })

    };

    useIsVisible(isVisible, setIsVisible, componentMounted);

    statisticsResponse = statisticsResponse === null || statisticsResponse === undefined ||
        
        (statisticsResponse !== null && !statisticsResponse.successful_request) ? { data: [] } : statisticsResponse;

    var fixturePlayerStatistics = fixture_id === null || fixture_id === undefined || fixture_id === "" || parseInt(fixture_id) === 0 ? statisticsResponse.data : statisticsResponse.data.filter(stats => stats.fixture_id === parseInt(fixture_id));

    var allStatistics = [];

    for(var i = 0; i < fixturePlayerStatistics.length; i++) {

        allStatistics = [...allStatistics, ...fixturePlayerStatistics[i].home_team.player_statistics, ...fixturePlayerStatistics[i].away_team.player_statistics];

    }

    allStatistics = allStatistics.filter(player => player.total_minutes_played > 0).sort((a, b) => parseFloat(a.match_rating) < parseFloat(b.match_rating) ? 1 : -1);

    var totalStatisticsCount = allStatistics.length;

    allStatistics = paginate(allStatistics, filterState.page, 20, filterState.searchTerm, setFilterState);

    var statisticsCount = allStatistics.length < 20 ? allStatistics.length + 1 : totalStatisticsCount;

    let height = 51;

    allStatistics.splice(0, 0, 
        
        { 
            
            player_id: '', 
        
            player_image: '', 
            
            team_logo: '', 
            
            player_name: '', 
            
            total_fouls_committed: 'Fouls',

            total_accurate_passes: 'Passes',

            total_yellow_cards: 'YC',

            total_yellow_red_cards: '',

            total_red_cards: 'RC',

            total_shots_on_target: 'SOT',

            total_goals_scored: 'Goals',

            match_rating: 'Rating'
    
        }
    
    );

    const transitions = useTransition(

        allStatistics.map((allStatistics, i) => ({ ...allStatistics, y: height * i })),

        // (d) => d.name,

        {
          from: { position: "absolute", opacity: 0 },

          leave: { display: 'none', opacity: 0 },

          enter: ({ y }) => ({ y, opacity: 1, display: '' }),

          update: ({ y }) => ({ y, opacity: 1 }),

          key: (item) => item?.player_name + "_" + item.player_id + "_" + item.team_id

        }

      );

    if(statisticsResponse === null || statisticsResponse === undefined ||
        
        (statisticsResponse !== null && !statisticsResponse.successful_request))
        
    {

        return <NoWidgetContent no_content_heading={"Loading Live Insights"} no_content_sub_heading={"Currently generating live insights, please wait..."} image_src={hour_glass_gif} />

    }

    if(statisticsResponse.data.length === 0) {

        return <NoWidgetContent no_content_heading={"No Live Insights"} no_content_sub_heading={"Please come back when todays scheduled fixtures have kicked off"} image_src={hour_glass_gif} additional_information={"Visit the player insights section above to view historical insights for players and teams playing today"} />

    }

    return (

        <Paper elevation={1} className={`mb-2 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
                
            <MDBRow style={ { paddingBottom: '0' } } className='widget_container'>

                <div className='widget_heading'>In Game Live Statistics</div>

                <div className="widget_sub_heading">Live in game statistics from the current round of fixtures</div>

                <div className="widget_controller p-2">

                    <Autocomplete setSearchTerm={inputChange} placeholder="Search Players..." />

                </div>

                <div className='widget_content'>

                    <div style={{display: 'flex', position: 'relative', height: height * statisticsCount, maxHeight: '900px', overflow: 'auto', overflowX: 'auto' }}>

                        {transitions(({ y, ...rest }, statistics, { key }) => (

                            <animated.div

                                key={key}

                                className="statistic_flex_div"

                                style={{

                                    transform: y.to((y) => `translate3d(0,${y}px,0)`),

                                    ...rest

                                }}

                            >

                                <StatisticsDiv key={key} player_id={statistics.player_id} player_image={statistics.player_image} team_logo={statistics.team_logo} player_name={statistics.player_name} statistics={statistics} />

                            </animated.div>
                            
                        ))}
                
                    </div>

                </div>

                <div className="widget_controller p-2">

                    <Stack spacing={2}>

                        <Pagination 
                        
                            count={statisticsCount === 0 ? 0 : (statisticsCount % 20 > 0 ? (Math.floor(statisticsCount / 20) + 1) : Math.floor(statisticsCount / 20))} 
                        
                            color="primary" 
                        
                            onChange={pagingChange} 
                        
                            page={filterState.page} 
                            
                        />

                    </Stack>

                </div>

            </MDBRow>

        </Paper>

    )

}