import { Paper } from "@mui/material"
import { useState } from "react"
import { Spinner } from "reactstrap"
import { decode } from "he";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useFixtureHighlights } from "../../../application/fixtures/fixtureHook";

export default function MobileFixtureHighlights({ fixtureId, fixtureName }) {
  const [fixtureHighlightsResponse, setFixtureHighlightsResponse] = useState({ isLoading: true, data: null })
  
  useFixtureHighlights(fixtureId, setFixtureHighlightsResponse);

  if(!fixtureHighlightsResponse.isLoading && !fixtureHighlightsResponse.data) {
      return <></>
  }

  if(fixtureHighlightsResponse.isLoading) {
      return <Paper elevation={1} className="mb-2 br-15 widget_fade_in no_side_padding">
          <Spinner />
      </Paper>
  }

  const videoId = fixtureHighlightsResponse.data.video_id;
  const highResImageUrl = fixtureHighlightsResponse.data.thumbnail_high_resolution_url;
  const title = decode(fixtureHighlightsResponse.data.title);

  return (
      <HighlightCard
          videoId={videoId}
          highResImageUrl={highResImageUrl}
          title={title}
          fixtureName={fixtureName}
      />
  )
}

const HighlightCard = ({ videoId, highResImageUrl, title, fixtureName }) => {
  return (
    <div style={{ marginBottom: '10px', borderRadius: '15px', position: 'relative', boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)' }}>
      <a
        href={`https://www.youtube.com/watch?v=${videoId}`}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.container}
      >
          <img
              src={highResImageUrl}
              alt={title}
              title={title}
              style={styles.image}
          />
          {/* <div>
              <div className="highlight-card-title" style={styles.title}>{title}</div>
              <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <div style={{width: '15px', height: '15px', display: 'flex', alignItems: 'center', marginRight: '2px'}}>
                      <img src={YoutubeLogo} style={{ width:'100%', height: 'auto' }} />
                  </div>
                  <span style={{fontSize: '10px', fontWeight: '600' }}>Youtube</span>
              </div>
          </div> */}
      </a>
      <div style={styles.overlay}>
      <div style={styles.overlayText}>Official Highlights - {fixtureName}</div>
        <YouTubeIcon style={styles.overlayImage} color="white" />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '225px',
    overflow: 'hidden',
    display: 'block'
  },
  image: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    borderRadius: '15px',
     objectFit: 'cover'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    pointerEvents: 'none',
    borderRadius: '15px'
  },
  overlayText: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    zIndex: '2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%'
  },
  overlayImage: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    color: 'white'
  }
};