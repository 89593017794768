import { useState } from "react"
import { useFixtureTvStations } from "./fixtureHook";
import { FormControl, MenuItem, Paper, Select } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FaTv } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Divider, { dividerClasses } from '@mui/material/Divider';

const CustomSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-select": {
        padding: "8px"
    },
}));

export default function FixtureTVStations({fixtureId, countryId}) {
    const [fixtureTvChannelResponse, setFixtureTvChannelResponse] = useState({ isLoading: true, data: null });
    const [selectedCountryId, setSelectedCountryId] = useState(countryId);

    const onCountryChange = (event) => {
        setSelectedCountryId(event.target.value);
    }

    useFixtureTvStations(fixtureId, setFixtureTvChannelResponse);

    if(!fixtureTvChannelResponse || !fixtureTvChannelResponse.data || fixtureTvChannelResponse.isLoading) {
        return <></>
    }

    var selectedCountry = fixtureTvChannelResponse.data.countries.find(
        (country) => country.id === selectedCountryId
    );

    if(!selectedCountry) {
        var country = fixtureTvChannelResponse.data.countries[0];
        setSelectedCountryId(country.id);
    }

    if(!selectedCountry) {
        return <></>
    }

    return (
        <Paper elevation={1} className="mb-2 br-15" sx={{[`& .${dividerClasses.root}`]: { mx: 0, height: '0.5px', opacity: '0.1' }}}>
            <MDBRow>
                <MDBCol style={{display: 'flex', justifyContent: 'space-between', padding: '10px 10px 0px 10px'}} size="12">
                    <div style={{fontSize: '14px', display: 'flex', alignItems: 'center'}}>
                        <HelpOutlineIcon sx={{fontSize: '18px', marginRight: '5px'}} /> Where can I watch?
                    </div>
                    
                    <FormControl sx={{ minWidth: 50 }}>
                        <CustomSelect
                            value={selectedCountryId}
                            onChange={onCountryChange}
                            renderValue={(selected) => {
                                const selectedCountry = fixtureTvChannelResponse.data.countries.find(
                                    (country) => country.id === selected
                                );

                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "32px",
                                            height: "32px",
                                            borderRadius: "50%",
                                            backgroundColor: "#f0f0f0"
                                        }}
                                    >
                                        <img
                                            src={selectedCountry.country_flag}
                                            alt={`${selectedCountry.name} flag`}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "50%",
                                            }}
                                        />
                                    </div>
                                );
                            }}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                                "& .MuiSelect-select": {
                                    padding: 0,
                                },
                            }}
                        >
                            {fixtureTvChannelResponse.data.countries.map((country) => (
                                <MenuItem value={country.id} key={`country_flag_${country.id}`}>
                                    <img
                                        src={country.country_flag}
                                        alt={`${country.name} flag`}
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            marginRight: "8px",
                                            borderRadius: "50%",
                                        }}
                                    />
                                    <span style={{ fontSize: "16px" }}>{country.name}</span>
                                </MenuItem> 
                            ))}
                        </CustomSelect>
                    </FormControl>
                </MDBCol>
            </MDBRow>
            <Divider />
            <MDBRow style={{paddingTop: '10px'}} className="pb-2">
                <MDBCol>
                    <div style={{backgroundColor: '#f3f3f3', borderRadius: '8px' }}>
                    {
                        selectedCountry.tv_stations.map((station, index) => {
                            return (
                                <div key={"tv_station_" +  index + "_" + station.id} style={{ padding: '6px 12px', display: 'flex', marginBottom: '4px', justifyContent: 'start', gap: '10px', alignItems: 'center'}}>
                                    <span><FaTv size={24} /></span>
                                    <span style={{fontSize: '13px'}}>{station.name}</span>
                                    {station.image_path && <span style={{marginLeft: 'auto'}}>
                                        <img src={station.image_path} alt={station.name + " logo"} style={{ width: 'auto', height: '20px' }} />
                                    </span>}
                                </div>
                            )
                        })
                    }
                    </div>
                </MDBCol>
            </MDBRow>
        </Paper>
    )
}