import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
//import Select from '@material-ui/core/Select';
import React, { useState } from "react";
// import { useFixtureResults, usePlayerStatisticInsights } from "../fixtures/fixtureHook";
import ErrorBoundary from "../error/error_boundary";
import NoWidgetContent from "../shared/widget/no_widget_content";
import FoulInsights from "./foul_insights";
import PlayerStatisticInsights from "./player_statistic_insights";
import hour_glass_gif from '../../images/hour_glass.gif';

function CalculateFoulInsights(insightResponse, fixture_id) {

    if(insightResponse === null || (insightResponse !== null && !insightResponse.successful_request)) {

        return [];

    }

    if(insightResponse.data.insight_data.length < 1) {

        return [];

    }

    var topTenFoulers = insightResponse.data.insight_data.filter((item, index) => 
    
        { 
            
            return (fixture_id === 0 || fixture_id === null || fixture_id === undefined ? true : item.todays_fixture_id === fixture_id) 
        
        }).sort((a, b) => a.foul_total < b.foul_total ? 1 : -1).slice(0, 8);

    var foulInsights = [];

    for(var i = 0; i < topTenFoulers.length; i++) {

        var fouler = topTenFoulers[i];

        var fixtureFoulInsights = [];

        for(var a = 0; a < fouler.statistic_insights.slice(0, 5).sort((a, b) => a.total_fouls < b.total_fouls ? 1 : -1).length; a++) {

            var fixtureInsight = fouler.statistic_insights[a];

            fixtureFoulInsights.push({ fixture_id: fixtureInsight.fixture_id, home_team_id: fixtureInsight.home_team_id, away_team_id: fixtureInsight.away_team_id, home_team_logo: fixtureInsight.home_team_logo, away_team_logo: fixtureInsight.away_team_logo, foul_count: fixtureInsight.foul_count, tackles: fixtureInsight.tackles });

        }

        foulInsights.push({ player_team_id: fouler.team_id, player_name: fouler.player_name, player_image: fouler.player_image, player_id: fouler.player_id, total_fouls: fouler.foul_total, fixture_foul_insights: fixtureFoulInsights });

    }

    return foulInsights;

}

export default function MatchDayInsights(props) {

    const { fixture_id, current_league_id, contentSetter, isMobile, site_date } = props;

    const [filterState, setFilterState] = useState({ page: 1 });

    const [fixturesResponse, setfixturesResponseState] = useState(null);

    var insightResponse = null;
    
    //usePlayerStatisticInsights(current_league_id === undefined ? null : current_league_id, site_date);

    var foulInsights = CalculateFoulInsights(insightResponse, fixture_id);

    //useFixtureResults(site_date, current_league_id, setfixturesResponseState);

    const pagnationChange = (event, value) => {

        setFilterState(prevFilterState => {

            return { 
                
                ...prevFilterState,

                page: value

            }

        })

    };

    const handleFixtureChange = (event) => {

        contentSetter(isMobile ? 11 : 7, parseInt(event.target.value) === 0 ? null : parseInt(event.target.value))

    };

    if(insightResponse === null || insightResponse.data === null || 
        
        (insightResponse !== null && !insightResponse.successful_request)) {

        return <NoWidgetContent no_content_heading={"Loading Insights"} no_content_sub_heading={"Currently calculating insights, please wait..."} image_src={hour_glass_gif} />

    }

    var noData = fixturesResponse === null || fixturesResponse.data === null || fixturesResponse.data.length === 0 || fixturesResponse.data.results.length === 0;

    return (

        <React.Fragment>

            <Grid container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    {

                        noData ? <></> : <FormControl className='widget_content_selection' variant="filled">

                            <Select

                                native={isMobile}
                                
                                className='widget_selection_dropdown'

                                value={fixture_id === null || fixture_id === undefined ? 0 : fixture_id}

                                onChange={handleFixtureChange}

                                label="Select Fixture"

                                style={{textAlign: 'center'}}

                            >

                                {isMobile ? <option value={0}>All Fixtures</option> : <MenuItem value={0}>All Fixtures</MenuItem>}

                                {

                                    fixturesResponse === null || fixturesResponse.data === null || fixturesResponse.data.length === 0 || fixturesResponse.data.results.length === 0 ?

                                    [] :

                                    fixturesResponse.data.results.map((fixture, index) => {

                                        return isMobile ? (

                                            <option key={"fixture_insight_mobile_menu_item_" + fixture.fixture_id} value={fixture.fixture_id}>{fixture.home_team_name} v {fixture.away_team_name}</option>

                                        ) : 
                                        (

                                            <MenuItem key={"fixture_insight_menu_item_" + fixture.fixture_id} value={fixture.fixture_id}>
                                            
                                                <img style={{width: '25px', height: '25px', marginRight: '10px'}} src={fixture.home_team_logo} /> 
                                                
                                                {fixture.home_team_name} v {fixture.away_team_name}

                                                <img style={{width: '25px', height: '25px', marginLeft: '10px'}} src={fixture.away_team_logo} /> 
                                                
                                            </MenuItem>

                                        )

                                    })

                                }

                            </Select>

                        </FormControl>

                    }

                </Grid>

                {

                    <React.Fragment>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <ErrorBoundary>
                                
                                <PlayerStatisticInsights page={filterState.page} pagnationChange={pagnationChange} insightResponse={insightResponse} fixture_id={fixture_id} isMobile={isMobile} />
                                
                            </ErrorBoundary>

                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <ErrorBoundary>
                                
                                <FoulInsights foul_insights={foulInsights} fixture_id={fixture_id} />
                                
                            </ErrorBoundary>

                        </Grid>

                    </React.Fragment>

                }

            </Grid>

        </React.Fragment>

    )

}