import React from 'react';
import { Divider, Paper } from "@mui/material";
import { GetGradientClass } from "../../../common/helpers/rating_helper";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const styles = {
    imageStyle: {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        objectFit: 'cover',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        position: 'relative',
        border: '2px solid #ffffff',
        marginRight: '5px'
    },
    teamLogo: {
        width: '14px',
        position: 'absolute',
        left: '23px',
        top: '22px'
    },
    ratingContainer: {
        padding: '3px',
        display: 'flex',
        color: '#fff',
        fontWeight: '600',
        textAlign: 'center',
        verticalAlign: 'top'
    },
    playerContainer: {
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center'
    },
    playerNames: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px'
    },
    teamContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '50%',
        fontSize: '11px'
    }
}

export default function MobileTopRatedPlayers({ fixture_id, teams, fixtureLineups, setIndex }) {
    const extractPlayerStatistics = (lineup) => {
        return lineup.map(player => ({
            player_id: player.player_id,
            player_image: player.player_image,
            rating: player.rating,
            statistics: player.lineup_statistics,
            statistics_wrapper: player.statistics_wrapper,
            name: player.player_name,
            number: player.jersey_number
        })).sort((a, b) => a.rating < b.rating ? 1 : -1).splice(0, 3);
    };

    const handleTabChange = () => {
        setIndex(1);
    };

    const topThreeHomeTeamPlayers = extractPlayerStatistics(fixtureLineups.data.home_team_lineup);
    const topThreeAwayTeamPlayers = extractPlayerStatistics(fixtureLineups.data.away_team_lineup);

    const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

    return (
        <Paper elevation={1} className="mb-2 br-15 widget_fade_in no_side_padding">
            <div className='widget_heading' style={{textAlign: 'center', padding: '10px 10px 10px 10px'}}>
                Top Performers
            </div>
            <Divider style={{opacity: '0.1'}} />
            {
                <div style={{display: 'flex'}}>
                    <div style={styles.teamContainer}>
                        {
                            topThreeHomeTeamPlayers.map((player, index) => {
                                return (
                                    <PlayerRow key={"top_players_row_" + player.player_id} placeholderImage={placeholderImage} player={player} index={index} team={teams.home_team} />
                                )
                            })
                        }
                    </div>
                    <div style={styles.teamContainer}>
                        {
                            topThreeAwayTeamPlayers.map((player, index) => {
                                return (
                                    <PlayerRow key={"top_players_row_" + player.player_id} player={player} index={index} team={teams.away_team} />
                                )
                            })
                        }
                    </div>
                </div>
                
            }
            <Divider style={{opacity: '0.1'}} />
            <div style={{padding: '8px', display: 'flex', justifyContent: 'center', fontSize: '12px'}}>
                <a href="#Lineups" className="anchor-tag" onClick={handleTabChange} style={{ textAlign: 'center', textDecoration: 'none' }}>View more <KeyboardArrowRightIcon /></a>        
            </div>
        </Paper>
    )
}

const PlayerRow = ({player, placeholderImage, index, team}) => {
    return (
        <React.Fragment>
            <div style={{...styles.playerContainer, padding: '6px 6px 6px 15px' }}>
                <div style={{position: 'relative'}}>
                    <img alt={player.name + " image"} style={{...styles.imageStyle }} src={player.player_image || placeholderImage} onError={(e) => (e.target.src = placeholderImage)} />
                    <img alt={team.name + " logo"} src={team.logo} style={styles.teamLogo} />
                </div>
                <span style={{...styles.playerNames, fontWeight: '500'}}>
                    <span style={{ color: '#6a6a6a', marginRight: '3px'}}>{player.number}</span>
                    {player.name}
                </span>
                {player.rating && player.rating !== "0" && player.rating > 0 ? <span className={GetGradientClass(player.rating)} style={{...styles.ratingContainer, marginLeft: 'auto'}}>
                    {player.rating}
                </span> : <></>}
            </div>
            {index !== 2 && <Divider style={{opacity: '0.1'}} />}
        </React.Fragment>
    )
}