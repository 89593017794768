import { useEffect } from "react";

export const useIsVisible = (isVisible, setter, componentMounted) => {

    useEffect(() => {
        
        const timeoutId = setTimeout(() => 
        
            {

                if (componentMounted.current) {

                    setter(true)

                }

            }, 
            
        3000);

        // cleanup function

        return () => {

            componentMounted.current = false;

            clearTimeout(timeoutId);
            
        }
        
    }, [isVisible]);

}