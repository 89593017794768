import React from "react";
import { connect } from 'react-redux';
import { MDBRow, MDBCol } from 'mdbreact';
import { ApplicationState } from '../../../store/index';
import * as ApplicationDataStore from '../../../store/application-state';
import StatManLogo from '../../../images/stat_man_logo_aqua.jpg';
import '../css/primary_dashboard.css';
import '../css/content_dashboard.css';
import '../css/dashboard.css';
import '../../../mobile/application/dashboards/css/mobile_dashboard.css';

import DashboardFooter from "./dashboard_footer";
import { AiOutlineHome } from "react-icons/ai";
import { BsGraphUpArrow, BsPeople, BsPerson } from "react-icons/bs";
import { Outlet } from "react-router-dom";

type StatisticDashboardProps =

    ApplicationState // ... state we've requested from the Redux store

    & typeof ApplicationDataStore.actionCreators // ... plus incoming routing parameters.

class StatisticsDashboard extends React.PureComponent<StatisticDashboardProps, ApplicationState> {

    constructor(props: StatisticDashboardProps) {

        super(props);

    }

    // This method is called when the component is first added to the document
    
    public componentDidMount() {



    }

    render() {

        return (

            <React.Fragment>
                
                <MDBRow className="row_statistics_dashboard" style={{height: '100%'}}>

                    <MDBCol className="dashboard_menu">

                        <img src={StatManLogo} className="the_stat_man_dashboard_logo" alt="The stat man logo" />

                        <ul className="dashboard_menu_list">

                            <div className="dashboard_menu_seperator"></div>

                            <li className="dashboard_list_item">
                                
                                <a href="/"> <AiOutlineHome /> Home</a>
                                
                            </li>

                            <div className="dashboard_menu_seperator"></div>

                            <li className="dashboard_list_item">
                                
                                <a href="/dashboards/statistics/players"> <BsPerson /> Player Comparison</a>
                                
                            </li>

                            <li className="dashboard_list_item">
                                
                                <a href="/dashboards/statistics/teams"> <BsPeople /> Team Comparison</a>
                                
                            </li>

                            <li className="dashboard_list_item">
                                
                                <a href="/dashboards/statistics/insights"> <BsGraphUpArrow /> Insights</a>
                                
                            </li>

                        </ul>

                        {/* <div className="column_seperator"></div>

                        <ul className="dashboard_menu_list_leagues">

                            

                        </ul> */}

                        <DashboardFooter />

                    </MDBCol>

                    <MDBCol className="column_no_side_padding primary_dashboard_secondary_column" style={{flex: '1'}}>

                        <MDBRow>

                            <MDBCol style={{padding:'10px'}} size="12">

                                <Outlet />

                            </MDBCol>

                        </MDBRow>

                    </MDBCol>

                </MDBRow>

            </React.Fragment>

        );

    }

}

export default connect(

    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props

    ApplicationDataStore.actionCreators // Selects which action creators are merged into the component's props

)(StatisticsDashboard as any);