import { useRef, useState } from "react";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import ErrorBoundary from "../error/error_boundary";
import { Paper } from "@mui/material";
import { MDBRow } from "mdbreact";
import FixtureComments from "./fixture_comments";

export default function FixtureCommentary({ comments })
{
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);
    useIsVisible(isVisible, setIsVisible, componentMounted);
    
    return comments.length < 1 ? <></> : (
    <ErrorBoundary>
        <Paper elevation={1} className={`mb-2 br-15 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
            <MDBRow style={{padding: '12px 0 12px 0' }}>
                <FixtureComments
                    className="fixture-commentary"
                    comments={comments}
                />
            </MDBRow>
        </Paper>
    </ErrorBoundary>
    )
}