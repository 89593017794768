import React from "react";
import { Tooltip, Typography, tooltipClasses, TableContainer, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { styled } from "@mui/system";
import { MDBRow } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: '12px',
      border: '1px solid #dadde9',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
  }));

export default function PlayerStatisticsToolTip(props) {

    const { playerSummary, fixtureStats, showOpponents, GetStatisticClassName, index } = props;

    return (

        <HtmlTooltip

            style={{padding: 0}}

            title={

            <React.Fragment>

                <MDBRow>

                    <MDBCol className="no_side_padding p-2" size="4">

                        <img style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '40px', height: '40px' }} src={fixtureStats.home_team_logo} />

                    </MDBCol>

                    <MDBCol style={{alignItems: 'center', display: 'flex'}} className="no_side_padding p-2" size="4">

                        <span style={{fontSize: '25px', fontWeight: '500', marginLeft: 'auto', marginRight: 'auto', display: 'block', textAlign: 'center' }}>{fixtureStats.result}</span>

                    </MDBCol>

                     <MDBCol className="no_side_padding p-2" size="4">

                        <img style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block',width: '40px', height: '40px' }}  src={fixtureStats.away_team_logo} />

                    </MDBCol>

                </MDBRow>

                <MDBRow style={{borderTop: '1px solid #dcdcdc', borderBottom: '1px solid #dcdcdc', paddingTop: '6px', paddingBottom: '6px'}}>

                    <MDBCol size="4" className="no_side_padding">

                        <img src={playerSummary.player_image} style={{ borderRadius: '50%', border: '1px solid #bebebe', marginLeft: 'auto', marginRight: 'auto', display: 'block',width: '50px', height: '50px' }} />

                    </MDBCol>

                    <MDBCol size="8" className="no_side_padding" style={{alignItems: 'center', display: 'flex'}}>

                        <label style={{fontSize: '12px', fontWeight: '500', marginLeft: 'auto', marginRight: 'auto', display: 'block', textAlign: 'center' }}>{playerSummary.player_name}</label>

                    </MDBCol>

                </MDBRow>

                <MDBRow className="no_side_padding">

                    <MDBCol size="12" className="no_side_padding">

                        <TableContainer>

                            <Table className="table_player_statistic_insights table_tooltip_statistics">

                                <TableBody>

                                    <TableRow>

                                        <TableCell >Minutes</TableCell>

                                        <TableCell className={GetStatisticClassName(index, playerSummary, 7)}>{fixtureStats.minutes_played}</TableCell>

                                    </TableRow>

                                    <TableRow>

                                        <TableCell>Goals</TableCell>

                                        <TableCell className={GetStatisticClassName(index, playerSummary, 2)}>{fixtureStats.goals_scored}</TableCell>

                                    </TableRow>

                                    <TableRow>

                                        <TableCell>Assists</TableCell>

                                        <TableCell className={GetStatisticClassName(index, playerSummary, 3)}>{fixtureStats.goals_assisted}</TableCell>

                                    </TableRow>
                                    
                                    <TableRow>

                                        <TableCell>Shots on Target</TableCell>

                                        <TableCell className={GetStatisticClassName(index, playerSummary, 4)}>{fixtureStats.shots_on_target}</TableCell>

                                    </TableRow>

                                    <TableRow>

                                        <TableCell>Fouls</TableCell>

                                        <TableCell className={GetStatisticClassName(index, playerSummary, 1)}>{fixtureStats.foul_count}</TableCell>

                                    </TableRow>

                                    <TableRow>

                                        <TableCell>Tackles</TableCell>

                                        <TableCell className={GetStatisticClassName(index, playerSummary, 6)}>{fixtureStats.tackles}</TableCell>

                                    </TableRow>

                                    <TableRow>

                                        <TableCell>Yellow Card</TableCell>

                                        {

                                            fixtureStats.yellow_cards > 0 ?

                                                <TableCell className={GetStatisticClassName(index, playerSummary, 5)}><BsFillCheckSquareFill color="green" /></TableCell> :

                                                <TableCell className={GetStatisticClassName(index, playerSummary, 5)}><ImCross /></TableCell>


                                        }

                                    </TableRow>

                                </TableBody>

                            </Table>

                        </TableContainer>

                    </MDBCol>

                </MDBRow>

            </React.Fragment>

            }
        >
            <img style={{ cursor: 'pointer', width: '30px', height: '30px', display: showOpponents ? '' : 'none' }} 
                                                                
                src={fixtureStats.player_team_id === fixtureStats.home_team_id ? 
                    
                    fixtureStats.away_team_logo : fixtureStats.home_team_logo} 
                        
            />

        </HtmlTooltip>

    )

}