import { CircularProgress, InputLabel, Paper } from "@material-ui/core";
import { Box, FormControl, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useRef } from "react";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { useTeamStatistics } from "../teams/teamHook";

const StyledTableCell = styled(TableCell)(({ theme }) => ({

    [`&.${tableCellClasses.head}`]: {

    // backgroundColor: '#344368',

    // color: theme.palette.common.white

    },

    [`&.${tableCellClasses.body}`]: {

      fontSize: 14,

      width: '100px'

    },

  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({

    '&:nth-of-type(odd)': {

      backgroundColor: '#0d1e3312'

    },

    // hide last border

    '&:last-child td, &:last-child th': {

      border: 0

    },

}));

export function TeamStatisicsGeneralStatsitics(props)
{
    const { competitions, team_id } = props;

    const [statisticResponse, setStatistics] = React.useState({ isLoading: false, data: null, team_id: team_id, season_id: competitions[0].season_id });

    useTeamStatistics(setStatistics, team_id, statisticResponse.season_id);

    const componentMounted = useRef(true);

    const [isVisible, setIsVisible] = React.useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    const handleSelectedCompetitionChange = (event) => {
        const newSeasonId = event.target.value;
        setStatistics(prevState => ({
            ...prevState,
            season_id: newSeasonId
        }));
    };

    return statisticResponse == null || statisticResponse.data == null || statisticResponse.isLoading || team_id !== statisticResponse.team_id ? 

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>

        <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>

            <CircularProgress sx={{marginLeft: 'auto', marginRight: 'auto', display: 'block' }}  />

        </Box>

    </div> :  (

            <MDBRow className={`widget_fade_in ${isVisible ? 'fade-in' : ''}`}>

                <MDBCol className="no_side_padding">

                    <MDBCol className="no_side_padding pt-2" size='12'>

                        <label className="section_header no_background">General Statistics</label>

                    </MDBCol>

                    <MDBCol className="pt-3">

                        <FormControl sx={{ m: 1 }} size="small">

                            <InputLabel id="demo-select-small-label"></InputLabel>

                        { 

                            competitions == null || competitions == null || competitions.length < 1 ? <></> :
                        
                            <div style={{ display: 'inline-flex', alignItems: 'center' }}>

                                <Select

                                    id="team_dashboard_competitions"

                                    value={statisticResponse.season_id === -1 ? competitions[0].season_id : (statisticResponse.season_id || '')}
                                    
                                    label="Competitions"

                                    onChange={handleSelectedCompetitionChange}

                                    sx={{

                                        flex: '1', // Make the width flexible

                                        borderRadius: 2,

                                        backgroundColor: '#f5f5f5', // Light grey background

                                        border: 'none', // Remove border

                                        display: 'flex',

                                        alignItems: 'center', // Vertically align items

                                        "& .MuiSelect-select": {

                                            padding: '10px', // Adjust padding as needed

                                            paddingRight: '30px', // Add space for the arrow icon

                                            display: 'flex',

                                            alignItems: 'center', // Vertically align items

                                        },

                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },

                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {

                                            border: 0,

                                        },

                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {

                                            border: 0,

                                        },

                                    }}

                                >
                                    {competitions.map((competition, index) => (

                                        <MenuItem key={"statistics_season_" + competition.season_id} value={competition.season_id}>
                                            
                                            <img style={{ width: '20px', height: '20px', marginRight: '10px' }} src={competition.league_logo} alt={competition.league_name} />
                                            
                                            <div style={{paddingLeft:'5px', paddingRight: '25px'}}>{competition.league_name}</div>
                                        
                                        </MenuItem>

                                    ))}

                                </Select>

                            </div>


                        }

                        </FormControl>

                    </MDBCol>

                    <TableContainer className="pb-2" component={Paper}>

                        <Table size="small" aria-label="simple table">

                            <TableHead>

                                <TableRow>

                                    <TableCell width={'100px'} align="center"></TableCell>

                                    <TableCell width={'100px'} align="center"></TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Average possession

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.ball_possession?.average}%</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Total attacks

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.attacks?.count}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Attacks per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.attacks?.average}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Dangerous attacks per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.dangerous_attacks?.average}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Total dangerous attacks

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.dangerous_attacks?.count}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Total shots

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.total}</span>

                                </StyledTableCell>

                            </StyledTableRow>
                            
                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Shots per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.average}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Shots on target

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.on_target}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Shots off target

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.off_target}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Shots blocked

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.blocked}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Shots inside box

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.inside_box}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    
                                    Shots outside box

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.shots?.outside_box}</span>

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Total corners

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.corner?.count}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Corners per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.corner?.average}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Total fouls

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.fouls?.count}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Fouls per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.fouls?.average}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Total offsides

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.offsides?.count}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Offsides per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.offsides?.average}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Total tackles

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.tackles?.count}</span> 

                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow 

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <StyledTableCell component="th" scope="row">
                                    
                                    Tackles per game

                                </StyledTableCell>

                                <StyledTableCell style={{fontWeight:500}} align="right" component="th" scope="row">
                                    
                                    <span>{statisticResponse.data.tackles?.average}</span> 
                                    
                                </StyledTableCell>

                            </StyledTableRow>

                        </TableBody>

                    </Table>

                </TableContainer>

            </MDBCol>

        </MDBRow>

    )

}