import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
//import { useTodaysFixturePlayerStatistics } from "../../../../hooks/StatManMasterAPI/fixtureHook";

import '../dashboards/css/player_insights.css';
import NoWidgetContent from "../shared/widget/no_widget_content";
import hour_glass_gif from '../../images/hour_glass.gif';
import LiveFixtureStatistics from "./live_fixture_statistics";
import ErrorBoundary from "../error/error_boundary";
import LiveInsightsPlayerTable from "./live_insights_player_table";
import LiveStatisticsScore from "./live_statistics_score";

export default function LiveInsights(props) {

    const { fixture_id, current_league_id, contentSetter, isMobile, site_date } = props;

    const [time, setTime] = useState(Date.now());

    var statisticsResponse = null;
    
    //useTodaysFixturePlayerStatistics(time, current_league_id, site_date);

    useEffect(() => {

        const interval = setInterval(() =>

            setTime(Date.now())

        , 10000);

        return () => {
    
            clearInterval(interval);

        };

    }, [time]);

    const handleFixtureChange = (event) => {

        contentSetter(isMobile ? 13 : 7, event.target.value === "0" ? undefined : event.target.value)

    };

    if(statisticsResponse === null || statisticsResponse === undefined ||
        
        (statisticsResponse !== null && !statisticsResponse.successful_request))
        
    {

        return <NoWidgetContent no_content_heading={"Loading Live Insights"} no_content_sub_heading={"Currently generating live insights, please wait..."} image_src={hour_glass_gif} />

    }

    if(statisticsResponse.data.length === 0) {

        return <NoWidgetContent no_content_heading={"No Live Insights"} no_content_sub_heading={"Please come back when todays scheduled fixtures have kicked off"} image_src={hour_glass_gif} additional_information={"Visit the player insights section above to view historical insights for players and teams playing today"} />

    }

    return (

        <React.Fragment>

            <Grid className="mt-1" container spacing={1}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    {

                        <FormControl className={'widget_content_selection ' + (isMobile ? "mobile_select" : "")} variant="filled">

                            <Select style={{textAlign: 'center'}}
                                
                                native={isMobile}

                                className='widget_selection_dropdown'

                                value={fixture_id === null ? 0 : fixture_id}

                                onChange={handleFixtureChange}

                            >

                                { isMobile ? <option value={0}>All Fixtures</option> : <MenuItem value={0}>All Fixtures</MenuItem>}

                                {

                                    statisticsResponse.data.length < 1 ?

                                    [] :

                                    statisticsResponse.data.map((statistics, index) => {

                                        return (isMobile ? <option key={"fixture_insight_menu_item_" + statistics.fixture_id} value={statistics.fixture_id}>{statistics.home_team.team_name} v {statistics.away_team.team_name}</option> :  <MenuItem key={"fixture_insight_menu_item_" + statistics.fixture_id} value={statistics.fixture_id}>
                                            
                                            <img style={{width: '25px', height: '25px', marginRight: '10px'}} src={statistics.home_team.team_logo} /> 
                                            
                                            {statistics.home_team.team_name} v {statistics.away_team.team_name}

                                            <img style={{width: '25px', height: '25px', marginLeft: '10px'}} src={statistics.away_team.team_logo} />
                                                
                                        </MenuItem>)

                                    })

                                }

                            </Select>

                        </FormControl>

                    }

                </Grid>

                {

                    fixture_id > 0 ? 

                    <Grid item xs={12} sm={12} md={12}>

                        <Grid spacing={1} container>

                            <Grid item xs={12} sm={12} md={6}>

                                <ErrorBoundary>

                                    <LiveStatisticsScore fixture_id={fixture_id} />

                                </ErrorBoundary>

                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>


                            </Grid>

                        </Grid>

                        <Grid spacing={1} container>

                            <Grid item xs={12} sm={12} md={12}>

                                <ErrorBoundary>

                                    <LiveFixtureStatistics fixture_id={fixture_id} isMobile={isMobile} />

                                </ErrorBoundary>

                            </Grid>

                        </Grid>

                    </Grid> : <></>

                }

                <Grid item xs={12} sm={12} md={12}>

                    <ErrorBoundary>

                        <LiveInsightsPlayerTable 
                        
                            fixture_id={fixture_id} 
                            
                            contentSetter={contentSetter} 
                            
                            current_league_id={current_league_id} 

                            apiResponse={statisticsResponse}

                            site_date={site_date}

                        />

                    </ErrorBoundary>

                </Grid>

            </Grid>

        </React.Fragment>

    )

}