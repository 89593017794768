import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Button, CircularProgress, Divider, Fade, LinearProgress, linearProgressClasses, Modal, Paper, Typography } from '@mui/material';
import '../../dashboards/css/lineup_statistic_drawer.css';
import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { BiFootball } from 'react-icons/bi';
import { GetGradientClass } from '../../../../common/helpers/rating_helper';
import StatManWhiteLogo from '../../../../images/stat-man-banner-logo-white.png';

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  padding: 0,
  backgroundColor: grey[100],
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
  }),
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  height: '80vh',
  overflowY: 'auto',
  borderRadius: '10px',
  padding: theme.spacing(0),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
}));

const PlayerStatisticModal = ({ modalState, setModalState }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalState.open) {
      setLoading(true);
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [modalState.open]);

  if (!modalState.player) return null;

  const { player } = modalState;

  const HandleClose = () => {
    setModalState({ open: false, player: null });
  }

  const renderStatisticsTables = () => {
    const stats = player.statistics_wrapper || {};
    return (
      <MDBRow className="drawer-statistics-table pb-3">
        <MDBCol className="stat-table-container no_side_padding">
          {stats.overall_statistics && <OverallStatistics player={player} />}
          {stats.goalkeeping_statistics && <GoalKeepingStatistics player={player} />}
          {stats.general_play_statistics && <GeneralPlayStatistics player={player} />}
          {stats.pass_statistics && <PassStatistics player={player} />}
          {stats.shot_statistics && <ShotStatistics player={player} />}
          {stats.duel_statistics && <DuelStatistics player={player} />}
        </MDBCol>
      </MDBRow>
    );
  };

  return (
    <Root>
      <CssBaseline />
      <Modal
        open={modalState.open}
        onClose={HandleClose}
        aria-labelledby="player-statistics-title"
        aria-describedby="player-statistics-description"
      >
        <ModalContent>
          {/* Scrollable Content */}
          <div
            style={{
              height: 'calc(80vh - 60px)',
              overflowY: 'auto'
            }}
          >
            <Fade in={modalState.open}>
              <div>
                <MDBCol style={{display: 'flex', justifyContent: 'flex-end', padding: '8px 12px 8px 12px' }}>
                  <img src={StatManWhiteLogo} style={{width: '180px', height: '38px'}} />
                </MDBCol>
                <Divider style={{opacity: '0.1'}} />
                {/* Player Information */}
                <MDBCol className="player-container" style={{ padding: '10px' }}>
                  <img
                    style={{ marginRight: '10px' }}
                    className="drawer-player-image"
                    src={player.playerImage}
                    alt={`Image of ${player.name}`}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="player-name">{player.name}</label>
                    <div style={{ position: 'relative' }}>
                      <img
                        className="drawer-team-logo"
                        src={player.team.logo}
                        alt={`${player.team.name} Logo`}
                      />
                      <span style={{ fontWeight: '400', fontSize: '14px' }}>
                        {player.team.name}
                      </span>
                    </div>
                  </div>
                  <span
                    className={`${GetGradientClass(player.rating)} player-rating`} style={{marginRight: 0}}
                  >
                    {player.rating}
                  </span>
                </MDBCol>

                {/* Loading or Statistics Tables */}
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  renderStatisticsTables()
                )}
              </div>
            </Fade>
          </div>

          {/* Fixed Footer */}
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              maxWidth: '600px',
              backgroundColor: 'white',
              boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.2)',
              padding: '10px',
              textAlign: 'right',
              zIndex: 1000
            }}
          >
            <Button
              onClick={HandleClose}
              style={{
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                padding: '10px 20px',
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              Close
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </Root>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));

function PassStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px 10px 10px 10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Passes</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Accurate Passes</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.pass_statistics.accurate_passes || 0}/{player.statistics_wrapper.pass_statistics.total_passes || 0}{" "}
              ({player.statistics_wrapper.pass_statistics.accurate_passes_percentage}%)
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Key Passes</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.pass_statistics.key_passes || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Long Balls</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.pass_statistics.long_balls || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{  display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Long Balls Won</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.pass_statistics.long_balls_won || 0}</span>
        </div>
      </div>
    </>
  );
}

function ShotStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px 10px 10px 10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Shots</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Total Shots</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.shot_statistics.total_shots}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Shots On Target</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.shot_statistics.shots_on_target || 0}/{player.statistics_wrapper.shot_statistics.total_shots || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Shots Off Target</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{(player.statistics_wrapper.shot_statistics.shots_off_target || 0) + (player.statistics_wrapper.shot_statistics.shots_blocked || 0)}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Shots Blocked</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.shot_statistics.shots_blocked || 0}</span>
        </div>
      </div>
    </>
  );
}

function OverallStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px 10px 10px 10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Overall</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Minutes Played</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.minutes_played || 0}'</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Touches</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.overall_statistics.touches || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Fouls</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.overall_statistics.fouls || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Fouls Drawn</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.overall_statistics.fouled || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Yellow Card</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.yellow_card || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Yellow / Red Card</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.yellow_red_card || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Red Card</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.red_card || 0}</span>
        </div>
      </div>
    </>
  );
}

function DuelStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Duels</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Total Duels</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.duel_statistics.total_duels || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Duels Won</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.duel_statistics.duels_won || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Duels Lost</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.duel_statistics.duels_lost || 0}</span>
        </div>
      </div>
    </>
  );
}

function GoalKeepingStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Goalkeeping</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Saves</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.saves || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Claims</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.come_outs || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Punches</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.punches || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Saves Inside Box</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.saves_inside_box || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Goals Conceded</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.goalkeeper_goals_conceded || 0}</span>
        </div>
      </div>
    </>
  );
}

function GeneralPlayStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>General Play</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Dribble Attempts</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.dribble_attempts || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Successful Dribbles</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.Successful_Dribbles || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Dribbled Past</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.dribbled_past || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Dispossessed</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.disposessed || 0}</span>
        </div>
      </div>
    </>
  );
}

const renderStatisticsTables = (player) => {
  return (
    <MDBRow className="drawer-statistics-table pb-3">
      <MDBCol className='stat-table-container no_side_padding' style={{ display: 'flex', flexDirection: 'column' }}>
        {player.statistics_wrapper.overall_statistics && <OverallStatistics player={player} />}
        {player.statistics_wrapper.goalkeeping_statistics && <GoalKeepingStatistics player={player} />}
        {player.statistics_wrapper.general_play_statistics && <GeneralPlayStatistics player={player} />}
        {player.statistics_wrapper.pass_statistics && <PassStatistics player={player} />}
        {player.statistics_wrapper.shot_statistics && <ShotStatistics player={player} />}
        {player.statistics_wrapper.duel_statistics && <DuelStatistics player={player} />}
      </MDBCol>
    </MDBRow>
  );
}

PlayerStatisticModal.propTypes = {
  modalState: PropTypes.shape({
    player: PropTypes.object,
    open: PropTypes.bool.isRequired,
  }).isRequired,
  setModalState: PropTypes.func.isRequired,
};

export default PlayerStatisticModal;
