import { Alert, AlertTitle, Box, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButtonGroup } from "@mui/material";
import { useStatPack } from "../../../application/statpack/statPackHook"
import { useState } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import '../../../application/dashboards/css/stat_pack.css';
import ScoringMinutes from "./stat_pack/scoring_minutes";
import Goals from "./stat_pack/goals";

export default function MobileFixtureStatPack({ fixture_id, contentSetter, teams }) {

    const [statPackState, setStatPackState] = useState({
        isLoading: true,
        data: null,
        fixture_id
    });

    const [IsHome, setIsHome] = useState(true);

    const [statisticOption, setStatisticOption] = useState('');

    const handleChange = (event) => {
        setStatisticOption(event.target.value);
    };

    useStatPack(fixture_id, setStatPackState);

    if (!statPackState.data ||
        statPackState.isLoading ||
        fixture_id !== statPackState.fixture_id) 
    {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </div>
        );
    }

    return (
        <>
            <Paper elevation={1} className="mb-2 widget_fade_in">
                <MDBRow>
                    <MDBCol className="pt-3 pb-3" size="12">
                        <FormControl fullWidth>
                            <InputLabel id="team-statistic-type-selection-label">Select</InputLabel>
                            <Select
                                labelId="team-statistic-type-selection-label"
                                id="team-statistic-type-selection"
                                value={statisticOption}
                                label="Option"
                                onChange={handleChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                            {
                                statPackState.data.team_statistic_options.map((item, index) => {
                                    return (
                                        <MenuItem key={"statistic_option_" + item.type_ID} value={item.type_ID}>{item.type_Name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    </MDBCol>
                </MDBRow>
            </Paper>

            {statisticOption !== "" && <Paper elevation={1} className="mb-2 widget_fade_in">
                <MDBRow>
                    <MDBCol className="pt-3 pb-3" size="12">
                        <div style={{display: 'flex'}}>
                            <div style={{
                                    width: '50%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    backgroundColor: IsHome ? '#1976d21f' : '#0000000a',
                                    borderRadius: '9px 0px 0px 9px',
                                    height: '40px',
                                    alignItems: 'center'
                                }}
                                onClick={() => setIsHome(true)}
                            >
                                <img style={{width: '28px', height: '28px'}} src={teams.home_team.logo} />
                            </div>
                            <div style={{
                                    width: '50%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    backgroundColor: IsHome ? '#0000000a' : '#1976d21f',
                                    borderRadius: '0px 9px 9px 0px',
                                    height: '40px',
                                    alignItems: 'center'
                                }}
                                onClick={() => setIsHome(false)}
                            >
                                <img style={{width: '28px', height: '28px'}} src={teams.away_team.logo} />
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            </Paper>}
            
            <Paper elevation={1} className="mb-2 widget_fade_in">
                <MDBRow>
                    <MDBCol className="pt-3 pb-3" size="12">
                        {
                            GetCorrectView(statisticOption, statPackState.data, teams, IsHome)
                        }
                    </MDBCol>
                </MDBRow>
            </Paper>
        </>
    )
}

function GetCorrectView(statisticOption, statPack, teams, IsHome) {
    switch(statisticOption) {
        case 52:
            {
                return <Goals
                    statPack={statPack} 
                    team_id={IsHome ? teams.home_team.id : teams.away_team.id} 
                />
            }
        case 27261:
            {
                var teamStatisticsGoalResultsDto = statPack.team_statistics_goal_results_dto;

                var teamStatisticsGoalResults = teamStatisticsGoalResultsDto.goal_results.find(stat => 
                    stat.team_id === (IsHome ? teams.home_team.id : teams.away_team.id)) || null;

                return <BuildGoalResultsTable 
                    teamStatisticsGoalResultsDto={teamStatisticsGoalResultsDto} 
                    teamStatisticsGoalResults={teamStatisticsGoalResults} 
                />
            }
        case 196:
        {
            return <ScoringMinutes statPack={statPack} team_id={IsHome ? teams.home_team.id : teams.away_team.id} />
        }
        default:
            {
                return <Alert size={12} severity="info" sx={{fontSize: '12px'}}>
                            <AlertTitle>Stat Packs</AlertTitle>
                                {`The Stat Pack provides key insights into ${teams.home_team.name} and 
                                ${teams.away_team.name}'s up to date performances in the current competition. Select a statistic to compare strengths, 
                                weaknesses, and trends, helping you understand each team's form going into this fixture.`}
                        </Alert>
            }
        
    }
}

const BuildGoalResultsTable = ({ teamStatisticsGoalResultsDto, teamStatisticsGoalResults }) => (
    <MDBCol size="12" className="no_side_padding">
        <label className="mb-3" style={{textAlign: 'center', width: '100%', fontWeight: '500'}}>{teamStatisticsGoalResultsDto.type_name}</label>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell >Over Goals</TableCell>
                        <TableCell style={{textAlign: 'center'}}>Match Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        teamStatisticsGoalResults && teamStatisticsGoalResults.goal_results.map((stat, index) => {
                            return (<TableRow
                                key={"goal_results_" + index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                
                            >
                                <TableCell component="th" scope="row">
                                    {stat.display_Name}
                                </TableCell>
                                <TableCell style={{textAlign: 'center'}} className={`stat-pack-value insight-${stat.value > 5 ? 'high' : stat.value}`}>{stat.value ?? 0}</TableCell>
                            </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </MDBCol>
);
