import { Box, CircularProgress, Paper, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, 
    ButtonGroup,
    IconButton} from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useRef, useState } from "react";
import errorImg from '../../../../images/opps_something_went_wrong.jpg';
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useFixtureStatPack } from "../../../../application/fixtures/fixtureHook";
import { useIsVisible } from "../../../../application/shared/hooks/isVisibleHook";
import { GoDotFill } from "react-icons/go";
import { GetOutcomeClass } from "../../../../common/helpers/rating_helper";
import { TbEyeSearch } from "react-icons/tb";
import { CiImageOn } from "react-icons/ci";
import statmanWhiteLogo from '../../../../images/stat-man-banner-logo-white.png';
import html2canvas from "html2canvas";
import FixtureStatPackHelmet from "../../../../application/helmets/fixture_stat_pack_helmet";

export default function MobileTeamStatPack({ contentSetter, isMobile, fixture_id }) {
    const componentMounted = useRef(true);
    const [IsHome, setIsHome] = useState(true);
    const [fixtureStatPackDashboardResponse, setFixtureStatPackDashboard] = useState({
        isLoading: true,
        data: null,
        fixture_id
    });

    var teams = [];

    const [isVisible, setIsVisible] = useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    useFixtureStatPack(fixture_id, setFixtureStatPackDashboard);

    if(!fixture_id && fixture_id < 1) {
        return (<Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">Select a fixture</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">Select a fixture from the live scores widget to view their stat pack</Typography>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if(fixtureStatPackDashboardResponse.data === null && !fixtureStatPackDashboardResponse.isLoading) {
        return (
        <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <img src={errorImg} alt='no available stat pack' style={{height: '300px', width: '275px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
                </MDBCol>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">An error occured</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">The fixture stat pack you where looking for could not be found</Typography>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if (!fixtureStatPackDashboardResponse.data ||
        fixtureStatPackDashboardResponse.isLoading ||
        fixture_id !== fixtureStatPackDashboardResponse.fixture_id) 
    {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </div>
        );
    }

    const teamPacks = fixtureStatPackDashboardResponse.data;

    const handleCapture = async () => {
        const element = document.getElementById("stat-pack-capture-area");
        try {
            const canvas = await html2canvas(element, {
                useCORS: true,
                scale: 2,
            });
            const dataUrl = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `${teamPacks.fixture_url_name}.png`;
            link.click();
        } catch (error) {
            console.error("Failed to capture image:", error);
        }
    };

    return (
        <>
            <FixtureStatPackHelmet fixture_description={teamPacks.fixture_description} 
                fixture_url={teamPacks.fixture_url_name}
                home_team_name={teamPacks.home_team_pack.team_name}
                away_team_name={teamPacks.away_team_pack.team_name} 
                fixture_id={fixture_id}
                league_logo={teamPacks.league_logo}
            />
            <div id="stat-pack-capture-area">
                <Paper elevation={1} className="mb-2 pt-1 pb-3 mobile-fixture-summary">
                    <MDBRow className="pb-2">
                        <MDBCol className="pb-2" style={{ paddingLeft: '20px' }} size="12">
                            <div style={{display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between', 
                                    width: '100%'
                                }}
                            >
                                <ButtonGroup
                                    variant="contained"
                                    disableElevation
                                    aria-label="team selection button group"
                                >
                                    <Link to={`/fixture/${fixture_id}/${teamPacks.fixture_url_name}`} style={{verticallyAlign: 'middle'}}>
                                        <IconButton variant="outlined" color="primary">
                                            <TbEyeSearch color="#121e32" />
                                        </IconButton>
                                    </Link>
                                
                                    <IconButton variant="outlined" onClick={() => handleCapture()} color="primary">
                                        <CiImageOn color="#121e32" />
                                    </IconButton>
                                </ButtonGroup>

                                <img src={statmanWhiteLogo} alt="The stat man logo" title="The stat man logo" style={{width: '160px', float: 'center' }} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                    {/* Header */}
                    <MDBRow className="mb-3" style={styles.header}>
                        <MDBCol size="12" style={styles.headerContent}>
                            <img
                                src={teamPacks.home_team_pack.team_logo}
                                alt={`${teamPacks.home_team_pack.team_name} Logo`}
                                style={{ width: 45, height:45, marginRight: 15 }}
                            />
                            <img
                                src={teamPacks.away_team_pack.team_logo}
                                alt={`${teamPacks.away_team_pack.team_name} Logo`}
                                style={{ width: 45, height:45, marginLeft: 15 }}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={styles.header}>
                        <MDBCol size="12" style={styles.headerContent}>
                            <div style={styles.teamNames}>
                                <span>{`${teamPacks.home_team_pack.team_name} - ${teamPacks.away_team_pack.team_name}`}</span>
                                <span style={styles.fixtureDescription}>{teamPacks.fixture_description}</span>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </Paper>
                <div style={{padding: '0px 8px 0px 8px'}}>
                <Paper elevation={1} className="mb-2 pt-2">
                    <MDBRow>
                        <MDBCol className="pt-1" size="12">
                            <div style={{ display: 'flex', borderRadius: '10px', border: '1px solid #e3e3e3' }}>
                                <div style={{
                                        width: '50%', 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        backgroundColor: IsHome ? '#1976d21f' : '#0000000a',
                                        borderRadius: '6px 0px 0px 6px',
                                        height: '40px',
                                        alignItems: 'center'
                                    }}
                                    onClick={() => setIsHome(true)}
                                >
                                    <img style={{width: '28px', height: '28px'}} src={teamPacks.home_team_pack.team_logo}alt={teamPacks.home_team_pack.team_name + " team logo"} />
                                </div>
                                <div style={{
                                        width: '50%', 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        backgroundColor: IsHome ? '#0000000a' : '#1976d21f',
                                        borderRadius: '0px 9px 9px 0px',
                                        height: '40px',
                                        alignItems: 'center'
                                    }}
                                    onClick={() => setIsHome(false)}
                                >
                                    <img style={{width: '28px', height: '28px'}} src={teamPacks.away_team_pack.team_logo} alt={teamPacks.away_team_pack.team_name + " team logo"} />
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="pt-3 pb-3">
                        <MatchStatsTable 
                            statpack={IsHome ? teamPacks.home_team_pack : teamPacks.away_team_pack} 
                            IsHome={IsHome} 
                        />
                    </MDBRow>
                </Paper>
                </div>
            </div>
        </>
    )
}

const StatRow = ({ title, data, average, hasAverage = true }) => (
    <TableRow>
        <StatCell data={data} />
        <TableCell style={{textAlign: 'center'}}>{hasAverage ? average : "-"}</TableCell>
        <TableCell style={styles.statTitle}>{title}</TableCell>
    </TableRow>
);
  
const StatCell = ({ data }) => (
    <TableCell style={styles.centerText}>
        <MDBRow style={styles.rowContainer}>
            {data.map((item, index) => (
                <div key={index} style={styles.cellItem}>
                    {item}
                </div>
            ))}
        </MDBRow>
    </TableCell>
);

const FormRow = ({ title, data, isHome = true }) => (
    <TableRow>
        <FormCell data={data} isHome={isHome} />
        <TableCell style={{textAlign: 'center'}}>-</TableCell>
        <TableCell style={styles.statTitle}>{title}</TableCell>
    </TableRow>
);

const FormCell = ({ data, isHome }) => {
    const displayedData = isHome ? data : [...data].reverse();
    return (
        <TableCell style={styles.centerText}>
            <MDBRow style={styles.rowContainer}>
                {displayedData.map((item, index) => (
                    <div key={"form-cell-" + index} style={styles.cellItem}>
                    <img src={item.opponent_logo} alt={`${item.opponent_name} Logo`} style={styles.logo} />
                    <GoDotFill className={GetOutcomeClass(item.outcome)} />
                    </div>
                ))}
            </MDBRow>
        </TableCell>
    );
  };

  const MatchStatsTable = ({ statpack, IsHome }) => {
    return (
      <>
        {/* Table */}
        <TableContainer className="no_side_padding">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Last 5 Games <FaArrowRight />
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <span>Season</span>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>Coverage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <FormRow
                    title="Recent Form"
                    data={statpack.form}
                    isHome={IsHome} 
                    hasAverage={false}
                />
                <StatRow
                    title="Goals For"
                    data={statpack.goals_for}
                    average={statpack.averages?.goals ?? 0}
                />
                <StatRow
                    title="Goals Against"
                    data={statpack.goals_against}
                    average={statpack.averages?.goals_conceded ?? 0}
                />
                <StatRow
                    title="Shots on Target"
                    data={statpack.shots_on_target}
                    average={(statpack.averages?.shots_on_target_pct ?? 0).toFixed(2)}
                />
                <StatRow
                    title="SOT Against"
                    data={statpack.shots_on_target_against}
                    average={'-'}
                />
                <StatRow
                    title="Cards For"
                    data={statpack.cards_for}
                    average={'-'}
                />
                <StatRow
                    title="Cards Against"
                    data={statpack.cards_against}
                    average={'-'}
                />
                <StatRow
                    title="Fouls"
                    data={statpack.fouls}
                    average={statpack.averages?.fouls ?? 0}
                />
                <StatRow
                    title="Fouls Won"
                    data={statpack.fouls_won}
                    average={'-'}
                />
                <StatRow
                    title="Corners"
                    data={statpack.corners}
                    average={statpack.averages?.corners ?? 0}
                />
                <StatRow
                    title="Corners Against"
                    data={statpack.corners_against}
                    average={'-'}
                />
            </TableBody>
          </Table>
        </TableContainer>
  
        {/* Footer */}
        {statpack.averages && (
          <>
            <Box display="flex" justifyContent="space-around" mt={2}>
                <Box textAlign="center">
                    <Typography variant="body1">BTTS</Typography>
                    <Typography variant="body2">
                        <strong>{statpack.averages.both_teams_to_score.toFixed(2)}%</strong>
                    </Typography>
                </Box>
                {["over_1_5", "over_2_5", "over_3_5"].map((key) => (
                    <Box key={key} textAlign="center">
                        <Typography variant="body1">{statpack.averages.goal_result_averages[`${key}_label`]}</Typography>
                        <Typography variant="body2">
                            <strong>{statpack.averages.goal_result_averages[`${key}_average`].toFixed(2)}%</strong>
                        </Typography>
                    </Box>
                ))}
            </Box>
          </>
        )}
      </>
    );
  };
  
  const styles = {
    header: { backgroundColor: "white" },
    headerContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      fontWeight: "500",
    },
    teamNames: { display: "flex", flexDirection: "column", textAlign: "center" },
    fixtureDescription: { fontSize: "12px" },
    centerText: { textAlgn: "center !important" },
    rowContainer: { display: "flex", justifyContent: "space-between", flexWrap: "nowrap" },
    cellItem: { padding: "5px", flex: "1 1 auto", textAlign: "center", fontSize:'16px' },
    logo: { width: 22, height: 22 },
    statTitle: { textAlign: "center", backgroundColor: "#f1f1f1", fontWeight: "500" },
  };
