import React from "react";
import { connect } from 'react-redux';
import * as ApplicationDataStore from '../../../../store/application-state';
import { ApplicationState } from '../../../../store/index';
import SwitchMobileDashboardContent from '../../../../application/dashboards/dashboard_system/mobile_dashboard_switcher';

import '../../../../application/dashboards/css/dashboard.css';

interface MobileDashboardLeagueProps {

    league_id: number;

};

type MobileDashboardProps = 

    ApplicationState

    & typeof ApplicationDataStore.actionCreators & 

    MobileDashboardLeagueProps;

class MobileDashboard extends React.PureComponent<MobileDashboardProps, ApplicationState> {

    constructor(props: MobileDashboardProps)
    {

        super(props);

    }

    render() {

        const props = this.props;

        const { mobile_dashboard_state, primary_dashboard_state, site_date } = props;

        return SwitchMobileDashboardContent(
    
            {

                content_setter: this.props.switchMobileDashboard,

                currentContentID: this.props.mobile_dashboard_state.selectedContentID,

                mobile_dashboard_type: mobile_dashboard_state.selectedDashboardType, 
                
                current_league_id: primary_dashboard_state.selectedLeagueID,

                site_date: this.props.site_date,

                switch_site_date: props.switchSiteDate

            }
        
        )

    }

}

export default connect(

    (state: ApplicationState) => state,

    ApplicationDataStore.actionCreators

)(MobileDashboard as any);