import React from "react";
import { connect } from 'react-redux';
import * as ApplicationDataStore from '../../../store/application-state';
import { ApplicationState } from '../../../store/index';
import SwitchDashboardContent from '../dashboard_system/dashboard_switcher';
import '../css/secondary_dashboard.css';

interface SecondaryDashboardLeagueProps {

    league_id: number;

};

type SecondaryDashboardProps = 

    ApplicationState // ... state we've requested from the Redux store

    & typeof ApplicationDataStore.actionCreators &  // ... plus incoming routing parameters

    SecondaryDashboardLeagueProps;

class SecondaryDashboard extends React.PureComponent<SecondaryDashboardProps, ApplicationState> {

    constructor(props: SecondaryDashboardProps)
    {

        super(props);

    }

    render() {

        const props = this.props;

        const { primary_dashboard_state, secondary_dashboard_state, content_dashboard_state, site_date } = props;

        return SwitchDashboardContent(
    
            {

                content_setter: props.switchContentDashboard,

                currentContentID: props.content_dashboard_state.selectedContentID,

                secondary_dashboard_type: secondary_dashboard_state.selectedDashboardType, 
                
                content_dashboard_type: content_dashboard_state.selectedDashboardType,

                current_league_id: primary_dashboard_state.selectedLeagueID,

                site_date: site_date,

                switch_site_date: props.switchSiteDate

            }
        
        )

    }

}

export default connect(

    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props

    ApplicationDataStore.actionCreators // Selects which action creators are merged into the component's props

)(SecondaryDashboard as any);