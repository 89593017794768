import React from "react";
import FeaturedFixture from "../../fixtures/featured_fixture";
import InformPlayers from "../../general_statistics/inform_players";
import TopPlayers from "../../general_statistics/player_statistics_widget";
import Grid from '@mui/material/Grid';
import ErrorBoundary from "../../error/error_boundary";
import { Alert, AlertTitle, Divider } from "@mui/material";
import NewsFeed from "../../home/news_feed";
import EventsCommentary3 from "../../home/event_commentary3";
export default function HomeDashboard(props) {

    const { contentSetter, isMobile, site_date } = props;

    return (

        <React.Fragment>

            <Grid container spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={1}>

                    <Grid style={{paddingBottom: '10px'}} item xs={12} sm={12} md={12} lg={12} xl={12} key={3}>

                        {/* <NewsFeed /> */}

                        {/* <ErrorBoundary>

                            <FeaturedFixture fixture_id={18535173} isMobile={isMobile} contentSetter={contentSetter} />

                        </ErrorBoundary> */}

                    </Grid>
                    {/*<Grid style={{paddingBottom: '10px'}} item xs={12} sm={12} md={12} key={4}> */}

                        {/* <ErrorBoundary> */}
                            
                            {/* <TopPlayers isMobile={isMobile} contentSetter={contentSetter} /> */}

                            {/* <InformPlayers widgetDescription={"Most Minutes Played"} propertyDescription={""} orderProperty={"Total_Minutes_Played"} isMobile={isMobile} contentSetter={contentSetter} /> */}

                        {/* </ErrorBoundary> */}

                    {/* </Grid> */}
                    
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={2}>

                    <Grid style={{paddingBottom: '0px'}} item xs={6} sm={6} md={6} key={5}>

                        {/* <EventsCommentary3 isMobile={isMobile} contentSetter={contentSetter} site_date={site_date} /> */}
                        
                        {/* <EventsCommentary2 isMobile={isMobile} contentSetter={contentSetter} site_date={site_date} /> */}

                        {/* <Alert variant="filled" style={{ height: '455px', backgroundColor: '#0D1E33', color: 'white', fontWeight: '300'}} className="mb-2" severity="info">

                            <AlertTitle>Welcome to The Stat Man</AlertTitle>

                            <Divider className="mb-3 mt-3" />

                            <React.Fragment>

                                <p>
                                    
                                    Your go-to destination for live football statistics and fixture insights! Our website is 
                                    
                                    dedicated to providing you with the most up-to-date and accurate information on all the latest football matches 
                                    
                                    across 27 different competitions and rising.
                                    
                                </p>

                                <p>

                                    Features include real-time updates on scores, in-game statistics from <strong>shots on target</strong>, <strong>fouls</strong>, <strong>player ratings</strong>, and much much more. With our cutting-edge data analysis, 
                                    
                                    you can stay ahead of the game and gain a competitive edge by tracking the performance of your favorite players and teams. 
                                    
                                </p>

                                <p>

                                    Whether you're a casual fan or a seasoned football expert, or just enjoy a small bet builder, 
                                    
                                    The Stat Man has everything you need to stay on top of the action and make informed decisions about your betting strategies. 

                                </p>
                                    
                            </React.Fragment>

                        </Alert> */}

                    </Grid>

                    <Grid style={{paddingBottom: '10px'}} item xs={6} sm={6} md={6} key={6}>

                        {/* <EventsCommentary isMobile={isMobile} contentSetter={contentSetter} /> */}

                        <ErrorBoundary>
                        <></>
                            {/* <FeaturedFixture fixture_id={18535173} isMobile={isMobile} contentSetter={contentSetter} /> */}

                        </ErrorBoundary>

                    </Grid>
                    
                </Grid>

            </Grid>

        </React.Fragment>

    )

}