import { Action, Reducer } from 'redux';
import { imobile_dashboard_state, iprimary_dashboard_menu_item, primary_dashboard_menu, iselected_mobile_dashboard_type, iselected_secondary_dashboard_type, iprimary_dashboard_state, iselected_content_dashboard_type, isecondary_dashboard_state, icontent_dashboard_state } from './dashboard_interfaces';

// SITE DATE 

interface ReceiveSiteDate {

    type: 'RECEIVE_SITE_DATE';

    site_date?: string | null | undefined;

}

interface RequestSiteDate {

    type: 'REQUEST_SITE_DATE';

}

export type SiteDateSwitchAction = ReceiveSiteDate | RequestSiteDate;

// PRIMARY DASHBOARD TYPES

interface ReceivePrimaryDashboard {

    type: 'RECIEVE_PRIMARY_DASHBOARD';

    selectedLeagueID?: number | null;

    menu_items: iprimary_dashboard_menu_item[];

}

interface RequestPrimaryDashboard {

    type: 'REQUEST_PRIMARY_DASHBOARD';

}

export type PrimaryDashboardSwitchAction = ReceivePrimaryDashboard | RequestPrimaryDashboard;

// SECONDARY DASHBOARD TYPES

interface ReceiveSecondaryDashboard {

    type: 'RECEIVE_SELECTED_SECONDARY_DASHBOARD';

    selectedDashboardType: iselected_secondary_dashboard_type | null;

    selectedLeagueID?: number | null;

}

interface RequestSecondaryDashboard {

    type: 'REQUEST_SELECTED_SECONDARY_DASHBOARD';

}

export type SecondaryDashboardSwitchAction = ReceiveSecondaryDashboard | RequestSecondaryDashboard;

// CONTENT DASHBOARD TYPES

interface RequestContentDashboard {

    type: 'REQUEST_SELECTED_CONTENT_DASHBOARD';

}

interface ReceiveContentDashboard {

    type: 'RECEIVE_SELECTED_CONTENT_DASHBOARD';

    selectedDashboardType: iselected_content_dashboard_type | null;

    selectedContentID?: number | null;

}

export type ContentDashboardSwitchAction = RequestContentDashboard | ReceiveContentDashboard;

// MOBILE DASHBOARD TYPES

interface ReturnMobileDashboard {

    type: 'REQUEST_SELECTED_MOBILE_DASHBOARD';

}

interface ReceiveMobileDashboard {

    type: 'RECEIVE_SELECTED_MOBILE_DASHBOARD';

    selectedDashboardType: iselected_mobile_dashboard_type | null;

    selectedContentID?: number | null;

}

export type MobileDashboardSwitchAction = ReturnMobileDashboard | ReceiveMobileDashboard;

// ------------------------------
// ---DASHBOARD SELECTED STATE---
// ------------------------------

const unloadedPrimaryDashboardState: iprimary_dashboard_state = {

    selectedLeagueID: null,

    menu_items: primary_dashboard_menu.menu_items

}

export const PrimaryDashboardSwitchReducer: Reducer<iprimary_dashboard_state> = (state: iprimary_dashboard_state | undefined, incomingAction: Action): iprimary_dashboard_state => { 

    if (state == undefined || state.selectedLeagueID === undefined) {

        return unloadedPrimaryDashboardState;

    }

    const action = incomingAction as PrimaryDashboardSwitchAction;
    
    switch (action.type) {

        case 'RECIEVE_PRIMARY_DASHBOARD':
            {

                return action as iprimary_dashboard_state;

            }
            
        case 'REQUEST_PRIMARY_DASHBOARD':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
        {

             return state as iprimary_dashboard_state;

        }
        default:
        {

            return state;

        }

    }

};


const unloadedSecondaryDashboardState: isecondary_dashboard_state = {

    selectedDashboardType: iselected_secondary_dashboard_type.Home

}

export const SecondaryDashboardSwitchReducer: Reducer<isecondary_dashboard_state> = (state: isecondary_dashboard_state | undefined, incomingAction: Action): isecondary_dashboard_state => { 

    if (state == undefined || state.selectedDashboardType === undefined) {

        return unloadedSecondaryDashboardState;

    }

    const action = incomingAction as SecondaryDashboardSwitchAction;

    switch (action.type) {

        case 'RECEIVE_SELECTED_SECONDARY_DASHBOARD':
            {

                return action as isecondary_dashboard_state;

            }
            
        case 'REQUEST_SELECTED_SECONDARY_DASHBOARD':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
        {

            return state as isecondary_dashboard_state;

        }
        default:
        {

            return state;

        }

    }

};

const unloadedContentDashboardState: icontent_dashboard_state = {

    selectedDashboardType: null,

    selectedContentID: null

}

export const ContentDashboardSwitchReducer: Reducer<icontent_dashboard_state> = (state: icontent_dashboard_state | undefined, incomingAction: Action): icontent_dashboard_state => { 


    if (state == undefined || state.selectedDashboardType === undefined) {

        return unloadedContentDashboardState;

    }

    const action = incomingAction as ContentDashboardSwitchAction;

    switch (action.type) {

        case 'RECEIVE_SELECTED_CONTENT_DASHBOARD':
            {

                return action as icontent_dashboard_state;

            }
            
        case 'REQUEST_SELECTED_CONTENT_DASHBOARD':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
        {

             return state as icontent_dashboard_state;

        }
        default:
        {

            return state as icontent_dashboard_state;;

        }

    }

};

// MOBILE DASHBOARD REDUCER

const unloadedMobileDashboardState: imobile_dashboard_state = {

    selectedDashboardType: iselected_mobile_dashboard_type.Home,

    selectedContentID: null

}


export const MobileDashboardSwitchReducer: Reducer<imobile_dashboard_state> = (state: imobile_dashboard_state | undefined, incomingAction: Action): imobile_dashboard_state => { 

    if (state == undefined || state.selectedDashboardType === undefined || state.selectedDashboardType === null) {

        return unloadedMobileDashboardState;

    }

    const action = incomingAction as MobileDashboardSwitchAction;
    
    switch (action.type) {

        case 'RECEIVE_SELECTED_MOBILE_DASHBOARD':
            {

                return action as imobile_dashboard_state;

            }
            
        case 'REQUEST_SELECTED_MOBILE_DASHBOARD':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
        {

             return state as imobile_dashboard_state;

        }
        default:
        {

            return state;

        }

    }

};

export const SetSiteDateReducer: Reducer<string | null | undefined> = (state: string | null | undefined, incomingAction: Action): string | null | undefined => { 

    if (state == undefined) {

        return '';

    }

    const action = incomingAction as SiteDateSwitchAction;

    switch (action.type) {

        case 'RECEIVE_SITE_DATE':
            {

                return action.site_date;

            }
            
        case 'REQUEST_SITE_DATE':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
        {

             return state as string | null | undefined;

        }
        default:
        {

            return state;

        }

    }

};