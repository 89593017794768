import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { useCommentary } from '../fixtures/fixtureHook';
import { useEventsCommentarySubscription } from '../infrastructure/subscriptions/eventsSubscriptionHook';
import { useIsVisible } from '../shared/hooks/isVisibleHook';
import { Paper, makeStyles } from '@material-ui/core';
import { GiWhistle } from "react-icons/gi";
import { IoIosFootball } from "react-icons/io";
import { FiTarget } from "react-icons/fi";

const useStyles = makeStyles((theme) => ({

  paper: {

    padding: theme.spacing(2)

  }
  
}));

export default function EventsCommentary3(props) {

  const { isMobile, contentSetter, site_date } = props;

  const [eventsResponse, setTodaysEvents] = React.useState(null);

  const [isVisible, setIsVisible] = React.useState(false);

  const componentMounted = React.useRef(true);

  const classes = useStyles();

  useIsVisible(isVisible, setIsVisible, componentMounted); 

  useCommentary(setTodaysEvents);

  useEventsCommentarySubscription(site_date, setTodaysEvents);

  return (

    eventsResponse === null || eventsResponse.length < 1 ? <></> :

    <Paper style={{maxHeight: '750px', overflow: 'auto'}} className={`dashboard_widget commentary_widget widget-scroller widget_fade_in ${isVisible ? 'fade-in' : ''} ${classes.paper}`}>

      <Timeline sx={{

        [`& .${timelineItemClasses.root}:before`]: {

          flex: 0,

          padding: 0,

        },

      }}>

        {

            eventsResponse.map((event, index) => (

              <TimelineItem key={event.id}>

                <TimelineSeparator>

                  <TimelineConnector />

                  <TimelineDot color='grey'>

                    <img src={event.team_logo} style={{width:'30px', height:'30px'}} />

                  </TimelineDot>

                  <TimelineConnector />

                  </TimelineSeparator>

                  <TimelineContent sx={{ py: '12px', px: 2 }}>

                  {GetEventType(event)}

                </TimelineContent>

              </TimelineItem>

            ))

        }

      </Timeline>

    </Paper>

  )

}

function GetEventType(event, classes)
{

    switch(event.event_type)
    {
        default:
            {

                return <></>

            }
            case "substitution":
                {

                    return (

                        <>
                            
                          <Typography variant="h6" style={{fontWeight: '500'}}>
  
                              {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' SUBSTITUTION!
  
                          </Typography>
  
                          <Typography variant="subtitle2">
  
                              <span className="sub_in">IN: {event.player_name}</span>
  
                          </Typography>
  
                          <Typography variant="subtitle2">
  
                              <span className="sub_out">OUT: {event.assisting_player_name}</span>
  
                          </Typography>
                                
                        </>
        
                    )

                }
            case "shot_on_target":
                {

                    return (

                        <>
                            
                          <Typography variant="h6" style={{fontWeight: '500'}}>
  
                              {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' SHOT ON TARGET!
  
                          </Typography>
  
                          <Typography variant="subtitle1" style={{fontWeight: '500'}}>
  
                            <FiTarget className='event_icon' /> <span>{event.player_name}</span>
  
                          </Typography>
  
                          <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
  
                              {`${event.player_name} with a shot on target`}
  
                          </Typography>
                                
                        </>
        
                    )

                }
            case "missed_penalty":
                {

                    return (

                        <>
                            
                          <Typography variant="h6" style={{fontWeight: '500'}}>
  
                              {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' MISSED!
  
                          </Typography>
  
                          <Typography variant="subtitle1" style={{fontWeight: '500'}}>
  
                              <IoIosFootball color='red' className='event_icon' /> <span>{event.result} {event.player_name}</span>
  
                          </Typography>
  
                          <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
  
                              {`${event.player_name} slots home the penalty. ${event.result} ${event.is_home ? event.home_team_name : event.away_team_name}`}
  
                          </Typography>
                                
                        </>
        
                    )

                }
            case "penalty":
            {

                return (

                <>
                    
                  <Typography variant="h6" style={{fontWeight: '500'}}>

                      {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' PENALTY!

                  </Typography>

                  <Typography variant="subtitle1" style={{fontWeight: '500'}}>

                    <IoIosFootball className='event_icon' /> <span>{event.result} {event.player_name}</span>

                  </Typography>

                  {event.assisting_player_name && (

                  <Typography className='pb-1' variant="caption" color="textSecondary">

                      Assist: {event.assisting_player_name}

                  </Typography>

                  )}

                  <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">

                      {`${event.player_name} slots home the penalty. ${event.result} ${event.is_home ? event.home_team_name : event.away_team_name}`}

                  </Typography>

                  <Typography className='mt-2' style={{ color:'black', maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
                      
                      {`${event.home_team_name} ${event.result} ${event.away_team_name}`}

                  </Typography>
                        
                </>

            )

        }
        case "var_card":
            {

                return (

                    <>
                        
                      <Typography variant="h6" style={{fontWeight: '500', display: 'flex'}}>
    
                        {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' VAR BOOKING!
    
                      </Typography>
    
                      <Typography variant="subtitle1" style={{fontWeight: '500', display: 'flex'}}>
    
                          {event.event_type == "yellowcard" ? <div className="yellow_card"></div> : <div className="red_card"></div>} <span style={{marginLeft: '5px' }}>{event.result} {event.player_name}</span>
    
                      </Typography>
    
                      <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
    
                          {`${event.player_name} is shown a ${event.event_type == "yellowcard" ? "yellow card" : "red card"} ${event.description == null ? "" : "for " + GetCardReason(event.description.toLowerCase())}`}
    
                      </Typography>
                            
                    </>
    
                )

            }
        case "redcard":
        case "redyellowcard":
        case "yellowcard":
            {

                return (

                <>
                    
                  <Typography variant="h6" style={{fontWeight: '500', display: 'flex'}}>

                  {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' BOOKING!

                  </Typography>

                  <Typography variant="subtitle1" style={{fontWeight: '500', display: 'flex'}}>

                      {event.event_type == "yellowcard" ? <div className="yellow_card"></div> : <div className="red_card"></div>} <span style={{marginLeft: '5px' }}>{event.result} {event.player_name}</span>

                  </Typography>

                  <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">

                      {`${event.player_name} is shown a ${event.event_type == "yellowcard" ? "yellow card" : "red card"} ${event.description == null ? "" : "for " + GetCardReason(event.description.toLowerCase())}`}

                  </Typography>
                        
                </>

            )

        }
        case "goal":
            {

                return (

                <>
                    
                  <Typography variant="h6" style={{fontWeight: '500'}}>

                    {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' GOAL!

                  </Typography>

                  <Typography variant="subtitle1" style={{fontWeight: '500'}}>

                    <IoIosFootball className='event_icon' />  <span>{event.result} {event.player_name}</span>

                  </Typography>

                  {event.assisting_player_name && (

                      <Typography className='pb-1' variant="caption" color="textSecondary">

                          Assist: {event.assisting_player_name}

                      </Typography>

                  )}

                  <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
                  
                      {GetCommentary(event)}

                  </Typography>

                  <Typography className='mt-2' style={{ color:'black', maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
                      
                      {`${event.home_team_name} ${event.result} ${event.away_team_name}`}

                  </Typography>
                        
                </>

            )

        }
        case "owngoal":
            {

                return (

                <>
                    
                  <Typography variant="h6" style={{fontWeight: '500'}}>

                    {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' GOAL!

                  </Typography>

                  <Typography variant="subtitle1" style={{fontWeight: '500'}}>

                    <IoIosFootball color='red' className='event_icon' />  <span>{event.result} {event.player_name}</span>

                  </Typography>

                  {event.assisting_player_name && (

                      <Typography className='pb-1' variant="caption" color="textSecondary">

                          Assist: {event.assisting_player_name}

                      </Typography>

                  )}

                  <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
                  
                      {`${event.player_name} puts the ball in the back of his own net! ${event.result}!`}

                  </Typography>

                  <Typography className='mt-2' style={{ color:'black', maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
                      
                      {`${event.home_team_name} ${event.result} ${event.away_team_name}`}

                  </Typography>
                        
                </>

            )

        }
        case "VAR":
            {

                return (

                <>
                     
                  <Typography variant="h6" style={{fontWeight: '500'}}>

                      <GiWhistle /> {event.minute + (event.extra_minute == null ? 0 : parseInt(event.extra_minute))}' VAR!

                  </Typography>

                  <Typography variant="subtitle1" style={{fontWeight: '500'}}>

                      <span>{event.result} {event.player_name}</span>

                  </Typography>

                  {event.assisting_player_name && (

                      <Typography className='pb-1' variant="caption" color="textSecondary">

                          Assist: {event.assisting_player_name}

                      </Typography>

                  )}

                  <Typography style={{ maxWidth: '270px', fontSize: '12px' }} variant="subtitle2" color="textSecondary">
                  
                      {`${event.additional_information} for ${event.is_home ? event.home_team_name : event.away_team_name}`}

                  </Typography>

                </>

            )

        }

    }

}

function GetCardReason(reason) {

  switch(reason){

      case "foul":
          {

              return "fouling";

          }
      case "argument":
          {

              return "dissent";

          }
      default:
          {

              return reason;

          }

  }

}

function GetCommentary(event) {

  var results = event.result == null ? ['0', '0'] : event.result.split('-');

  var homeTeamScore = parseInt(results[0]);

  var awayTeamScore = parseInt(results[1]);

  var teamName = event.isHome ? event.home_team_name : event.away_team_name;

  var opponentName = event.isHome ? event.away_team_name : event.home_team_name;

  var leadIncreased = false;
  
  if(homeTeamScore > awayTeamScore || awayTeamScore > homeTeamScore) {

      if(homeTeamScore > awayTeamScore) {

          leadIncreased = (homeTeamScore - awayTeamScore) > 1;
          
      } 
      else {

          leadIncreased = (homeTeamScore - awayTeamScore) > 1;
          
      }

  } 

  var goalCommentary = "";

  if(event.player_name == null) {

      return "";

  }

  switch(event.description)
  {

      default: 
      {

          var playersTeam = event.isHome ? event.home_team_name : event.away_team_name;

          return `${event.player_name} scores for ${playersTeam} to make the score ${event.result}.`;

      }
      case "Right foot shot":
          {

              if(homeTeamScore > awayTeamScore || awayTeamScore > homeTeamScore) {

                  if(leadIncreased) {

                      goalCommentary = `A lovely right foot finish from ${event.player_name}. ${teamName} increase their lead. ${event.result} ${event.home_team_name}!`;

                  } 
                  else {

                      goalCommentary = `A lovely right foot finish from ${event.player_name}. ${teamName} take the lead. ${event.result} ${event.home_team_name}!`;

                  }

              }
              else if(homeTeamScore == awayTeamScore) {

                  goalCommentary = `A lovely right foot finish from ${event.player_name} for ${teamName} to equalize. ${event.result} All Square!`;

              }
              else {

                  goalCommentary = `A lovely right foot finish from ${event.player_name}. ${teamName} pull a goal back. ${event.result} ${opponentName}!`;

              }
              
              return goalCommentary;

          }
      case "Left foot shot":
          {

              if(homeTeamScore > awayTeamScore || awayTeamScore > homeTeamScore) {

                  if(leadIncreased) {

                      goalCommentary = `A lovely left foot finish from ${event.player_name}. ${teamName} increase their lead. ${event.result} ${event.home_team_name}!`;

                      
                  } 
                  else {

                      goalCommentary = `A lovely left foot finish from ${event.player_name}. ${teamName} take the lead. ${event.result} ${event.home_team_name}!`;

                  }

              }
              else if(homeTeamScore == awayTeamScore) {

                  goalCommentary = `A lovely left foot finish from ${event.player_name} for ${teamName} to equalize. ${event.result} All Square!`;

              }
              else {

                  goalCommentary = `A lovely left foot finish from ${event.player_name}. ${teamName} pull a goal back. ${event.result} ${opponentName}!`;

              }
              
              return goalCommentary;

          }
          case "Header":
          {

              if(homeTeamScore > awayTeamScore || awayTeamScore > homeTeamScore) {

                  if(leadIncreased) {

                      goalCommentary = `A towering header from ${event.player_name}. ${teamName} increase their lead. ${event.result} ${event.home_team_name}!`;

                      
                  } 
                  else {

                      goalCommentary = `A towering header from ${event.player_name}. ${teamName} take the lead. ${event.result} ${event.home_team_name}!`;

                  }

              }
              else if(homeTeamScore == awayTeamScore) {

                  goalCommentary = `A towering header from ${event.player_name} for ${teamName} to equalize. ${event.result} All Square!`;

              }
              else {

                  goalCommentary = `A towering header from ${event.player_name}. ${teamName} pull a goal back. ${event.result} ${opponentName}!`;

              }
              
              return goalCommentary;

          }
      
  }

}