import { useState } from "react";
import { Button, TextField, IconButton, Box } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 0,
});

export function DateController(props) {
  const { set_date, selected_date } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (numOfDays) => {
    const currentDate = !selected_date || selected_date === ''
      ? new Date()
      : ConvertStateDate(selected_date); // Convert selected_date to Date object

    currentDate.setDate(currentDate.getDate() + numOfDays);
    set_date(currentDate.toLocaleDateString('en-GB')); // Format for setting the date
  };

  // Convert the selected_date to a Date object
  const formattedDate = selected_date ? ConvertStateDate(selected_date) : new Date();
  const displayDate = formattedDate.toLocaleDateString('en-GB'); // Format to dd/MM/yyyy

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <ButtonContainer>
          <IconButton onClick={() => handleDateChange(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Button sx={{color: '#373737 !important'}} onClick={() => setIsOpen(true)}>
            {displayDate} {/* Display formatted date */}
          </Button>
          <IconButton onClick={() => handleDateChange(1)}>
            <ChevronRightIcon />
          </IconButton>
        </ButtonContainer>
        <span style={{ display: 'none' }}>
          <DatePicker
            label="Site Date"
            value={formattedDate} // Pass the Date object directly
            inputFormat="dd/MM/yyyy"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={(newValue) => {
              if (newValue) {
                set_date(newValue.toLocaleDateString('en-GB'));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </span>
      </Container>
    </LocalizationProvider>
  );
}

// Helper function to convert the state date from dd/MM/yyyy format to Date object
function ConvertStateDate(stateDate) {
  if (!stateDate) return null; // Handle null or undefined input

  const dateParts = stateDate.split("/");
  // Create a new Date object. Month is 0-based, hence subtract 1 from month.
  return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
}
