import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from "@mui/material";
import { MDBCol } from "mdb-react-ui-kit";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { tableCellClasses } from '@mui/material/TableCell';
import { useStandings } from "../../../application/standings/standingsHook";

const selectStyling = {
  borderRadius: 2,
  backgroundColor: '#f5f5f5',
  border: 'none',
  "& .MuiSelect-select": {
    padding: '10px',
    paddingRight: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
};

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: 'black',
    fontWeight: 400,
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f9f9f9',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function MobileLeagueTable({ contentSetter, season_id, team_ids, isMobile }) {
  const [leagueStandingsResponse, setLeagueStandings] = useState({
    isLoading: false,
    data: null,
    season_id: season_id,
    round_id: -1,
  });

  const handleSelectedRoundChange = (event) => {
    const newRoundId = event.target.value;
    setLeagueStandings((prevState) => ({
      ...prevState,
      round_id: newRoundId,
    }));
  };

  useStandings(season_id, leagueStandingsResponse.round_id, setLeagueStandings);

  if (leagueStandingsResponse.isLoading || !leagueStandingsResponse.data) {
    return <LoadingSpinner />;
  }

  const stage = leagueStandingsResponse.season_id === -1
    ? leagueStandingsResponse.data[0]
    : leagueStandingsResponse.data.find((a) => a.season_id === leagueStandingsResponse.season_id);

  if (!stage) {
    return <LoadingSpinner />;
  }

  const currentRound = stage.rounds.find((round) =>
    leagueStandingsResponse.round_id === -1 ? round.is_current : round.id === leagueStandingsResponse.round_id
  );

  return (
    <Paper elevation={1} className={`mb-2 br-15 pb-3 widget_fade_in`}>
      <MDBCol>
        <div className="p-2 pb-2">
            <FormControl size="small">
                <Select
                    id="team_dashboard_competitions"
                    value={
                    leagueStandingsResponse.round_id === -1
                        ? stage?.rounds?.find((a) => a.is_current)?.id || -1
                        : leagueStandingsResponse.round_id
                    }
                    label="Competitions"
                    onChange={handleSelectedRoundChange}
                    sx={selectStyling}
                >
                    {
                        stage.rounds
                        .filter((round) => round.id <= stage.rounds.find((a) => a.is_current)?.id) // Filter rounds
                        .map((round) => (
                            <MenuItem key={`standing_round_${round.id}`} value={round.id}>
                            <div style={{ paddingLeft: '5px', paddingRight: '25px' }}>GW{round.name}</div>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </div>
        <Divider sx={{opacity: '0.1', marginBottom: 0 }} />
        <TableContainer className="pb-1">
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Team</StyledTableCell>
                <StyledTableCell align="center">P</StyledTableCell>
                <StyledTableCell align="center">W</StyledTableCell>
                <StyledTableCell align="center">D</StyledTableCell>
                <StyledTableCell align="center">L</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">PTS</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRound?.standings
                ?.sort((a, b) => a.position - b.position)
                .map((standing) => (
                  <StyledTableRow
                    key={`league_standings_team_${standing.participant_id}`}
                    style={{
                      background: team_ids.includes(standing.participant_id) ? "#2F4369" : "",
                    }}
                  >
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                      }}
                      align="center"
                    >
                      {standing.position}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                      }}
                    >
                      <img
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                          position: 'relative',
                          top: '-2px',
                        }}
                        src={standing.image_path}
                        alt={`${standing.team_name} logo`}
                      />
                      {standing.team_short_name || standing.team_name.toUpperCase().substring(0, 3)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                      }}
                      align="center"
                    >
                      {standing.details.find((a) => a.type_id === 129)?.value}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                      }}
                      align="center"
                    >
                      {standing.details.find((a) => a.type_id === 130)?.value}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                      }}
                      align="center"
                    >
                      {standing.details.find((a) => a.type_id === 131)?.value}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                      }}
                      align="center"
                    >
                      {standing.details.find((a) => a.type_id === 132)?.value}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                      }}
                      align="center"
                    >
                      {GetStandingResultIcon(standing.result)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: team_ids.includes(standing.participant_id) ? 'white' : '',
                      }}
                      align="center"
                    >
                      {standing.points}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{opacity: '0.1', marginBottom: 0 }} />
      </MDBCol>
    </Paper>
  );
}

// Loading Spinner Component
function LoadingSpinner() {
  return (
    <Box
      className="loading_spinner"
      sx={{
        height: '800px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

// Standing Result Icon Function
function GetStandingResultIcon(result) {
  switch (result) {
    case "equal":
      return "-";
    case "up":
      return <BiChevronUp size={25} color="green" />;
    case "down":
      return <BiChevronDown size={25} color="red" />;
    default:
      return null;
  }
}
