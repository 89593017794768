import { useState } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import '../../../../application/dashboards/css/fixture_dashboard.css';
import { useFixtureDashboard, useFixtureSchedule, useLineups } from '../../../../application/fixtures/fixtureHook';
import FixturePenalties from '../../../../application/fixtures/fixture_penalties';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { useLiveFixtureSubscription } from '../../../../application/infrastructure/subscriptions/fixtureSubscriptionHook';
import SwipeableViews from 'react-swipeable-views';
import FixtureSchedule from '../../../../application/fixtures/fixture_schedule';
import MobileFixtureDetails from '../../fixture/mobile_fixture_details';
import MobileFixtureLineups from '../../fixture/mobile_fixture_lineups';
import MobileFixtureEvents from '../../fixture/mobile_fixture_events';
import MobileFixturePitch from '../../fixture/mobile_fixture_pitch';
import MobileFixtureStatPack from '../../fixture/mobile_fixture_stat_pack';
import errorImg from '../../../../images/opps_something_went_wrong.jpg';
import { Link } from 'react-router-dom';
import FixtureHelmet from '../../../../application/helmets/fixture_helmet';
import MobileFixtureStatistics from '../../fixture/mobile_fixture_statistics';
import MobileFixtureCommentary from '../../fixture/mobile_fixture_commentary';
import FixtureTVStations from '../../../../application/fixtures/fixture_tv_stations';
import MobileLeagueTable from '../../fixture/mobile_league_table';
import MobileTopRatedPlayers from '../../fixture/mobile_top_rated_players';
import MobileFixtureHighlights from '../../fixture/mobile_fixture_highlights';
import FixtureFormGuide from '../../../../application/fixtures/fixture_form_guide';
import FixtureManOfTheMatch from '../../../../application/fixtures/fixture_man_of_the_match';

const FixtureDashboard = ({ fixture_id, contentSetter, isMobile, site_date }) => {
    const [fixtureDashboardResponse, setFixtureDashboard] = useState({
        isLoading: true,
        data: null,
        fixture_id,
        last_updated: null
    });

    const [schedule, setFixtureSchedule] = useState({ data: null, isLoading: true });

    const [activeStep, setActiveStep] = useState(0);
    const [index, setIndex] = useState(0);
    const [fixtureLineups, setFixtureLineups] = useState({ isLoading: true, data: null });

    useFixtureDashboard(fixture_id, setFixtureDashboard);
    useLiveFixtureSubscription(fixture_id, setFixtureDashboard);
    useLineups(fixture_id, setFixtureLineups);
    useFixtureSchedule(fixture_id, setFixtureSchedule);

    if(fixtureDashboardResponse.data === null && !fixtureDashboardResponse.isLoading) {
        return (
        <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <img src={errorImg} alt='no available fixture' style={{height: '280px', width: '265px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
                </MDBCol>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">An error occured</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">The fixture you where looking for could not be found</Typography>
                    <Link to={`/`}>
                        <Button className='mt-3' style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} onClick={() => contentSetter(1)} variant="contained" disableElevatio>Go Home</Button>
                    </Link>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if (!fixtureDashboardResponse.data ||
        fixtureDashboardResponse.isLoading ||
        fixture_id !== fixtureDashboardResponse.fixture_id) 
    {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </div>
        );
    }

    const 
    {
        data: 
        {
            events,
            periods,
            comments,
            league,
            fixture,
            period_statistics: periodStats,
            fixture_statistics: stats,
            last_updated
        },
    } = fixtureDashboardResponse;

    let teams = {
        home_team: fixture.home_team, 
        away_team: fixture.away_team,
    };

    const renderPenalties = (key) => {
        const penaltyPeriod = periods.find((period) => period.description === 'penalties');
        if (!penaltyPeriod) return null;

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <FixturePenalties
                    events={events}
                    period={penaltyPeriod}
                    teams={teams}
                />
            </MDBCol>
        );
    };

    const renderCommentary = (key) => {
        if (!tabPanels[2] || comments.length < 1) {
            return null;
        }
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <MobileFixtureCommentary comments={comments} />
            </MDBCol>
        );
    };

    const renderStatistics = (key) => {
        if (!tabPanels[3] || (periods.length < 1 && periodStats.length < 1)) {
            return null;
        }

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <MobileFixtureStatistics
                    periods={periods}
                    fixture_id={fixture_id}
                    fixture_statistics={stats}
                />
            </MDBCol>
        );
    };

    const renderTopPlayers = (key) => {
        return (
            fixtureLineups.isLoading ? 
            <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress sx={{ margin: 'auto' }} />
            </Paper> :

            fixtureLineups?.data && <MDBRow>
                <MobileTopRatedPlayers 
                    fixture_id={fixture_id}
                    teams={teams}
                    fixtureLineups={fixtureLineups}
                    setIndex={setIndex}
                    setActiveStep={setActiveStep}
                />
            </MDBRow>
        )
    }

    const renderLineups = (key) => {
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                {
                    fixtureLineups.isLoading ? 
                    <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <CircularProgress sx={{ margin: 'auto' }} />
                    </Paper> :

                    <MobileFixturePitch 
                        fixture_id={fixture_id}
                        teams={teams}
                        fixtureLineups={fixtureLineups}
                    />
                }

                {
                    fixtureLineups.isLoading ? 
                    <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <CircularProgress sx={{ margin: 'auto' }} />
                    </Paper> :

                    <MobileFixtureLineups
                        fixture_id={fixture_id}
                        last_updated={last_updated}
                        contentSetter={contentSetter}
                        fixtureLineups={fixtureLineups}
                        teams={teams}
                    />
                }
            </MDBCol>
        );
    };
    
    const renderFormGuide = () => {
        if (!tabPanels[0]) {
            return <></>
        }
        return (
            <MDBRow>
                <FixtureFormGuide 
                    fixture_id={fixture_id}
                    teams={teams}
                    contentSetter={contentSetter}
                />
            </MDBRow>
        )
    }

    const renderManOfTheMatch = () => {
        if (fixture.fixture_state.state !== "FT") {
            return <></>
        }
        return (
            <MDBRow>
                <FixtureManOfTheMatch 
                    fixture_id={fixture_id}
                />
            </MDBRow>
        )
    }

    const renderInfo = (key) => {
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                {renderManOfTheMatch()}
                <MDBRow>
                    {renderPenalties()}
                </MDBRow>
                <MobileFixtureHighlights fixtureId={fixture_id} fixtureName={fixture.name} />
                {renderTopPlayers()}
                {renderFormGuide()}
                {events.filter(event => event.section === "event").length > 0 && <MDBRow>
                    {renderEvents()}
                </MDBRow>}
                <MDBRow>
                    {renderTvStations()}
                </MDBRow>
                <MDBRow>
                    <FixtureSchedule fixture_id={fixture_id} teams={teams} schedule={schedule} />
                </MDBRow>
            </MDBCol>
        );
    };

    const renderEvents = () => {
        return (
            <MDBCol className="no_side_padding" size="12">
                <MobileFixtureEvents
                    events={events.filter(event => event.section === "event")}
                    periods={periods}
                    fixture={fixture}
                />
            </MDBCol>
        );
    };

    const renderTvStations = () => {
        return (
            <MDBCol className="no_side_padding" size="12">
                <FixtureTVStations fixtureId={fixture_id} countryId={league.country_id} />
            </MDBCol>
        )
    }

    const renderLeagueStandings = (key) => {
        if (!tabPanels[4]) {
            return null;
        }

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <MobileLeagueTable 
                    team_ids={[teams.home_team.id, teams.away_team.id]} 
                    season_id={fixture.season_id}
                    isMobile={isMobile}
                />
            </MDBCol>
        );
    };

    const renderStatPack = (key) => {
        if (!tabPanels[5]) return null;
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <MobileFixtureStatPack 
                    fixture_id={fixture_id}
                    teams={teams}
                />
            </MDBCol>
        );
    };

    var tabPanels = 
    { 
        0: 'Match'
    };

    if(fixtureLineups.data !== null && !fixtureLineups.isLoading) {
        tabPanels[1] = 'Lineups';
    }

    // if(comments.length > 0) {
        //tabPanels[5] = 'Stat Pack';
    //}

    if(comments.length > 0) {
        tabPanels[2] = 'Commentary';
    }

    if(stats.length > 0) {
        tabPanels[3] = 'Statistics';
    }

    if(fixture.is_cup === false) {
        tabPanels[4] = 'Standings';
    }

    const renderTabItem = (item, index) => {
        let key = `${item}_${index}_tab`;
        switch(item)
        {
            case "Match":
                {
                    return renderInfo(key);
                }
            case "Commentary":
                {
                    return renderCommentary(key);
                }
            case "Lineups":
                {
                    return renderLineups(key);
                }
            // case "Stat Pack":
            //     {
            //         return renderStatPack(key);
            //     }
            case "Statistics":
                {
                    return renderStatistics(key);
                }
            case "Standings":
                {
                    return renderLeagueStandings(key);
                }
            default:
                return null;
        }
    };

    return (
        <>
            <FixtureHelmet fixture={fixture} league={league} schedule={schedule} teams={teams} fixture_id={fixture_id} />
            <MDBRow>
                <MDBCol className="no_side_padding" size="12">
                    <div style={styling.detailsSection}>
                        <MobileFixtureDetails
                            contentSetter={contentSetter}
                            fixture_id={fixture_id}
                            fixture={fixture}
                            league={league}
                            last_updated={last_updated}
                            tabPanels={tabPanels}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            index={index}
                            setIndex={setIndex}
                            goalEvents={events.filter((event) => {return event.type_id === 14 || event.type_id === 16 || event.type_id === 15})}
                        />
                    </div>
                    {Object.keys(tabPanels).length > 0 && 
                        <div>
                            <MDBRow>
                                <MDBCol style={{ padding: '8px' }}>
                                    <SwipeableViews
                                        index={index}
                                        onChangeIndex={setIndex}
                                        // animateHeight
                                        enableMouseEvents
                                        disableLazyLoading={true}
                                    >
                                        {Object.entries(tabPanels).map(([key, value]) => {
                                            return (
                                                renderTabItem(value, key)
                                            );
                                        })
                                    }
                                    </SwipeableViews>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    }
                </MDBCol>
            </MDBRow>
        </>
    );    
};

export default FixtureDashboard;

var styling = {
    detailsSection: {
        padding: '8px 8px 0px 8px'
        // position: 'sticky',
        // top: 0,
        // zIndex: 10,
        // background: 'white'
    }
}
