import { Alert, AlertTitle, Paper } from '@mui/material';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

export default function NoWidgetContent(props) {

    const { no_content_heading, no_content_sub_heading, image_src, additional_information } = props;

    return (

        <Paper elevation={2}>

            <MDBRow className='widget_container'>

                <div className='widget_heading'>{no_content_heading}</div>

                <div className="widget_sub_heading">{no_content_sub_heading}</div>

                <div className='widget_content'>

                    <img className='center_content' style={{width: '300px', height: '300px'}} src={image_src} />

                    <MDBCol style={{padding:'10px'}} size="12">

                        {

                            additional_information === undefined ? <></> : 
                            
                            <Alert style={{width: '100%', marginLeft: 'auto', marginRight: 'auto'}} className="mb-2" severity="info">

                                <AlertTitle>In the mean time</AlertTitle>

                                {additional_information}

                            </Alert>

                        }

                    </MDBCol>

                </div>

            </MDBRow>

        </Paper>

    )

  }
