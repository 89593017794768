import { Alert, Box, Chip, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import SoccerLineUp from 'react-soccer-lineup';
import { GetGradientClass } from '../../../common/helpers/rating_helper';
import StatisticsModal from '../lineups/fixture_lineup_statistics_modal.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

function CreatePlayerArrayByPosition(squad, position, callback, color, textColor, homeTeam) {
    return squad
        .filter((player) => player.position_name === position)
        .map((player) => mapPlayer(player, callback, color, textColor, homeTeam));
}

function mapPlayer(player, callback, color, textColor, homeTeam) {
    return {
        homeTeam,
        numberColor: textColor,
        nameColor: textColor,
        color,
        playerId: player.player_id,
        name: player.player_name,
        number: player.jersey_number,
        onClick: callback,
        style: { numberColor: textColor, nameColor: textColor, color },
        cardStatus: player.card_status,
        totalGoals: player.total_goals || 0,
        totalAssists: null,
        rating: player.rating ? parseFloat(player.rating).toFixed(1) : 0,
        ratingClass: GetGradientClass(player.rating),
        playerImage: player.player_image
    };
}

function BuildFormation(formation, players, isHome, callback) {
    const formationNumbers = formation.split('-').map(Number);
    const formationRows = [];
    const goalkeeper = players[0]; 

    if(goalkeeper) {
        formationRows[0] = [mapPlayer(goalkeeper, callback, isHome ? '#000000' : '#FFFFFF', isHome ? '#FFFFFF' : '#000000', isHome)];
    }

    let positionIndex = 1;
    for (let col = 0; col < formationNumbers.length; col++) {
        const positionalPlayers = [];
        for (let row = 0; row < formationNumbers[col]; row++) {
            const player = players[positionIndex];
            if (player) {
                positionalPlayers.push(
                    mapPlayer(player, callback, isHome ? '#000000' : '#FFFFFF', isHome ? '#FFFFFF' : '#000000', isHome)
                );
                positionIndex++;
            }
        }
        formationRows.push(positionalPlayers);
    }

    return formationRows;
}

const RatingCard = ({ team, gradientClass, isHome }) => (
    <div style={{ padding: '16px 0px 16px 0px' }}>
        <MDBRow>
            <MDBCol className='no_side_padding' style={{ display: 'flex', justifyContent: isHome ? 'start' : 'end', gap: '14px', alignItems: 'center', flexDirection: isHome ? 'row' : 'row-reverse' }}>
                <span className={"span_player_fixture_rating " + gradientClass}>
                    {team.rating == 0 ? "-" : team.rating?.toFixed(2) || "-"}
                </span>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center', flexDirection: isHome ? 'row' : 'row-reverse' }}>
                    <img 
                        src={team.logo} 
                        alt={team.name + " logo"} 
                        style={{ width: '30px', height: '30px' }}
                    />
                    <span>
                        {team.name}
                    </span>
                </div>
                <span style={{fontWeight: 'bold'}}>{team.formation}</span>
            </MDBCol>
        </MDBRow>
    </div>
);

const AverageRatingsDisplay = ({ home_team, away_team }) => (
    <MDBRow>
        <MDBCol>
            <RatingCard 
                gradientClass={GetGradientClass(home_team.rating)}
                team={home_team}
                isHome={true}
            />
        </MDBCol>
        <MDBCol>
            <RatingCard 
                gradientClass={GetGradientClass(away_team.rating)} 
                team={away_team}
                isHome={false}
            />
        </MDBCol>
    </MDBRow>
);

export default function FixturePitch({ home_team, away_team, home_team_lineup, away_team_lineup, contentSetter }) {
    const [selectedPlayerStatistics, setSelectedPlayerStatistics] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => setModalOpen(false);

    function DisplayPlayerStatisticModal(ev) {
        const playerId = ev.target.closest('[playerid]')?.getAttribute('playerid');
        if (modalOpen || !playerId) return;

        const selectedPlayer = [...home_team_lineup, ...away_team_lineup].find((player) => player.player_id === playerId);

        if (selectedPlayer && selectedPlayer.fixture_player_statistics) {
            setSelectedPlayerStatistics(selectedPlayer.fixture_player_statistics);
            setModalOpen(true);
        }
    }

    const homeTeam = BuildFormation(home_team.formation, home_team_lineup, true, DisplayPlayerStatisticModal);
    const awayTeam = BuildFormation(away_team.formation, away_team_lineup, false, DisplayPlayerStatisticModal);

    if(homeTeam.length < 1 || awayTeam.length < 1) {
        return (
            <Paper elevation={1} className="mb-2 br-15 widget_fade_in">
                <Box className="p-3">
                    <Alert severity="info" fontSize={16}>Confirming lineups...</Alert>
                </Box>
            </Paper>
        )
    }

    return (
        <>
            <StatisticsModal playerStatistics={selectedPlayerStatistics} modalOpen={modalOpen} handleClose={handleClose} />
            <AverageRatingsDisplay 
                home_team={home_team}
                away_team={away_team}
            />
            <div style={{ backgroundColor: 'rgb(175, 206, 164)' }}>
                <SoccerLineUp
                    size="responsive"
                    color="#afcea4"
                    pattern="lines"
                    homeTeam={{ formationRow: homeTeam }}
                    awayTeam={{ formationRow: awayTeam }}
                />
            </div>
        </>
    );
}
