import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { usePlayerComparisonDashboard } from '../player/playerHook';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Alert, Box, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TableSortLabel, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { GetGradientClass } from '../../common/helpers/rating_helper';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function GetColumnHeadings(category) {

  switch(category)
  {

      case "general":
          {

              return [
                  
                  { id: 1, orderBy: 'minutes_played', title: 'Minutes Played' },

                  { id: 2, orderBy: 'total_appearances', title: 'Total Appearances' },

                  { id: 3, orderBy: 'starting_lineup', title: 'Starting Lineup' },

                  { id: 4, orderBy: 'total_yellow_cards', title: 'Yellow Cards' },

                  { id: 5, orderBy: 'offsides', title: 'Offsides' },

                  { id: 6, orderBy: 'games_as_captain', title: 'Games As Captain' },

                  { id: 7, orderBy: 'highest_rating', title: 'Highest Rating' },

                  { id: 8, orderBy: 'lowest_rating', title: 'Lowest Rating' },
                  
              ]

          }
          case "attacking":
          {

              return [

                  { id: 1, orderBy: 'goals', title: 'Goals' },

                  { id: 2, orderBy: 'assists', title: 'Assists' },

                  { id: 3, orderBy: 'big_chances_created', title: 'Chances Created' },

                  { id: 4, orderBy: 'big_chances_missed', title: 'Chances Missed' },

                  { id: 5, orderBy: 'fouls_drawn', title: 'Fouled' },

                  { id: 6, orderBy: 'hit_woodwork', title: 'Woodwork' },

                  { id: 7, orderBy: 'dribble_attempts', title: 'Dribble Attempts' },

                  { id: 8, orderBy: 'successful_dribbles', title: 'Successful Dribbles' },

                  { id: 9, orderBy: 'times_dispossessed', title: 'Dispossessed' }
                  
              ]

          }
          case "defending":
          {

              return [

                  { id: 1, orderBy: 'total_tackles', title: 'Tackes' },

                  { id: 3, orderBy: 'total_fouls', title: 'Fouls' },

                  { id: 4, orderBy: 'goals_conceded', title: 'Goals Conceded' },

                  { id: 5, orderBy: 'total_clearances', title: 'Clearances' },

                  { id: 6, orderBy: 'dribbled_past', title: 'Dribbled Past' },

                  { id: 7, orderBy: 'total_duels', title: 'Duels' },

                  { id: 8, orderBy: 'duels_won', title: 'Duels Won' },

                  { id: 2, orderBy: 'aerial_duels_won', title: 'Aerial Duels Won' },

                  { id: 9, orderBy: 'total_interceptions', title: 'Interceptions' },

                  { id: 10, orderBy: 'total_blocked_shots', title: 'Blocked Shots' }
                  
              ]

          }
          case "shooting":
          {

              return [

                  { id: 1, orderBy: 'shots_total', title: 'Total Shots' },

                  { id: 2, orderBy: 'shots_on_target', title: 'Shots On Target' },

                  { id: 3, orderBy: 'shots_off_target', title: 'Shots Off Target' },

                  { id: 4, orderBy: 'shots_blocked', title: 'Shots Blocked' }
                  
              ]

          }
          case "passing":
          {

              return [

                  { id: 1, orderBy: 'total_passes', title: 'Total Passes' },

                  { id: 2, orderBy: 'total_accurate_passes', title: 'Total Accurate Passes' },

                  { id: 3, orderBy: 'accurate_passes_percentage', title: 'Accurate Passes Percentage' },

                  { id: 4, orderBy: 'total_through_balls', title: 'Total Through Balls' },

                  { id: 5, orderBy: 'successful_through_balls', title: 'Successful Through Balls' },

                  { id: 6, orderBy: 'key_passes', title: 'Key Passes' },

                  { id: 7, orderBy: 'long_balls', title: 'Long Balls' },

                  { id: 8, orderBy: 'long_balls_won', title: 'Long Balls Won' },

                  { id: 9, orderBy: 'total_crosses', title: 'Total Crosses' },

                  { id: 10, orderBy: 'accurate_crosses', title: 'Accurate Crosses' }
                  
              ]

          }

  }
  
  // ---------------
  // - GOALKEEPING -
  // ---------------

}

function rowContent(_index, row, classes, dashboardState) {

  var player = row;

  return (

    <React.Fragment>

      <TableCell className={`${classes.cell} ${classes.shadowedCell}`}>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>

              <img src={player.player.image_path} style={{ marginRight: '10px', width: '20px', height: '20px', borderRadius: '50%' }} />
              
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: '10px' }}>{player.player.name}</span>
          
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
          
              <img src={player.team.image_path} style={{ marginRight: '10px', width: '20px', height: '20px', position: 'relative', top: '-3px' }} />
              
              <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="caption" display="inline" gutterBottom>{player.team.name}</Typography>
          
          </div>
      
      </TableCell>

      {/* GENERAL STATISTICS */}

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.minutes_played == null ? 0 : player.statistics.minutes_played.total}</Typography>

      </TableCell>

      <TableCell className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_appearances == null ? 0 : player.statistics.total_appearances.total}</Typography>

      </TableCell>

      <TableCell className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.starting_lineup == null ? 0 : player.statistics.starting_lineup.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_yellow_cards == null ? 0 : player.statistics.total_yellow_cards.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.offsides == null ? 0 : player.statistics.offsides.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.games_as_captain == null ? 0 : player.statistics.games_as_captain.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <span className={GetGradientClass(player.statistics.rating == null || player.statistics.rating.highest == null ? 0 : player.statistics.rating.highest) + " span_player_fixture_rating"} style={{textAlign: "center", padding: '8px', borderRadius: '6px', color: 'white'}}>
                                      
              {player.statistics.rating == null || player.statistics.rating.highest == null ? "0.00" : player.statistics.rating.highest.toFixed(2)}
                                      
          </span>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'general' ? 'table-cell' : 'none' }} align="center">

          <span className={GetGradientClass(player.statistics.rating == null || player.statistics.rating.lowest == null ? 0 : player.statistics.rating.lowest) + " span_player_fixture_rating"} style={{textAlign: "center", padding: '8px', borderRadius: '6px', color: 'white'}}>
                                      
              {player.statistics.rating == null || player.statistics.rating.lowest == null ? "0.00" : player.statistics.rating.lowest.toFixed(2)}
                                      
          </span>

      </TableCell>

      {/* GENERAL STATISTICS */}

      {/* ATTACKING STATISTICS */}

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.goals == null ? 0 : player.statistics.goals.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.assists == null ? 0 : player.statistics.assists.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.big_chances_created == null ? 0 : player.statistics.big_chances_created.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.big_chances_missed == null ? 0 : player.statistics.big_chances_missed.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.fouls_drawn == null ? 0 : player.statistics.fouls_drawn.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.hit_woodwork == null ? 0 : player.statistics.hit_woodwork.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.dribble_attempts == null ? 0 : player.statistics.dribble_attempts.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.successful_dribbles == null ? 0 : player.statistics.successful_dribbles.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'attacking' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.times_dispossessed == null ? 0 : player.statistics.times_dispossessed.total}</Typography>

      </TableCell>

      {/* ATTACKING STATISTICS */}

      {/* DEFENDING STATISTICS */}
      
      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_tackles == null ? 0 : player.statistics.total_tackles.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_fouls == null ? 0 : player.statistics.total_fouls.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.goals_conceded == null ? 0 : player.statistics.goals_conceded.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_clearances == null ? 0 : player.statistics.total_clearances.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.dribbled_past == null ? 0 : player.statistics.dribbled_past.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_duels == null ? 0 : player.statistics.total_duels.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.duels_won == null ? 0 : player.statistics.duels_won.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.aerial_duels_won == null ? 0 : player.statistics.aerial_duels_won.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_interceptions == null ? 0 : player.statistics.total_interceptions.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'defending' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_blocked_shots == null ? 0 : player.statistics.total_blocked_shots.total}</Typography>

      </TableCell>

      {/* DEFENDING STATISTICS */}

      {/* SHOOTING STATISTICS */}

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'shooting' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.shots_total == null ? 0 : player.statistics.shots_total.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'shooting' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.shots_on_target == null ? 0 : player.statistics.shots_on_target.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'shooting' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.shots_off_target == null ? 0 : player.statistics.shots_off_target.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'shooting' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.shots_blocked == null ? 0 : player.statistics.shots_blocked.total}</Typography>

      </TableCell>

      {/* SHOOTING STATISTICS */}

      {/* PASSING STATISTICS */}

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_passes == null ? 0 : player.statistics.total_passes.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_accurate_passes == null ? 0 : player.statistics.total_accurate_passes.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.accurate_passes_percentage == null ? 0 : player.statistics.accurate_passes_percentage.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_through_balls == null ? 0 : player.statistics.total_through_balls.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.successful_through_balls == null ? 0 : player.statistics.successful_through_balls.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.key_passes == null ? 0 : player.statistics.key_passes.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.long_balls == null ? 0 : player.statistics.long_balls.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.long_balls_won == null ? 0 : player.statistics.long_balls_won.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.total_crosses == null ? 0 : player.statistics.total_crosses.total}</Typography>

      </TableCell>

      <TableCell  className={`${classes.colouredCell}`} style={{ display: dashboardState.statistic_type === 'passing' ? 'table-cell' : 'none' }} align="center">

          <Typography variant="body2" display="block" gutterBottom>{player.statistics.accurate_crosses == null ? 0 : player.statistics.accurate_crosses.total}</Typography>

      </TableCell>

      {/* PASSING STATISTICS */}

      </React.Fragment>

  );
}

function EnhancedTableHead(order, orderBy, onRequestSort, category, classes) {

  const createSortHandler = (property) => (event) => {

    onRequestSort(event, property);

  };

  return (
      <TableRow>
        <TableCell
          sx={{
            backgroundColor: 'background.paper',
          }} colSpan={1} style={{width: '200px'}}></TableCell>
        {GetColumnHeadings(category).map((headCell) => (
          <TableCell
            sx={{
              backgroundColor: 'background.paper',
            }}
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.orderBy ? order : false}
            className={classes.tableHeading}
          >
            <TableSortLabel
              active={orderBy === headCell.orderBy}
              direction={orderBy === headCell.orderBy ? order : 'asc'}
              onClick={createSortHandler(headCell.orderBy)}
            >
              {headCell.title}
              {/* {orderBy === headCell.orderBy ? (
                <Box component="span" >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, orderBy, sortOrder) {

  const stabilizedThis = array.map((el, index) => [el, index]);

  if(orderBy === "rating") {

    stabilizedThis.sort((a, b) => {

      const ratingA = a[0].statistics.rating?.average || 0; // Get rating average for player a

      const ratingB = b[0].statistics.rating?.average || 0; // Get rating average for player b

      return sortOrder === "desc" ? ratingB - ratingA : ratingA - ratingB; // Sort in descending order by rating

    })

  }
  else
  {

    stabilizedThis.sort((a, b) => {

      const order = comparator(a[0].statistics, b[0].statistics);
  
      if (order !== 0) {
  
        return order;
  
      }
  
      return a[1] - b[1];
  
    });

  }

  var returnedArray = stabilizedThis.map((el) => el[0]);

  return returnedArray;

}

function descendingComparator(a, b, orderBy) {

  if ((b[orderBy] == null ? 0 : b[orderBy].total) < (a[orderBy] == null ? 0 : a[orderBy].total)) {

    return -1;

  }

  if ((b[orderBy] == null ? 0 : b[orderBy].total) > (a[orderBy] == null ? 0 : a[orderBy].total)) {

    return 1;

  }

  return 0;

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      width: '100%',
  },
  cell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '5px 10px 0px 10px !important'
  },
  colouredCell: {
      backgroundColor: '#f6f6f6',
      padding: '5px 10px 0px 10px !important'
  },
  shadowedCell: {
      boxShadow: '5px 0 10px rgba(0, 0, 0, 0.05)'
  },
  header: {
    fontSize: '10px'
  }
}));

const inputStyle = {

  flex: '1', // Make the width flexible

  borderRadius: 2,

  backgroundColor: '#f5f5f5', // Light grey background

  border: 'none', // Remove border

  display: 'flex',

  alignItems: 'center', // Vertically align items

  "& .MuiSelect-select": {

      display: 'flex',

      alignItems: 'center', // Vertically align item

  },

  ".MuiOutlinedInput-notchedOutline": { border: 0 },

  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {

      border: 0,

  },

  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {

      border: 0,

  },

};

export default function PlayerComparisonOne() {

  const classes = useStyles();

  const [order, setOrder] = React.useState('desc');

  const [orderBy, setOrderBy] = React.useState('rating');

  const [dashboardState, setDashboardState] = React.useState({ isVisible: false, isLoading: false, season_id: -1, statistic_type: "general", team_ids: [] });

  usePlayerComparisonDashboard(dashboardState, setDashboardState);

  const handleRequestSort = (event, property) => {

    const isAsc = orderBy !== property || (orderBy === property && order === 'asc');

    setOrder(isAsc ? 'desc' : 'asc');

    setOrderBy(property);

  };

  React.useEffect(() => {

    window.addEventListener('error', e => {

        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {

            const resizeObserverErrDiv = document.getElementById(

                'webpack-dev-server-client-overlay-div'

            );
            const resizeObserverErr = document.getElementById(

                'webpack-dev-server-client-overlay'

            );
            if (resizeObserverErr) {

                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {

                resizeObserverErrDiv.setAttribute('style', 'display: none');

            }

        }

    });

}, []);

  const handleSeasonChange = (event) => {

    setDashboardState(prevState => ({

        ...prevState,

        season_id: event.target.value,

        team_ids: []

    }));

  };

  const handleTeamChange = (event) => {

    setDashboardState(prevState => ({

        ...prevState,

        team_ids: event.target.value

    }));

  };

  const handleStatisticTypeChange = (event) => {

    setDashboardState(prevState => ({

        ...prevState,

        statistic_type: event.target.value

    }));

  };

  React.useEffect(() => {

    setDashboardState(prevState => ({

        ...prevState,

        isLoading: true

    }));

  }, [dashboardState.season_id]);

  var nullResponse = dashboardState == null || dashboardState.data == null || dashboardState.isLoading;

  const sortedRows = React.useMemo(

      () => {

          if(!nullResponse) {

              return stableSort(dashboardState.data.player_statistics, getComparator(order, orderBy), orderBy, order);

          } 
          else {

              return [];

          }

      }, [order, orderBy, dashboardState.season_id, nullResponse],

  );

  if(dashboardState.isLoading || nullResponse)
  {

    return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>

        <MDBRow>

            <MDBCol size="12">

                <CircularProgress sx={{marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />

            </MDBCol>

            <MDBCol size="12" align="center" style={{padding: "25px", fontWeight: '500', fontSize: '24px', color: '#121e32' }}>

                <Box sx={{marginLeft: 'auto', marginRight: 'auto', display: 'block' }} >Loading Statistics...</Box>
                
            </MDBCol>

        </MDBRow>

    </div> )
        
  }

  var data = (

    sortedRows.length > 0 ? 

    sortedRows : 

    dashboardState.data.player_statistics
    
  )
  .filter(a => 
  {
    
    if (dashboardState.team_ids.length === 0) {

      return true;

    } 
    else 
    {

      return dashboardState.team_ids.some(item => item === a.team.id);

    }

  });
  
  return (
  
      <Grid container spacing={2}>

        <Grid item xs={12}>

          <Paper className={`mb-2 widget_fade_in ${dashboardState.isVisible ? 'fade-in' : ''}`}>

              <MDBRow style={{padding: '20px 8px 20px 8px'}}>

                  <MDBCol size="3">

                    <FormControl fullWidth>

                      <InputLabel id="demo-simple-select-label">League</InputLabel>

                      <Select

                          autoWidth={true}

                          sx={inputStyle}

                          labelId="demo-simple-select-label"

                          id="demo-simple-select"

                          value={dashboardState.season_id}

                          onChange={handleSeasonChange}

                      >

                          <MenuItem key={"season_selection_minus_one"} value={-1}>Select League</MenuItem>

                          {

                              dashboardState.data.leagues.map((league, index) => {

                                  return (

                                      <MenuItem key={"season_selection_" + league.league_id} value={league.season_id}>
                                        
                                        <img src={league.league_logo} style={{marginRight: '10px', width: '20px', height: '20px' }} /> {league.league_name}
                                        
                                        </MenuItem>

                                  )

                              })

                          }

                      </Select>

                    </FormControl>

                  </MDBCol>

                  {

                      dashboardState.data.player_statistics.length == 0 || dashboardState.season_id == -1 ? <></> :

                      <MDBCol size="3">

                          <FormControl fullWidth>

                              <InputLabel id="demo-simple-select-label">Teams</InputLabel>

                              <Select

                                  autoWidth={true}

                                  sx={inputStyle}
  
                                  labelId="demo-simple-select-label"

                                  id="demo-simple-select"

                                  value={dashboardState.team_ids}

                                  label="Team"

                                  input={<OutlinedInput label="Tag" />}

                                  onChange={handleTeamChange}

                                  multiple

                                  margin='normal'

                                  renderValue={(selected) => {

                                    return selected.map(id => {

                                      const team = dashboardState.data.teams.find(team => team.id === id);
                                     
                                      return team ? team.name : '';
                                    
                                    }).join(', ');

                                  }}

                              >

                                  {

                                      dashboardState.data.teams.map((team, index) => {

                                          return (

                                              <MenuItem key={"team_selection_" + team.id} value={team.id}>
                                                
                                                <Checkbox checked={dashboardState.team_ids.indexOf(team.id) > -1} />

                                                <img src={team.image_path} style={{marginRight: '10px', width: '20px', height: '20px' }} /> 
                                                
                                                <ListItemText primary={team.name} />
                                                
                                              </MenuItem>

                                          )

                                      })

                                  }

                              </Select>

                          </FormControl>

                      </MDBCol>

                  }

                  </MDBRow>

              </Paper>

              {

                  dashboardState.data.player_statistics.length == 0 || dashboardState.season_id == -1 ? <></> : 
                  
                  <Paper className={`mb-2 widget_fade_in ${dashboardState.isVisible ? 'fade-in' : ''}`}>

                      <MDBRow style={{padding: '10px 8px 10px 8px'}}>

                          <MDBCol style={{ padding: '5px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '25px' }}>

                              <ToggleButtonGroup

                                  size='small'

                                  value={dashboardState.statistic_type}

                                  exclusive

                                  onChange={handleStatisticTypeChange}

                                  aria-label="text alignment"

                              >

                                  <ToggleButton style={{ border: 0, marginLeft: '1px', marginRight: '1px', borderRadius: '20px', paddingLeft: '15px', paddingRight: '15px' }} color="primary" key={"statistic_group_shooting"} value="general" aria-label="left aligned">

                                      General

                                  </ToggleButton>

                                  <ToggleButton style={{ border: 0, marginLeft: '1px', marginRight: '1px', borderRadius: '20px', paddingLeft: '15px', paddingRight: '15px' }} color="primary" key={"statistic_group_shooting"} value="attacking" aria-label="left aligned">

                                      Attacking

                                  </ToggleButton>

                                  <ToggleButton style={{ border: 0, marginLeft: '1px', marginRight: '1px', borderRadius: '20px', paddingLeft: '15px', paddingRight: '15px' }} color="primary" key={"statistic_group_duels"} value="defending" aria-label="left aligned">

                                      Defending

                                  </ToggleButton>

                                  <ToggleButton style={{ border: 0, marginLeft: '1px', marginRight: '1px', borderRadius: '20px', paddingLeft: '15px', paddingRight: '15px' }} color="primary" key={"statistic_group_goalkeeping"} value="shooting" aria-label="left aligned">

                                      Shooting

                                  </ToggleButton>

                                  <ToggleButton style={{ border: 0, marginLeft: '1px', marginRight: '1px', borderRadius: '20px', paddingLeft: '15px', paddingRight: '15px' }} color="primary" key={"statistic_group_passing"} value="passing" aria-label="left aligned">

                                      Passing

                                  </ToggleButton>

                                  <ToggleButton style={{ border: 0, marginLeft: '1px', marginRight: '1px', borderRadius: '20px', paddingLeft: '15px', paddingRight: '15px' }} color="primary" key={"statistic_group_goalkeeping"} value="goalkeeping" aria-label="left aligned">

                                      Goalkeeping

                                  </ToggleButton>

                              </ToggleButtonGroup>

                          </MDBCol>

                      </MDBRow>

                  </Paper>

              }

          { dashboardState.data.player_statistics.length == 0 || dashboardState.season_id == -1 ? <></> : 
              
          <Paper className={`widget_fade_in ${dashboardState.isVisible ? 'fade-in' : ''}`} style={{ height: 550, width: '100%' }}>

              <Alert style={{ border: '1px solid #c8efff' }} className="mb-2" severity="info">To order the table by a statistic, simply click on the heading, each statistic can be ordered in both ascending and descending order for convenience.</Alert>

              <TableVirtuoso

                data={data}

                components={VirtuosoTableComponents}

                fixedHeaderContent={() => EnhancedTableHead(order, orderBy, handleRequestSort, dashboardState.statistic_type, classes)}
                
                itemContent={(index, row) => rowContent(index, row, classes, dashboardState)}
              
              />

          </Paper>

        }

      </Grid>

    </Grid>

  )

}