import { useTeam, useTeamStatistics } from '../../teams/teamHook';
import { MDBRow, MDBCol } from "mdbreact";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useIsVisible } from '../../shared/hooks/isVisibleHook';
import React, { useRef } from 'react';
import SquadList from '../../teams/team_squad_table';
import { MdOutlineStadium } from "react-icons/md";
import { TeamStatisicsGeneralStatsitics } from '../../team_statistics/team_statistics_general_statistics';
import { LeagueStandingsCondensed } from '../../standings/league_standings_condensed';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../css/team_dashboard.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

export default function TeamDashboard(props)
{

    const { team_id, contentSetter, isMobile } = props;

    const componentMounted = useRef(true);

    const [teamResponse, setTeamResponse] = React.useState({ isLoading: false, data: null, team_id: team_id });

    useTeam(team_id, setTeamResponse);

    const [isVisible, setIsVisible] = React.useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);

    if(teamResponse == null || teamResponse.data == null || teamResponse.isLoading || team_id !== teamResponse.team_id) {

            return (
        
                <Box className="loading_spinner" sx={{ display: 'flex' }}>
    
                    <CircularProgress />
                    
                </Box>
                
            );

    }

    return (
    
        <React.Fragment>

            <MDBRow className={`team_dashboard widget_fade_in ${isVisible ? 'fade-in' : ''}`}>

                <MDBCol className='no_side_padding' size='12'>

                    <MDBRow className='dashboard_widget'>

                        <MDBCol className="team_information_header" style={{ display: 'flex', alignItems: 'center' }}>

                            <MDBCol className='logo_container' style={{ 

                                flex: '0',

                                backgroundColor: 'white',

                                padding: '7px',

                                borderRadius: '5px',

                                boxShadow: '0 3px 3px 0 rgb(0 0 0 / 5%), 0 5px 15px 0 rgb(0 0 0 / 5%)'

                            }}>

                                <img className='team_profile_logo' src={teamResponse.data.image_path} alt="Team Logo" />

                            </MDBCol>

                            <MDBCol className='name_container' style={{ flex: 2 }}>

                                <div className='team_name'>{teamResponse.data.name}</div>

                                <div>

                                    <MdOutlineStadium />

                                    <label style={{ marginLeft: '5px', position: 'relative', top: '1px'}}>{teamResponse.data.stadium_name}</label>

                                </div>

                            </MDBCol>

                            <MDBCol style={{ flex: 0, paddingRight: '20px' }}>

                                <img className='country_flag' style={{ width: '60px', height: '60px', borderRadius: '50%' }} src={teamResponse.data.country_image_path} alt="Country Flag" />
                            
                            </MDBCol>

                        </MDBCol>

                    </MDBRow>
                    
                </MDBCol>

                <MDBCol className='no_side_padding' size='12'>

                <Grid container spacing={1} className='pt-2'>

                    <Grid item xs={6} md={6}>

                        <Item>
                            
                            <LeagueStandingsCondensed 
                            
                                team_id={team_id}
                                
                                competitions={teamResponse.data.team_competitions} 
                                
                            />

                        </Item>

                    </Grid>

                    { 
                
                        <Grid item xs={6} md={6}>

                            <Item>

                                <TeamStatisicsGeneralStatsitics 
                                            
                                    team_id={team_id}

                                    competitions={teamResponse.data.team_competitions}
                                    
                                />

                            </Item>

                        </Grid>

                    }

                    <Grid item xs={12} md={12}>
                        
                        <SquadList team_id={team_id} />

                    </Grid>

                </Grid>

                </MDBCol>

            </MDBRow>

        </React.Fragment>
        
    );

}